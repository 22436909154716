import { ElNotification } from 'element-plus'

export class notificationService {

    succes(tittle: string, message: string, duration: number = 4500) {
        ElNotification({
            title: tittle,
            message: message,
            type: 'success',
            duration: duration
        })
    }

    succesPermanent(tittle: string, message: string) {
        this.succes(tittle, message, 0);
    }

    warning(tittle: string, message: string, duration: number = 4500) {
        ElNotification({
            title: tittle,
            message: message,
            type: 'warning',
            duration: duration
        })
    }

    warningPermanent(tittle: string, message: string) {
        this.warning(tittle, message, 0);
    }

    warningPermanentHtml(tittle: string, messageHtml: string ) {
        ElNotification({
            title: tittle,
            dangerouslyUseHTMLString: true,
            message: messageHtml,
            type: 'warning',
            duration: 0,
        })
    }

    info(tittle: string, message: string, duration: number = 4500) {
        ElNotification({
            title: tittle,
            message: message,
            type: 'info',
            duration: duration
        })
    }

    infoPermanent(tittle: string, message: string) {
        this.info(tittle, message, 0);
    }

    error(tittle: string, message: string, duration: number = 4500) {
        ElNotification({
            title: tittle,
            message: message,
            type: 'error',
            duration: duration
        })
    }

    errorPermanent(tittle: string, message: string) {
        this.error(tittle, message, 0);
    }
}