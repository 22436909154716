<template>
    <div class="segment-item">
        <div class="row-number">
            {{rowNumber}}
        </div>
        <div class="text" >
            <SourceSegment v-model="translationSegment.sourceData" />
        </div>
        <div class="text">
            <SourceSegment v-model="translationSegment.draftData" />
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';

    import SourceSegment from '../Document/TranslationSegment/Components/SourceSegmentView.vue'

    export default defineComponent({
        props: {
            modelValue: {
                type: Object,
                required: true
            },
            rowNumber: {
                type: Number,
                required: true
            }
        },
        components: {
            SourceSegment,
        },

        data() {
            return {
            };
        },
        mounted() {
        },
        computed: {
            translationSegment(): TranslationSegment {
                return this.modelValue as TranslationSegment;
            },
        },
        methods: {
        },
    })
</script>

<style lang="scss" scoped>
    .segment-item {
        padding: 12px;
        margin-bottom: 18px;
        background-color: #f0f0f0;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        width: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr 8fr 8fr;
        transition: background-color 0.2s ease-in-out;

        &:hover {
            background-color: #f5faff;
        }
    }
    .row-number {
        margin-top: 12px;
    }
    .text{
        max-width: 560px;
    }


</style>