import { apiClient } from '@/utils/apiClient'

import { segmentsStore } from '@/stores/segmentsStore'
import { pagingStore } from '@/stores/pagingStore'
import { documentStore } from '@/stores/documentStore';

import { SegmentEditorItem } from '@/models/SegmentEditorItem'
import { SegmentEditorItemType } from '../SegmentEditorItemType'

export class SplitService {
    requestClient: any
    segmentsStore: any
    pagingStore: any
    documentStore: any

    segmentToSplitId: number = 0;

    constructor() {
        this.requestClient = new apiClient();
        this.segmentsStore = segmentsStore();
        this.pagingStore = pagingStore();
        this.documentStore = documentStore();
    }

    initSplit(segmentId: number) {
        this.segmentToSplitId = segmentId;

        this.toggleSegmentVisibility();

        this.segmentsStore.setSplitState();
    }

    private toggleSegmentVisibility() {
        const segmentIndex = this.segmentsStore.getSegmentPosition(this.segmentToSplitId);
        const segment = this.segmentsStore.segmentsArray[segmentIndex];

        segment.segmentIsVisibleOnList = !segment.segmentIsVisibleOnList;
    }


    async getSegmentsWereMerged() {
        const segments = await this.requestClient.apiGet(`segments/mergedSegments?segmentId=${this.segmentToSplitId}`);
        return segments;
    }

    async splitMrgedSegments() {
        const segments = await this.splitSegmnetsrequest();
        this.segmentsStore.setTranslateState();
        this.documentStore.reloadDocument();
    }

    async splitSegmnetsrequest() {

        const splitRequest = {
            documentId: 0,
            segmentIds: [this.segmentToSplitId],
            pageSize: this.pagingStore.pageSize,
        }

        const response = await this.requestClient.apiPost(`segments/splitMergedSegments`, splitRequest);
        return response;
    }

    cancelSplit() {
        this.toggleSegmentVisibility()

        this.segmentsStore.setTranslateState();
        this.removeSplitSegment();
    }

    private removeSplitSegment() {
        const segmentIndex = this.segmentsStore.segmentsArray.findIndex((s: SegmentEditorItem) => s.segmentType == SegmentEditorItemType.SplitSegment);
        this.segmentsStore.removeSegmentFromSegmentsArray(segmentIndex);
    }
}
