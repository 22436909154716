<template>
    <div class="translation-editor">

        <SourceWithTermsView v-model="translationSegment"
                             :configuration="configuration" />

        <InternalVariants v-model="segmentItem"
                       v-loading="loading" />

        <div v-if="externalProvidersAvailable">
            <ExternalTranslationVariants :translationSegment="translationSegment"
                                         :configuration="configuration" />
        </div>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { SegmentEditorItem } from '@/models/SegmentEditorItem';
    import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
    import { TranslationConfiguration } from '@/models/TranslationConfiguration'

    import SourceWithTermsView from './SourceWithTermsView.vue';
    import InternalVariants from './InternalVariants.vue';
    import ExternalTranslationVariants from './ExternalTranslationVariants.vue';

    export default defineComponent({

        props: {
            modelValue: Object,
            configuration: Object,
            loading: Boolean,
        },
        components :{
            SourceWithTermsView,
            ExternalTranslationVariants,
            InternalVariants,
        },
        data() {
            return {
            }
        },
        computed: {
            segmentItem: {
                get(): SegmentEditorItem { return this.modelValue as SegmentEditorItem; },
                set(value: SegmentEditorItem) { this.$emit('input', value); }
            },
            translationSegment: {
                get(): TranslationSegment { return this.segmentItem.data as TranslationSegment; },
                set(value: TranslationSegment) { this.segmentItem.data = value }
            },
            externalProvidersAvailable(): boolean {
                return (this.configuration as TranslationConfiguration).externalTranslationProviders.length > 0;
            }
        },
    })

</script>

<style lang="scss" scoped>

    .translation-editor {
        background: white;
        width: 100%;
        box-sizing: border-box;
        position: sticky;
        top: 10px;
        z-index: 1;
    }

</style>