import { TranslationSegment } from './TranslationSegment/TranslationSegment';

export interface ExternalTranslationResponse {
    translationSegment: TranslationSegment,
    externalTranslationStatus: ExternalTranslationStatus,
    groupCharactersCount: number,
}

export enum ExternalTranslationStatus {
    Translated = 1,

    UserLimitReached,

    ServiceLimit,
    TooManyRequest,

    ConfigurationError,
    TranslationError,
}
