<template>
    <div>
        <el-row :gutter="24">
            <el-col :span="6" id="translate-all">

                <el-dropdown @click="translatePage"
                             size="default"
                             split-button
                             type="primary">
                    Translate this page
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="translateAll">Translate all document</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>

            </el-col>

            <el-col :span="6">

                <el-button id="save-drafts"
                           @click="saveDraftsDialogVisible = true"
                           plain
                           type="warning">
                    Save all drafts to memory
                    <div class="bttn-img">
                        <span class="material-symbols-outlined">
                            add_comment
                        </span>
                    </div>
                </el-button>

            </el-col>

            <el-col :span="6">

                <el-button id="clear-document"
                           @click="clearSegmentsDialogVisible = true"
                           plain
                           type="danger">
                    Clear document
                    <div class="bttn-img">
                        <span class="material-symbols-outlined">
                            ink_eraser
                        </span>
                    </div>
                </el-button>

            </el-col>

            <el-col :span="6">
                <el-button id="download-document"
                           @click="downloadDoc">
                    Download document
                </el-button>
            </el-col>
        </el-row>

        <el-dialog v-model="saveDraftsDialogVisible"
                   title="Save drafts"
                   width="30%"
                   align-center>
            <div class="message" v-html="saveMsgHtml"></div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeSaveDraftsDialog">Cancel</el-button>
                    <el-button type="primary" @click="saveAllDrafts">
                        Confirm
                    </el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog v-model="clearSegmentsDialogVisible"
                   title="Clear document"
                   width="30%"
                   align-center>
            <div class="message">{{clearMsg}} </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeClearSegmentsDialog">Cancel</el-button>
                    <el-button type="primary" @click="clearAllSegments">
                        Confirm
                    </el-button>
                </span>
            </template>
        </el-dialog>

    </div>
</template>



<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    import { documentService } from './documentService'
    import { documentStore } from '@/stores/documentStore'

    import { TranslationConfiguration } from '@/models/TranslationConfiguration';

    export default defineComponent({
        props: {
            modelValue: Object as PropType<TranslationConfiguration>,
        },
        data() {
            return {
                documentService: new documentService(),
                documentStore: documentStore(),

                saveDraftsDialogVisible: false,
                saveMsgHtml: `<p>${'This action will save all segments of this file with state'} <b>${'draft'}</b> ${'or'} <b>${'auto translated'}</b> ${'to the memory. Continue?'}</p>`,

                clearSegmentsDialogVisible: false,
                clearMsg: 'This action will erase all translations for this document and set all segments as not translated. Continue?',
            };
        },
        computed: {
            translationConfig(): TranslationConfiguration {
                return this.modelValue as TranslationConfiguration;
            },
            documentId(): number {
                return Number(this.$route.params.id);
            },
        },

        methods: {
            async translatePage() {
                this.documentStore.startLoading();
                await this.documentService.translateThisPage(this.documentId);
                this.documentStore.stopLoading();

                this.documentStore.reloadDocument();
            },
            async translateAll() {
                this.documentStore.startLoading();
                await this.documentService.translateDocument(this.documentId);
                this.documentStore.stopLoading();
                this.documentStore.reloadDocument();
            },
            async saveAllDrafts() {
                this.documentStore.startLoading();
                this.closeSaveDraftsDialog();
                await this.documentService.saveTranslationDrafts(this.documentId);
                this.documentStore.stopLoading();
                this.documentStore.reloadDocument();
            },
            async clearAllSegments() {
                this.documentStore.startLoading();
                this.closeClearSegmentsDialog();
                await this.documentService.clearFileTranslations(this.documentId);
                this.documentStore.stopLoading();
                this.documentStore.reloadDocument();
            },
            async downloadDoc() {
                this.documentStore.startLoading();
                await this.documentService.downloadDocument(this.documentId)
                this.documentStore.stopLoading();
            },
            closeClearSegmentsDialog() {
                this.clearSegmentsDialogVisible = false;
            },
            closeSaveDraftsDialog() {
                this.saveDraftsDialogVisible = false;
            }
        }
    });
</script>

<style lang="scss" scoped>
    .bttn-img {
        margin-left: 10px;
    }

    .message {
        font-size: 1.3em;
    }
</style>