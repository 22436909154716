<template>
    <div>
        <el-tooltip placement="top-start"
                    :disabled="disabled"
                    :content="tooltipMessage"
                    :show-after="5">
            <div :class="['img-small-button-container', {'button-disabled':disabled}] "
                 @click="buttonClick">
                <slot>

                </slot>
            </div>
        </el-tooltip>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            disabled: {
                type: Boolean,
                default: true
            },
            tooltipMessage: {
                type: String,
                default: ''
            },
        },
        methods: {
            buttonClick() {
                if (!this.disabled) {
                    this.$emit('buttonClick');
                }
            },
        }

    });
</script>

<style lang="scss" scoped>
</style>
