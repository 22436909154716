import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslationConfig = _resolveComponent("TranslationConfig")!
  const _component_SegmentListPreview = _resolveComponent("SegmentListPreview")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TranslationConfig, {
      modelValue: _ctx.translationConfig,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.translationConfig) = $event)),
      "view-mode": true,
      pageNamePrefix: "Current document"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_SegmentListPreview)
  ]))
}