<template>
    <div>
        <el-button type="primary"
                   @click="startStuleTour"
                   :disabled="!tourAvailable">
            {{tourName}}
        </el-button>

        <el-tour v-model="tourStart"
                 :target-area-clickable="false">

            <el-tour-step :target="targetOne"
                          :title="tittleOne">
                <div>{{descriptionOne}}</div>
            </el-tour-step>

            <el-tour-step :target="targetTwo"
                          :title="tittleTwo"
                          :content-style="contentStyleTwo">

                <div>{{descriptionTwo}}</div>

                <img class="screenshot"
                     src="@/images/Tours/text-style-tour.png"
                     alt="tour.png" />

                <div v-for="(decs, index) in subDescriptionsTwo"
                     :key="index">
                    {{decs}}
                </div>

            </el-tour-step>


        </el-tour>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { shortcuts } from '@/utils/shortcuts';

    export default defineComponent({
        props: {
            tourAvailable: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                tourName: "Text styles",
                tourStart: false,

                targetOne: "#source-container",
                tittleOne: "Source style",
                descriptionOne: "When source text has some specific styles (bold, italic, underline and link) you need to apply them manually to target.",

                targetTwo: "#tareget-container",
                tittleTwo: "Target style",
                descriptionTwo: "To apply styles fill text translation, select text with cursor. Below you would see pop-up where you can:",
                contentStyleTwo: { width: '1000px' },
                subDescriptionsTwo: [
                    "1 - Apply styles (bold, italic, underline)",
                    "2 - Add link",
                    "3 - Clear applied styles",
                    `Info - you can remove all applied styles to the segment by using hotkey ${shortcuts.clearStyles.win}`,
                ],
            };
        },

        methods: {
            startStuleTour() {
                const result = document.getElementById("shortcut-button");
                if (result != null) {
                    result.click();
                }
                this.tourStart = true;
            },
        },


    })
</script>

<style scoped>
    .screenshot {
        max-width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
</style>