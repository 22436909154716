<template>
    <div class="target">
        <p class="text">
            {{translationVariant.targetSegment}}
        </p>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { Translation } from '@/models/TranslationSegment/TranslationVariants';

    export default defineComponent({
        props: {
            translationVariant: {
                type: Object as PropType<Translation>,
                mandatory: true
            }
        },
    })

</script>

<style lang="scss" scoped>

    .target {
        & .text {
            margin: 15px 0 15px 5px;
            word-break: auto-phrase;
        }
    }

</style>