import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-48dada42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.haveDraft)
      ? (_openBlock(), _createBlock(_component_el_tooltip, {
          key: 0,
          effect: "light",
          content: _ctx.draftSegments,
          placement: "right"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tag, { type: "warning" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.draftTag), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["content"]))
      : _createCommentVNode("", true),
    (_ctx.haveTranslated)
      ? (_openBlock(), _createBlock(_component_el_tooltip, {
          key: 1,
          effect: "light",
          content: _ctx.translatedSegments,
          placement: "right"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tag, { type: "success" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translatedTag), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["content"]))
      : _createCommentVNode("", true),
    (_ctx.havePreTranslated)
      ? (_openBlock(), _createBlock(_component_el_tooltip, {
          key: 2,
          effect: "light",
          content: _ctx.preTranslatedSegments,
          placement: "right"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tag, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.preTranslatedTag), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["content"]))
      : _createCommentVNode("", true),
    (_ctx.newFile)
      ? (_openBlock(), _createBlock(_component_el_tooltip, {
          key: 3,
          effect: "light",
          content: _ctx.tatalSegments,
          placement: "right"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tag, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.nweFileTag), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["content"]))
      : _createCommentVNode("", true)
  ]))
}