import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tour_step = _resolveComponent("el-tour-step")!
  const _component_el_tour = _resolveComponent("el-tour")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_button, {
      type: "primary",
      onClick: _ctx.startTour,
      disabled: !_ctx.tourAvailable
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.tourName), 1)
      ]),
      _: 1
    }, 8, ["onClick", "disabled"]),
    _createVNode(_component_el_tour, {
      modelValue: _ctx.tourStart,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tourStart) = $event)),
      "target-area-clickable": _ctx.tourElementsClickable
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tourSteps, (step) => {
          return (_openBlock(), _createBlock(_component_el_tour_step, {
            key: step.id,
            target: step.target,
            title: step.title,
            description: step.description
          }, null, 8, ["target", "title", "description"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "target-area-clickable"])
  ]))
}