<template>
    <i></i>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { notificationService } from '@/services/notificationService';
    import { translationSegmentService } from './TranslationSegment/translationSegmentService';
    import { segmentsEditorService } from './segmentsEditorService';

    export default defineComponent({
        data() {
            return {
                notificationService: new notificationService(),
                segmentsEditorService: new segmentsEditorService(),
                translationSegmentService: translationSegmentService.getInstance(),
            }
        },
        mounted() {
            document.addEventListener("keydown", this.hotkeysListener);
        },
        beforeUnmount() {
            document.removeEventListener("keydown", this.hotkeysListener);
        },
        methods: {
            async hotkeysListener(e: KeyboardEvent) {
                if (e.altKey && e.key === "c") {
                    await this.translationSegmentService.copyCurrentSegment();
                    this.notificationService.succes("Copied", "Source copied to target.");
                    return
                }

                if (e.altKey && e.key === "t") {
                    await this.translationSegmentService.translateCurrentSegment();
                    this.notificationService.succes("Translated", "Translation variants loaded.");
                    return
                }

                if (e.altKey && e.key === "s") {
                    await this.doAddTranslationWithNotification();
                    return
                }

                if (e.altKey && e.key === "u") {
                    await this.doUpdateTranslationWithNotification();
                    return
                }

                if (e.altKey && e.key === "Backspace") {
                    await this.translationSegmentService.cleanCurrentSegmentTranslationSegment();
                    this.notificationService.succes("Cleared", "Segment is cleared.");
                    return
                }
                               
                if (e.altKey && e.key === "q") {
                    this.translationSegmentService.removeStylesInCurrentSrgment();
                    this.notificationService.succes("Styles removed", "");
                    return
                }

                if (e.altKey && e.key === "v") {
                    this.translationSegmentService.toogleCurrentSegmentVariantsPopup();
                    return
                }   
                
                if (e.shiftKey && e.key === "Tab") {
                    e.preventDefault();
                    this.segmentsEditorService.goToPreviousSegment();
                    return
                }

                if (e.key === "Tab") {
                    e.preventDefault();
                    this.segmentsEditorService.goToNextSegment();
                    return
                }

                if (e.altKey && e.key === "PageUp") {
                    this.segmentsEditorService.goToNextPage();
                    return
                }  
                
                if (e.altKey && e.key === "PageDown") {
                    this.segmentsEditorService.goToPreviousPage();
                    return
                }

                if (e.altKey && e.key === "ArrowRight") {
                    e.preventDefault();
                    await this.doAddTranslationWithNotification();
                    this.segmentsEditorService.goToNextSegment();
                    return
                } 

                if (e.altKey && e.key === "ArrowLeft") {
                    e.preventDefault();
                    await this.doAddTranslationWithNotification();
                    this.segmentsEditorService.goToPreviousSegment();
                    return
                }
                
                if (e.ctrlKey && e.key === "m") {
                    const currentSegment = this.segmentsEditorService.getCurrentSegmentEditorItem();
                    
                    if (this.translationSegmentService.mergeIsPossible(currentSegment) == false){
                        return;
                    }

                    this.segmentsEditorService.statrMerge(currentSegment.itemId);
                    this.notificationService.info("Merge begin", "Merge dialog is opened")
                    return
                }

                if (e.ctrlKey && e.shiftKey && e.key === "S") {
                    const currentSegment = this.segmentsEditorService.getCurrentSegmentEditorItem();
                    
                    if (this.translationSegmentService.splitIsPossible(currentSegment) == false){
                        return;
                    }

                    this.segmentsEditorService.statrSplit(currentSegment.itemId);
                    this.notificationService.info("Split segments begin", "Split segment dialog is opened")
                    return
                }
                
                if (e.altKey && e.key === "n") {
                    this.notificationService.info("Looking next working segment.", "");
                    await this.segmentsEditorService.goToNextWorkSegment();
                    return
                }
            },
            
            async doAddTranslationWithNotification() {
                if(this.translationSegmentService.translationCouldBeAdded()){
                    await this.translationSegmentService.addCurrentSegmentTranslation();
                    this.notificationService.succes("Saved", "Translation saved to the memory.");
                }
            },

            async doUpdateTranslationWithNotification(){
                if(this.translationSegmentService.translationCouldBeUpdated()){
                    await this.translationSegmentService.updateCurrentSegmentTranslation();
                    this.notificationService.succes("Updated", "Translation updated in the memory.");
                }
            },

        }
    })

</script>