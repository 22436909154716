<template>

    <div class="info">
        <span v-if="translationIsOwn"
              class="material-symbols-outlined">
            person
        </span>
        <span v-else class="material-symbols-outlined">
            group
        </span>
    </div>

</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { Translation } from '@/models/TranslationSegment/TranslationVariants';

    import { segmentTextLogicService } from '@/services/translationSegment/segmentTextLogicService';

    export default defineComponent({
        props: {
            translationVariant: {
                type: Object as PropType<Translation>,
                mandatory: true
            }
        },
        data() {
            return {
                segmentTextLogicService: segmentTextLogicService.getInstance(),
            }
        },
        computed: {
            translationIsOwn(): boolean {
                return this.segmentTextLogicService.ownershipIsOwn(this.translationVariant);
            },
        },
    })

</script>

<style lang="scss" scoped>
    .info {
        display: flex;
        justify-content: center;
    }

</style>