import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6ea506e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "segment-container",
  class: "segment-container",
  ref: "translationSegment"
}
const _hoisted_2 = {
  id: "row-number",
  class: "row-number"
}
const _hoisted_3 = {
  id: "source-container",
  class: "source-container"
}
const _hoisted_4 = { class: "next-segmet-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SourceSegment = _resolveComponent("SourceSegment")!
  const _component_TranslateAndCopy = _resolveComponent("TranslateAndCopy")!
  const _component_TargetWithVariants = _resolveComponent("TargetWithVariants")!
  const _component_TargetOptions = _resolveComponent("TargetOptions")!
  const _component_FindNextSegment = _resolveComponent("FindNextSegment")!
  const _component_MergeButton = _resolveComponent("MergeButton")!
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass([{'focused-row':_ctx.focused}, {'not-loadded':!_ctx.translationLoadded}, 'translation-segment'])
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.segmentItem.rowNumber), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SourceSegment, {
          modelValue: _ctx.translationSegment.sourceData,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.translationSegment.sourceData) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createVNode(_component_TranslateAndCopy, {
        modelValue: _ctx.segmentItem,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.segmentItem) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_TargetWithVariants, {
        modelValue: _ctx.segmentItem,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.segmentItem) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_TargetOptions, {
        modelValue: _ctx.segmentItem,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.segmentItem) = $event))
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_FindNextSegment, {
          focused: _ctx.focused,
          translationLoadded: _ctx.translationLoadded,
          onNextTranslateSegment: _ctx.nextTranslateSegment
        }, null, 8, ["focused", "translationLoadded", "onNextTranslateSegment"])
      ])
    ], 2)), [
      [_directive_loading, _ctx.loading]
    ]),
    (_ctx.mergePossible)
      ? (_openBlock(), _createBlock(_component_MergeButton, {
          key: 0,
          focused: _ctx.focused,
          onMerge: _ctx.mergeSegments
        }, null, 8, ["focused", "onMerge"]))
      : _createCommentVNode("", true),
    (_ctx.splitPossible)
      ? (_openBlock(), _createBlock(_component_SplitButton, {
          key: 1,
          focused: _ctx.focused,
          onSplit: _ctx.splitSegment
        }, null, 8, ["focused", "onSplit"]))
      : _createCommentVNode("", true)
  ], 512))
}