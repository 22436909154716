import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-11cef9ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "translation-editor" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SourceWithTermsView = _resolveComponent("SourceWithTermsView")!
  const _component_InternalVariants = _resolveComponent("InternalVariants")!
  const _component_ExternalTranslationVariants = _resolveComponent("ExternalTranslationVariants")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SourceWithTermsView, {
      modelValue: _ctx.translationSegment,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.translationSegment) = $event)),
      configuration: _ctx.configuration
    }, null, 8, ["modelValue", "configuration"]),
    _withDirectives(_createVNode(_component_InternalVariants, {
      modelValue: _ctx.segmentItem,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.segmentItem) = $event))
    }, null, 8, ["modelValue"]), [
      [_directive_loading, _ctx.loading]
    ]),
    (_ctx.externalProvidersAvailable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ExternalTranslationVariants, {
            translationSegment: _ctx.translationSegment,
            configuration: _ctx.configuration
          }, null, 8, ["translationSegment", "configuration"])
        ]))
      : _createCommentVNode("", true)
  ]))
}