<template>
    <div>

        <el-popover placement="bottom"
                    :width="200"
                    title="Actions"
                    trigger="click">

            <div class="buttons-container">

                <el-button plain
                           type="primary"
                           @click="goToUserInfoPage">
                    User info
                </el-button>

                <el-divider />

                <el-button tag="a"
                           :href="wwsUrl"
                           target="_blank"
                           rel="noopener noreferrer">
                    Open WWS admin
                </el-button>

                <el-button plain
                           type="warning"
                           @click="userLogout">
                    Log Out
                </el-button>

            </div>

            <template #reference>
                <div>
                    <el-tooltip :content="tooltip"
                                placement="bottom">
                        <el-button id="user-component"
                                   class="m-2">
                            Hi,  {{userName}}
                        </el-button>
                    </el-tooltip>
                </div>
            </template>
        </el-popover>

    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    import { userService } from '@/services/userService'
    import { wwsUrlService } from '@/services/wwsUrlService'
    import { navigationService } from '@/services/navigationService'

    export default defineComponent({
        data() {
            return {
                userService: new userService(),
                userName: '',
                tooltip: "User actions",
            };
        },
        computed:{
            wwsUrl(){
                return wwsUrlService.homePage()
            }
        },
        created(){
            this.init();
        },
        methods: {
            async init() {
                await this.setUserName()
            },
            async setUserName(){
                this.userName = await this.userService.getUserName();
            },
            goToUserInfoPage() {
                navigationService.goUserInfoPage();
            },
            userLogout() {
                navigationService.goLogoutPage()
            },
        },
    });
</script>

<style lang="scss">
    .buttons-container {
        display: flex;
        flex-flow: column;

        & > button {
            margin: 12px 5px 0 5px;
        }
    }

</style>