import { defineStore } from 'pinia'
import { DEFAULT_PAGE_SIZE } from '@/utils/constants'

export const pagingStore = defineStore('pagingStore', {
    state: () => ({
        currentPageNumber: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        rowCount: 0,
        pageCount: 0,
        reloadPage: 0,
        reloadBack: 0,
    }),

    getters: {
        currentPageBaseIndex: (state) => (state.currentPageNumber - 1) * state.pageSize,
    },

    actions: {
        resetState() {
            this.currentPageNumber = 1;
            this.pageSize = DEFAULT_PAGE_SIZE;
            this.rowCount = 0;
            this.pageCount = 0;
        },

        setPageNumber(pageNumber: number) {
            this.currentPageNumber = pageNumber;
        },

        goToPage(pageNumber: number) {
            this.currentPageNumber = pageNumber;
            this.reload();
        },

        setPageCount(pageCount: number) {
            this.pageCount = pageCount;
        },

        setRowCount(rowCount: number) {
            this.rowCount = rowCount;
        },

        reload() {
            this.reloadPage++;
        },

        backReload() {
            this.reloadBack++;
        },

        increasePageNumber() {
            if (this.currentPageNumber < this.pageCount) {
                this.currentPageNumber++;
                return true;
            } else {
                return false;
            }
        },

        decreasePageNumber() {
            if (this.currentPageNumber > 1) {
                this.currentPageNumber--;
                return true;
            } else {
                return false;
            }
        },

        goToNextPage() {
            if (this.currentPageNumber < this.pageCount) {
                this.currentPageNumber++;
                this.reload();
                return true;
            } else {
                return false;
            }
        },

        goToPreviousPage() {
            if (this.currentPageNumber > 1) {
                this.currentPageNumber--;
                this.reload();
                return true;
            } else {
                return false;
            }
        },
    }
})
