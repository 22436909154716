import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
import { SegmentStatus } from '@/models/TranslationSegment/SegmentStatus';
import { TranslationState } from '@/models/TranslationSegment/TranslationState';

import { segmentTextLogicService } from './segmentTextLogicService';

export class segmentStateService {
    private static instance: segmentStateService;
    private segmentTextLogicService: segmentTextLogicService;

    private constructor() {
        this.segmentTextLogicService = segmentTextLogicService.getInstance();
    }

    public static getInstance(): segmentStateService {
        if (!segmentStateService.instance) {
            segmentStateService.instance = new segmentStateService();
        }
        return segmentStateService.instance;
    }

    clearSelectedTranslation(translationSegment: TranslationSegment): void {
        if (translationSegment.segmentTranslation != null) {
            if (translationSegment.segmentTranslation.targetSegment != translationSegment.draftData.text) {
                translationSegment.segmentTranslation = null;
            }
        }
    }

    setNewState(translationSegment: TranslationSegment): void {
        this.updateSegmentStatus(translationSegment);

        if (this.segmentTextLogicService.draftIsEmpty(translationSegment)) {
            this.clearSelectedTranslation(translationSegment);
            this.setStateNotTranslated(translationSegment);
        } else if (this.segmentTextLogicService.translationNotSelected(translationSegment) || this.segmentTextLogicService.draftNotEqalTranslation(translationSegment)) {
            this.setStateDraft(translationSegment);
        } else if (this.segmentTextLogicService.matchPercentIs100(translationSegment)) {
            this.setStateTranslated(translationSegment);
        } else {
            this.setStatePreTranslated(translationSegment);
        }
    }

    updateSegmentStatus(translationSegment: TranslationSegment): void {
        if (translationSegment.segmentStatus == SegmentStatus.SourceReach500Limit) {
            return
        }

        if (this.segmentTextLogicService.textIsTooLong(translationSegment.draftData.text)) {
            translationSegment.segmentStatus = SegmentStatus.TargetReach500Limit;
        }
        if (this.segmentTextLogicService.textLengthIsOk(translationSegment.draftData.text)) {
            translationSegment.segmentStatus = SegmentStatus.Ok;
        }
    }

    setSegmentStatus(segment: TranslationSegment): void {
        if (this.segmentTextLogicService.textIsTooLong(segment.sourceData.text)) {
            segment.segmentStatus = SegmentStatus.SourceReach500Limit;
        }

        if (this.segmentTextLogicService.textIsTooLong(segment.draftData.text)) {
            segment.segmentStatus = SegmentStatus.TargetReach500Limit;
        }

        if (segment.segmentStatus == SegmentStatus.Undefined) {
            segment.segmentStatus = SegmentStatus.Ok;
        }
    }

    setStateNotTranslated(translationSegment: TranslationSegment): void {
        translationSegment.translationState = TranslationState.NotTranslated;
    }

    setStateDraft(translationSegment: TranslationSegment): void {
        translationSegment.translationState = TranslationState.Draft;
    }

    setStateTranslated(translationSegment: TranslationSegment): void {
        translationSegment.translationState = TranslationState.Translated;
    }

    setStatePreTranslated(translationSegment: TranslationSegment): void {
        translationSegment.translationState = TranslationState.PreTranslated;
    }

    setStateAutoTranslated(translationSegment: TranslationSegment): void {
        translationSegment.translationState = TranslationState.AutoTranslated;
    }

}