<template>
    <div ref="editorRef"></div>
</template>

<script lang="ts">
    import { defineComponent, toRaw } from 'vue'
    import { deltaBuilder } from '@/utils/deltaBuilder'
    import Quill from 'quill'
    import Delta from 'quill-delta'

    export default defineComponent({
        props: {
            modelValue: {
                type: Object,
                defaultValue: {
                    text: '',
                    delta:{}                
                }
            },            
            readOnly :{
                type: Boolean,
                defaultValue: false
            },
            setFocus: {
                type: Boolean,
                defaultValue: false
            },
        },
        emits: [
            'update:modelValue',
            'textChange',
            'editing',
            'focusEvent'
        ],
        watch: {
            modelValue: {
                handler() {
                    if (!this.editorChangedValue) {
                        this.setEditorValue();
                    }
                },
            },
            setFocus() {
                if (!this.editorIsFocused) {
                    this.cursorToEndOfLine();
                }
            }
        },
        data() {
            return {
                editorChangedValue: false,
                editorIsFocused: false,
                editor: null as (Quill|null),
                emptyDelta: deltaBuilder.createEmptyDelta(),
            };
        },
        computed: {
            textData:{
                get(): any { return this.modelValue },
                set(value: any) { this.$emit('update:modelValue', value) }  
            },
            quillEditor():any {
                return toRaw(this.editor);
            }
        },
        mounted() {
            this.editor = new Quill((this.$refs as any).editorRef, {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline'],
                        ['link'],
                        ['clean']
                    ],
                    keyboard: {
                        bindings: {
                            enter: {
                                key: 13,
                                handler: function () {
                                    return false;
                                }
                            },
                            tab: {
                                key: 9,
                                handler: function () {
                                    return true;
                                },
                            },
                        }
                    }
                },
                theme: 'bubble',
                readOnly: this.readOnly,
            });

            this.setEditorValue();

            this.editor.on('text-change', () => this.texteditorChangedValue());
            this.editor.on('selection-change',
                (range: any, oldRange: any) =>
                    this.handleBlur(range, oldRange));
            this.clearLinks();
        },
        beforeUnmount(){
             
            if(this.editor == null){
                return;
            }

            this.editor.off('text-change', () => this.texteditorChangedValue());
            this.editor.off('selection-change',
                (range: any, oldRange: any) =>
                    this.handleBlur(range, oldRange));

            this.editor = null;
        },

        methods: {
            clearLinks() {
                var divs = document.querySelectorAll("input[data-link]");
                [].forEach.call(divs, function (input: HTMLInputElement) {
                    input.dataset.link = "https://www.site.com";
                    input.placeholder = "https://www.site.com";
                });
            },
            setEditorValue(){
                if(this.textData.text != '' && this.deltaIsEmpty(this.textData.delta)){
                    this.setStringValue(this.textData.text);
                } else {
                    this.setDeltaValue(this.textData.delta as Delta);
                }
            },
            deltaIsEmpty(delta: any){
                return delta == undefined ||
                delta == null ||
                (delta.ops != undefined && delta.ops.length == 0)
            },
            setStringValue(textString: string) {
                var delta = deltaBuilder.createDeltaFromText(textString);

                this.setDeltaValue(delta);
            },
            setDeltaValue(delta: Delta) {      
               this.quillEditor?.setContents(delta as Delta, 'silent')
               this.cursorToEndOfLine();
            },
            cursorToEndOfLine(){
                this.quillEditor?.setSelection(this.quillEditor?.getLength(), 0)
            },
            getPlaneText(){
                return this.quillEditor?.getText().replace("\n", "");
            },
            getDelta(){
                return this.quillEditor?.getContents()
            },
            texteditorChangedValue() {
                this.editorChangedValue = true;
                
                const changeObject = {
                    text : this.getPlaneText(),
                    delta: this.getDelta()                
                }

                this.textData = changeObject;
                this.$emit('editing');
            },
            buildChangeObject() {
                const changeObject = {
                    planeText : this.getPlaneText(),
                    delta: this.getDelta()                
                }

                return changeObject;
            },
            handleBlur(range: any, oldRange:any) {
                if (range === null && oldRange !== null) {
                    this.blurAction();
                }
                if (range !== null && oldRange === null) {
                    this.emitFocus();
                } 
            },
            blurAction() {
                if (this.editorChangedValue) {
                    const change = this.buildChangeObject();
                    this.$emit('textChange', change);
                    this.editorChangedValue = false;
                }
                this.editorIsFocused = false;
            },
            emitFocus() {
                this.editorIsFocused = true;

                this.$emit('focusEvent');
            },

        }
    }) 
</script>

<style scoped>
    :deep().ql-container {
        height: auto !important;
    }

    :deep().ql-editor {
        height: auto !important;
    }
</style>