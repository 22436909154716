<template>
    <div class="post">
        {{msg}}

        <GoHome />
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import GoHome from '@/components/GoHome.vue'

    export default defineComponent({
        components:{
            GoHome
        },
        data() {
            return {
                msg: "Page not found",
            };
        },
    });
</script>