import { apiClient } from '@/utils/apiClient'

export class externalTranslationLogService {
    requestClient: apiClient

    constructor() {
        this.requestClient = new apiClient();
    }

    async getTranslationLogs() {
        return await this.requestClient.getExternalTranslationLogs();
    }
}