import { defineStore } from 'pinia'
import { TranslationProvider } from '@/models/TranslationProvider'


export const externalProvidersStore = defineStore('externalProvidersStore', {
    state: () => ({
        disabledProviders: [] as DisabledProvider[],
    }),

    actions: {

        disableProviderForDay(provider: TranslationProvider) {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            tomorrow.setHours(0, 0, 0, 0);

            this.addDisabledProvider(provider, tomorrow);

        },

        disableProviderForTreeMinutes(provider: TranslationProvider) {
            const threeMinutes = 3 * 60 * 1000;
            this.addDisabledProvider(provider, new Date(Date.now() + threeMinutes));
        },

        addDisabledProvider(provider: TranslationProvider, dueDate: Date) {
            const isProviderDisabled = this.disabledProviders.some(dp => dp.providerType == provider)

            if (!isProviderDisabled) {
                this.disabledProviders.push({ providerType: provider, dueDate: dueDate });
            }
        },

        getProviderName(provider: TranslationProvider){
            switch (provider) {
                case TranslationProvider.DeepL:
                    return 'DeepL';
                case TranslationProvider.Google:
                    return 'Google';
                default:
                    return 'Unknown';
            }
        },

        providerIsAvailable(provider: TranslationProvider) {
            const currentDate = new Date();
            this.disabledProviders = this.disabledProviders.filter(dp => dp.dueDate > currentDate);

            return !this.disabledProviders.some(dp => dp.providerType == provider)
        },

        updateDisabledProviders() {
            const currentDate = new Date();
            this.disabledProviders = this.disabledProviders.filter(dp => dp.dueDate > currentDate);
        }
    }
})

interface DisabledProvider { 
    providerType: TranslationProvider;
    dueDate: Date;
}
