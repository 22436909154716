
<template>
    <div>

        <p>
            User documents
        </p>

        <div v-loading="loading" v-if="noDocuments">
            <el-empty description="You don't have any document yet." />
                    <el-button type="primary" @click="goToUploadFile">
                        Go to upload file
                    </el-button>
        </div>

        <div v-else class="documents">

            <el-table v-loading="loading" :data="documents">
                <el-table-column prop="id" label="Id" width="50" />

                <el-table-column prop="name" label="Name" />

                <el-table-column label="Language">
                    <template #default="scope">
                        {{ languages(scope.row) }}
                    </template>
                </el-table-column>

                <el-table-column label="Created on">
                    <template #default="scope">
                        {{ mapDate(scope.row.createdAt) }}
                    </template>
                </el-table-column>

                <el-table-column label="Updated on">
                    <template #default="scope">
                        {{ mapDateTime(scope.row.updatedAt) }}
                    </template>
                </el-table-column>

                <el-table-column label="Progress">
                    <template #default="scope">
                        <ProgressTags :rowData="scope.row" />
                    </template>
                </el-table-column>

                <el-table-column label="State">
                    <template #default="scope">
                        <DocumentState :rowData="scope.row" />
                    </template>
                </el-table-column>

                <el-table-column fixed="right" label="Operations">
                    <template #default="scope">

                        <div class="file-actions">

                            <EditButton :rowData="scope.row"/>

                            <FileActions :rowData="scope.row"
                                         @archiveAction="archiveDialog"
                                         @deleteAction="deleteDialog"                                         
                                         />
                        </div>

                    </template>
                </el-table-column>

            </el-table>

            <el-pagination layout="prev, pager, next"
                           :total="rowCount"
                           :default-page-size="pageSize"
                           @current-change="goToPage" />

        </div>


        <el-dialog v-model="archiveDialogVisible"
                   title="Archive"
                   width="30%"
                   align-center>
            <span>This action will move document to archive and you would be able to review it later. Be sure that you have downloadded document, it wouldn't be possible to da later. Continue?</span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeArchiveDialog">Cancel</el-button>
                    <el-button type="primary" @click="archiveConfirm">
                        Confirm
                    </el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog v-model="deleteDialogVisible"
                   title="Delete"
                   width="30%"
                   align-center>
            <span>This action will delete document. Be sure that you have downloadded document. Continue?</span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeDeleteDialog">Cancel</el-button>
                    <el-button type="primary" @click="deleteConfirm">
                        Confirm
                    </el-button>
                </span>
            </template>
        </el-dialog>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { ConfigurationOptions, IdNamePair } from '@/models/ConfigurationOptions'
    import { RequestOptions } from '@/models/RequestOptions'

    import { translationConfigService } from '@/components/TranslationConfig/translationConfigService'
    import { homePageService } from '@/pages/Home/homePageService'

    import ProgressTags from '@/components/Home/progressTags.vue'

    import EditButton from './editButton.vue'
    import FileActions from './fileActions.vue'
    import DocumentState from './documentState.vue'


    export default defineComponent({
        props: {
        },
        components: {
            ProgressTags,
            EditButton,
            FileActions,
            DocumentState,
        },
        data() {
            return {
                loading: false,
                documents: [] as any[],
                homePageService: new homePageService(),
                translationConfigService: new translationConfigService(),

                configOptions: null as ConfigurationOptions | null,

                deleteDialogVisible: false,
                archiveDialogVisible: false,

                deleteItemId: 0,
                archiveItemId: 0,

                currentPageNumber: 1,
                pageSize: 10,
                rowCount: 0,
            };
        },
        created() {
            this.init();
        },
        computed: {
            requestOptions(): RequestOptions {
                var request: RequestOptions = {
                    Paging: {
                        PageNumber: this.currentPageNumber,
                        PageSize: this.pageSize
                    },
                }
                return request;
            },
            noDocuments(): boolean {
                return this.rowCount === 0;
            }
        },
        methods: {
            async init() {
                this.loading = true;

                await this.loadDocuments();
                this.configOptions = await this.translationConfigService.getConfigOptions();

                this.loading = false;
            },
            async loadDocuments() {
                var pagedResult = await this.homePageService.loadDocuments(this.requestOptions);
                if (pagedResult != undefined && pagedResult != null) {
                    this.rowCount = pagedResult.rowCount;
                    this.documents = pagedResult.results;
                }
            },
            languages(item: any) {
                let sourceLang = this.configOptions?.languages.find((l: IdNamePair) => l.id == item.translationConfig.sourceLanguageId);
                let targeLang = this.configOptions?.languages.find((l: IdNamePair) => l.id == item.translationConfig.targetLanguageId);

                return `${sourceLang?.name} - ${targeLang?.name}`
            },

            mapDate(dateString: any) {
                return new Date(dateString).toLocaleDateString()
            },
            mapDateTime(dateString: any) {
                return new Date(dateString).toLocaleString()
            },


            archiveDialog(documentId: number) {
                this.archiveDialogVisible = true;
                this.archiveItemId = documentId;
            },

            deleteDialog(documentId: number) {
                this.deleteDialogVisible = true;
                this.deleteItemId = documentId;
            },

            async archiveConfirm() {
                this.loading = true;

                await this.homePageService.archiveDocument(this.archiveItemId);
                await this.loadDocuments();

                this.loading = false;

                this.$emit('document-achived');

                this.closeArchiveDialog();
            },

            closeArchiveDialog() {
                this.archiveDialogVisible = false;
                this.archiveItemId = 0;
            },

            async deleteConfirm() {
                this.loading = true;

                await this.homePageService.deleteDocument(this.deleteItemId);
                await this.loadDocuments();

                this.loading = false;
                this.$emit('document-deleted');

                this.closeDeleteDialog()
            },

            closeDeleteDialog() {
                this.deleteDialogVisible = false;
                this.deleteItemId = 0;
            },

            async goToPage(pageNumebr: number) {
                this.currentPageNumber = pageNumebr;
                await this.loadDocuments();
            },

            goToUploadFile() {
                this.$emit('go-file-upload');
            }

        }
    })
</script>

<style lang="scss" scoped>
    .documents {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .file-actions {
        display: flex;
        justify-content: space-around;
    }
</style>