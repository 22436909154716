import Delta from "quill-delta";

export class deltaBuilder {

    static createDeltaFromText(textString: string) {
        const delta = new Delta()
            .insert(textString + '\n');
        return delta;
    }

    static createDelta(ops: any) {
        const delta = new Delta(ops)
        return delta;
    }

    static createEmptyDelta() {
        const delta = new Delta();
        return delta;
    }
}
