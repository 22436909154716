import { apiClient } from "@/utils/apiClient";
import { clientCache } from "@/utils/clientCache";

const oneDayInSeconds = 24 * 60 * 60;

export class userSettingsService {

    requestClient: any;

    static clearCache() {
        this.removeUserSettingsFromCache();
    }

    constructor() {
        this.requestClient = new apiClient();
    }

    async getUserTranslationConfig() {
        const userSettings = await this.getUserSettins();
        return userSettings?.pageConfig;
    }

    async getUserTourConfig() {
        const userSettings = await this.getUserSettins();
        return userSettings?.tourConfig;
    }

    async getSkipUploadNotification() {
        const userSettings = await this.getUserSettins();
        return userSettings?.uploadFileNotification;
    }

    async getUserSettins() {
        const userSettings = this.getUserSettingsFromCache();
        if (userSettings !== null) {
            return userSettings;
        }

        const loaddedUserSettings = await this.loadUserSettings()
        return loaddedUserSettings;
    }

    async saveTranslationConfig(config: any) {
        this.clearUserSettings();
        return await this.requestClient.saveTranslationConfig(config);
    }

    async markTourAsPassed() {
        this.clearUserSettings();
        return await this.requestClient.markTourAsPassed();
    }

    async skipTourToday() {
        this.clearUserSettings();
        return await this.requestClient.skipTourToday();
    }

    async hideUploadFileMessage() {
        this.clearUserSettings();
        return await this.requestClient.hideUploadFileMessage();
    }

    private async loadUserSettings() {
        const userSettings = await this.requestClient.getUserSettins();

        if (userSettings != null) {
            this.setUserSettingsToCache(userSettings);
        }

        return userSettings;
    }

    private clearUserSettings() {
        userSettingsService.removeUserSettingsFromCache()
    }

    private getUserSettingsFromCache() {
        return clientCache.get('userSettings');
    }

    private setUserSettingsToCache(value: any) {
        clientCache.set('userSettings', value, oneDayInSeconds);
    }

    static removeUserSettingsFromCache() {
        clientCache.remove('userSettings');
    }


}