<template>
    <el-col :span="6"
            id="translation-subject">
        <p>Subject</p>
        <el-select v-model="configuration.subjectId"
                   placeholder="Subject"
                   @change="clearSubSubjects"
                   default-first-option
                   filterable
                   :disabled="disabled">

            <el-option v-for="(item) in subjects"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id" />
        </el-select>
    </el-col>

    <el-col :span="1" />

    <el-col :span="5"
            id="translation-sub-subject">
        <p>Subsubject</p>
        <el-select v-model="subSubject"
                   @change="valueChanged"
                   placeholder="Not selected"
                   clearable
                   default-first-option
                   filterable
                   :disabled="disabled">

            <el-option v-for="(item) in subsubjects"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id" />
        </el-select>

    </el-col>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    import { TranslationConfiguration } from '@/models/TranslationConfiguration'
    import { ConfigurationOptions } from '@/models/ConfigurationOptions'
    import { EMPTY_SUB_SUBJECT_ID } from '@/utils/constants'

    import { translationConfigService } from '../translationConfigService'

    export default defineComponent({
        props: {
            modelValue: Object as PropType<TranslationConfiguration>,
            disabled: {
                type: Boolean,
                defaultValue: false
            },
        },
        emits: ['input', 'valueChanged'],
        data() {
            return {
                translationConfigService: new translationConfigService(),

                configurationOptions: null as ConfigurationOptions | null,
                subSubjects: [],
            }
        },
        mounted() {
            this.init();
        },
        watch: {
            'configuration.subjectId': {
                immediate: true,
                async handler() {
                    await this.loadSubSubjects();
                },
            },
        },
        computed: {
            configuration: {
                get(): TranslationConfiguration { return this.modelValue as TranslationConfiguration; },
                set(value: TranslationConfiguration) { this.$emit('input', value); }
            },
            subjects(): any {
                return this.configurationOptions?.subjects;
            },
            subsubjects(): any {
                return this.subSubjects;
            },

            subSubject: {
                get(): any { return this.configuration.subSubjectId == EMPTY_SUB_SUBJECT_ID ? null : this.configuration.subSubjectId; },
                set(value: any) {
                    var setVal = value
                    if (setVal == '' || setVal == undefined) {
                        setVal = EMPTY_SUB_SUBJECT_ID;
                    }
                    this.configuration.subSubjectId = setVal;
                }
            },
        },
        methods: {
            async init() {
                this.configurationOptions = await this.translationConfigService.getConfigOptions()
            },
            async loadSubSubjects() {
                if (this.configuration.subjectId) {
                    this.subSubjects = await this.translationConfigService.getSubSubjectOptions(this.configuration.subjectId);
                }
            },
            clearSubSubjects() {
                this.configuration.subSubjectId = EMPTY_SUB_SUBJECT_ID;
                this.valueChanged();
            },
            valueChanged() {
                this.$emit('valueChanged');
            },
        }
    })
</script>
