import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1d3e8444"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "limit-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_ctx.showLicenseMessage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_tag, {
          size: "large",
          type: _ctx.tagType,
          effect: "light"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.licenseWarningMessage), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ]))
    : _createCommentVNode("", true)
}