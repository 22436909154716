<template>
    <div class="translate-copy">

        <ButtonTemplate id="copy-button"
                        :disabled="copyDisabled"
                        :tooltipMessage="copyTooltip"
                        @buttonClick="doCopy">
            <span class="material-symbols-outlined">
                content_copy
            </span>
        </ButtonTemplate>

        <ButtonTemplate id="translate-button"
                        :disabled="false"
                        :tooltipMessage="translateTooltip"
                        @buttonClick="doTranslate">
            <span class="material-symbols-outlined">
                translate
            </span>
        </ButtonTemplate>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    
    import { shortcuts } from '@/utils/shortcuts';
    import { translationSegmentService } from '../translationSegmentService'
    
    import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
    import { SegmentEditorItem } from '@/models/SegmentEditorItem';

    import ButtonTemplate from '@/components/ButtonTemplate.vue';

    export default defineComponent({
        props: {
            modelValue: Object,
        },
        components: {
            ButtonTemplate,
        },
        data() {
            return {
                translationSegmentService: translationSegmentService.getInstance(),
            }
        },
        computed: {
            segmentItem: {
                get(): SegmentEditorItem { return this.modelValue as SegmentEditorItem; },
                set(value: SegmentEditorItem) { this.$emit('input', value); }
            },
            translationSegment: {
                get(): TranslationSegment { return this.segmentItem.data as TranslationSegment; },
                set(value: TranslationSegment) { this.segmentItem.data = value }
            },
            copyTooltip(): string {
                return shortcuts.getTooltip(shortcuts.copy);
            },
            translateTooltip(): string {
                return shortcuts.getTooltip(shortcuts.translate);
            },
            copyDisabled(): boolean {
                return this.translationSegment.translationVariants == null;
            },
        },
        methods: {
            doCopy() {
                this.translationSegmentService.copy(this.translationSegment.translationSegmentId);
            },
            doTranslate() {
                this.translationSegmentService.translate(this.translationSegment.translationSegmentId);
            },
        }

    });
</script>

<style lang="scss" scoped>
    .translate-copy {
        width: 60px;
        margin: 12px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }
</style>