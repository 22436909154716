<template>
    <TextFormatEditor v-model="textData"
                      :readOnly="true" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            modelValue: Object
        },
        data() {
            return {
                textData: {
                    text: '',
                    delta: {}
                },
            }
        },
        watch: {
            modelValue: {
                immediate: true,
                handler: function (val: any) {
                    this.textData = val;
                }
            }
        }
    });
</script>

<style lang="scss" scoped>
    :deep().ql-editor > * {
        cursor: default;
    }
</style>
