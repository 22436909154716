<template>
    <div v-if="showLicenseMessage"
         class="limit-container">
        <el-tag size="large"
                :type="tagType"
                effect="light">
            {{licenseWarningMessage}}
        </el-tag>
        <!--commentted out because of the missing link-->
        <!--<el-button type="primary"
                    tag="a"
                    :href="solutionsAndPricesUrl"
                    target="_blank"
                    rel="noopener noreferrer">
            {{upgradeLicenceMessage}}
        </el-button>-->
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { externalTranslationService } from './externalTranslationService';

    const seventyFivePercentMessage = 'Warning: You have reached 75% of your scope! Contact WWS.';
    const ninetyPercentMessage = 'Last warning: You have reached 90% of your scope! Contact WWS.';
    const limitReachedMessage = 'You reached the limit of external translation service for today. Contact WWS.';

    const upgradeLicenceMessage = 'Upgrade your licence!';

    export default defineComponent({
        data() {
            return {
                externalTranslationService: externalTranslationService.getInstance(),
                showLicenseMessage: false,
                licenseWarningMessage: '',
                tagType: 'warning',
            }
        },
        created() {
            this.updateMessage();
        },
        computed: {
            upgradeLicenceMessage(): string {
                return upgradeLicenceMessage;
            },
            solutionsAndPricesUrl(): string {
                return process.env.VUE_APP_SOULUTIONS_AND_PRICES;
            },
        },
        methods: {
            updateMessage() {
                if (this.externalTranslationService.reachedHundredPercent()) {
                    this.showLicenseMessage = true;
                    this.licenseWarningMessage = limitReachedMessage;
                    this.tagType = 'danger';
                } else if (this.externalTranslationService.reachedNinetyPercent()) {
                    this.showLicenseMessage = true;
                    this.licenseWarningMessage = ninetyPercentMessage;
                    this.tagType = 'warning';
                } else if (this.externalTranslationService.reachedSeventyFivePercent()) {
                    this.showLicenseMessage = true;
                    this.licenseWarningMessage = seventyFivePercentMessage;
                    this.tagType = 'warning';
                }
            },
        }

    })

</script>

<style scoped lang="scss">
    .limit-container {
        display: flex;
        justify-content: right;

        & > * {
            margin-right: 10px;
        }
    }
</style>