<template>
    <div>

        <el-button id="submitt-file"
                   class="upload-button"
                   :disabled="submitDisabled"
                   @click="sublitButtonHandler"
                   type="primary">
            Start Translation
        </el-button>

        <el-dialog v-model="uploadFileDialogVisible"
                   :title="uploadFileTittle"
                   width="30%"
                   align-center>
            <span>{{ uploadFileText }}</span>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="neverShowDialog" type="info">Newer show this dialog</el-button>
                    <div>
                        <el-button @click="uploadFile" type="primary">Continue</el-button>
                        <el-button @click="closePopup">Back to configurations</el-button>
                    </div>
                </div>
            </template>
        </el-dialog>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { userSettingsService } from '@/services/userSettingsService'

    export default defineComponent({
        props: {
            submitDisabled: {
                type: Boolean,
                required: true
            }
        },
        created() {
            this.init();
        },
        data() {
            return {
                showFileUploadPrompt: true,

                uploadFileDialogVisible: false,
                uploadFileTittle: `Reminder`,
                uploadFileText: `Ensure that the source and target languages are correctly configured, and the subjects are properly set. You can always modify these settings in the 'Translation settings' section on the document page.`,

                userSettingsService: new userSettingsService(),

            };
        },
        methods: {
            async init() {
                var uploadNotificationConfig = await this.userSettingsService.getSkipUploadNotification();
                this.showFileUploadPrompt = uploadNotificationConfig == null || uploadNotificationConfig == false;
            },
            async sublitButtonHandler() {
                if(this.showFileUploadPrompt){
                    this.uploadFileDialogVisible = true;
                    return;
                }

                this.$emit('submitted');
            },
            async neverShowDialog(){
                await this.userSettingsService.hideUploadFileMessage();
                this.uploadFile();
            },
            uploadFile(){
                this.uploadFileDialogVisible = false;
                this.$emit('submitted');
            },
            closePopup(){
                this.uploadFileDialogVisible = false;
                this.showFileUploadPrompt = false;
            },
        }
    })
</script>

<style scoped>
    .upload-button {
        margin-top: 20px;
    }

    .dialog-footer {
        display: flex;
        justify-content: space-between;
    }

</style>