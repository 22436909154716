import router from '../router'

export class navigationService {

    static homePageName = 'home';
    static documentPageName = 'document';
    static archivedPageName = 'archived';
    static forbiddenPageName = 'forbidden';
    static pageNotFoundPageName = 'pageNotFound';

    static goHomePage() {
        router.push('/')
    }

    static goAnonymousPage() {
        router.push('/anonymous')
    }

    static goForbiddenPage() {
        router.push('/forbidden')
    }

    static goNotFoundPage() {
        router.push('/pageNotFound')
    }

    static goLogoutPage() {
        router.push('/logout')
    }

    static goUserInfoPage() {
        router.push('/user/userInfo')
    }

    static goDocumentPage(documentId: number) {
        router.push(this.getDocumentUrl(documentId))
    }

    static getDocumentUrl(documentId: number) {
        return `document/${documentId}`;
    }

    static goArchivedDocumentPage(documentId: number) {
        router.push(this.getArchivedDocumentUrl(documentId))
    }

    static getArchivedDocumentUrl(documentId: number) {
        return `archived-document/${documentId}`;
    }
}