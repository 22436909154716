<template>
    <div :class="[{'focused-item':focused}, 'merge-button-container']" v-if="mergePossible">

        <el-tooltip placement="top-start" :content="megreTooltip" :show-after="5">
            <div id="split-button"
                 class="img-button-container merge-button"
                 @click="splitSegment">
                <span class="material-symbols-outlined">
                    expand
                </span>
            </div>
        </el-tooltip>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { shortcuts } from '@/utils/shortcuts';

    export default defineComponent({
        props: {
            mergePossible: {
                type: Boolean,
                default: true
            },
            focused: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            megreTooltip(): string {
                return shortcuts.getTooltip(shortcuts.split);
            },
        },
        methods: {
            splitSegment() {
                this.$emit('split');
            }
        }

    });
</script>

<style lang="scss" scoped>

    .merge-button-container {
        height: 1px;
        width: 30%;
        position: absolute;
        opacity: 0;
        transition: opacity 0.5s ease;

        &:hover {
            opacity: 0.6;
        }
    }

    .focused-item.merge-button-container {
        opacity: 0.6;
    }

    .merge-button {
        position: absolute;
        top: -35px;
        left: 50px;
    }
</style>