<template>
    <TextFormatEditor v-model="translationSegment.draftData"
                      :setFocus="changeFocus"
                      @textChange="saveSetmentState"
                      @focusEvent="focusAction"
                      @editing="updateState"
                      />

</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { segmentStateService } from '@/services/translationSegment/segmentStateService';
    import { segmentApiService } from '@/services/translationSegment/segmentApiService';

    export default defineComponent({
        props: {
            modelValue: Object,
            setFocus: {
                type: Boolean,
                defaultValue: false
            },
        },
        watch: {
            setFocus() {
                this.changeFocus = !this.changeFocus;
            },
        },
        data() {
            return {
                segmentStateService: segmentStateService.getInstance(),
                segmentApiService: segmentApiService.getInstance(),
                changeFocus: false,
            }
        },
        computed: {
            translationSegment: {
                get(): any { return this.modelValue; },
                set(value: any) { this.$emit('input', value); }
            },
        },
        methods: {
            async saveSetmentState() {
                await this.segmentApiService.saveSegmentState(this.translationSegment);
            },
            updateState(){                
                this.segmentStateService.setNewState(this.translationSegment)
            },
            focusAction() {
                this.$emit("focus");
            },
        }
    });
</script>
