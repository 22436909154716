<template>
    <div class="source"
         v-html="sourceHtml(translationVariant.sourceSegment)">
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    import { Translation } from '@/models/TranslationSegment/TranslationVariants';
    import { TextData } from "@/models/TranslationSegment/TextData";

    export default defineComponent({
        props: {
            sourceData: {
                type: Object as PropType<TextData>,
                mandatory: true
            },
            translationVariant: {
                type: Object as PropType<Translation>,
                mandatory: true
            }
        },
        computed: {
            source(): string {
                return this.sourceData ? this.sourceData.text : '';
            },
        },
        methods: {
            sourceHtml(variantSourceText: string) {

                let sourceSentenseWords = this.parceToWordsArray(this.source);
                let variantSentenseWords = this.parceToWordsArray(variantSourceText);

                if (sourceSentenseWords == null || variantSentenseWords == null) {
                    return `<p class="text">${this.source}</p>`;
                }

                let res = variantSentenseWords.filter((i: string) => !sourceSentenseWords?.includes(i));

                var text = "";
                for (var i = 0; i < variantSentenseWords.length; i++) {
                    var hasVal = res?.includes(variantSentenseWords[i]);
                    if (hasVal) {
                        text += " <span class='imp'>" + variantSentenseWords[i] + "</span> ";
                    } else {
                        text += " " + variantSentenseWords[i] + " ";
                    }
                }

                return `<p class="text">${text}</p>`;
            },

            parceToWordsArray(sentance: string) {
                return sentance.match(/[^\s,.!?;:()]+/g) || [];
            },
        }
    })

</script>

<style lang='scss' scoped>
    .source {
        & :deep() .imp {
            color: red
        }

        & :deep() .text {
            margin: 15px 0 15px 5px;
            word-break: auto-phrase;
        }
    }
</style>