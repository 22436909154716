<template>

    <div class="match">
        <div :class="['chip', matchStyle ]">
            {{translationVariant.matchPercent}}%
        </div>
    </div>

</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { Translation } from '@/models/TranslationSegment/TranslationVariants';

    export default defineComponent({
        props: {
            translationVariant: {
                type: Object as PropType<Translation>,
                mandatory: true
            }
        },
        computed: {
            matchStyle(): string{
                const matchPersent = this.translationVariant ? this.translationVariant.matchPercent : 0;

                switch (true) {
                    case (matchPersent == 100):
                        return 'match-100';
                    case (matchPersent > 80):
                        return 'match-80';
                    case (matchPersent > 60):
                        return 'match-60';
                    default:
                        return 'match-50';
                }
            },
        },
    })

</script>

<style lang="scss" scoped>
    .match {
        display: flex;
        justify-content: center;

        .chip {
            padding: 3px 10px;
            border-style: solid;
            border-radius: 18px;
            display: flex;
            justify-content: center;
            width: fit-content;
        }

        &-100 {
            border-color: green;
            background-color: #0080005c;
        }

        &-80 {
            border-color: greenyellow;
            background-color: #adff2f70;
        }

        &-60 {
            border-color: yellow;
            background-color: #ffff0080;
        }

        &-50 {
            border-color: orange;
            background-color: #ffa50082;
        }
    }

</style>