<template>
    <div class="state-img">
        <div v-if="showStateImage"
             :class="stateClass" />
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
    import { TranslationState } from '@/models/TranslationSegment/TranslationState';

    export default defineComponent({
        props: {
            modelValue: Object as PropType<TranslationSegment>,
        },
        watch: {
            'modelValue.translationState': {
                immediate: true,
                handler() {
                    this.stateClass = this.getStateClass();
                }
            }
        },
        data() {
            return {
                stateClass: '' 
            }
        },
        computed: {
            translationSegment(): TranslationSegment {
                return this.modelValue as TranslationSegment;
            },
            showStateImage(): boolean {
                return this.translationSegment.translationVariants != null;
            },
        },
        methods: {
            getStateClass() {
                switch (this.translationSegment.translationState) {
                    case TranslationState.Draft:
                        return 'draft';
                    case TranslationState.PreTranslated:
                        return 'fuzzy-match';
                    case TranslationState.Translated:
                        return 'done';
                    case TranslationState.AutoTranslated:
                        return 'auto-translated';
                    default:
                        return '';
                }
            }
        },
    });
</script>

<style lang="scss" scoped>

    .state-img {
        width: 24px;
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .done {
        background-image: url('@/images/complete.svg');
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
    }

    .draft {
        background-image: url('@/images/draft.svg');
        width: 22px;
        height: 22px;
        background-repeat: no-repeat;
    }

    .fuzzy-match {
        background-image: url('@/images/fuzzy-match.svg');
        width: 22px;
        height: 22px;
        background-repeat: no-repeat;
    }

    .auto-translated {
        -webkit-mask-image: url('@/images/auto.svg');
        -webkit-mask-size: 24x;
        -webkit-mask-repeat: no-repeat;
        mask-image: url('@/images/auto.svg');
        mask-size: 24px;
        mask-repeat: no-repeat;
        background-color: rgb(71, 119, 158);
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
    }
</style>
