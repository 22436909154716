<template>
    <div class="panel" v-loading="loading">

        <div v-for="(item, index) in translationSegments"
             :key="item.translationSegmentId">

            <SegmentItemPreview v-model="translationSegments[index]"
                                :rowNumber="getRowNumber(index)" />
        </div>

        <el-pagination layout="prev, pager, next"
                       :total="paging.rowCount"
                       :current-page="paging.currentPageNumber"
                       :default-page-size="paging.pageSize"
                       @current-change="goToPage" />
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { archivedDocumentService } from './archivedDocumentService'

    import { LoadSegmentsRequest } from '@/models/LoadSegmentsRequest';

    import SegmentItemPreview from './SegmentItemPreview.vue'

    export default defineComponent({
        components: {
            SegmentItemPreview,
        },

        data() {
            return {
                loading: false,

                archivedDocumentService: new archivedDocumentService(),
                translationSegments: [] as any,

                paging: {
                    currentPageNumber: 1,
                    pageSize: 50,
                    rowCount: 0,
                    pageCount: 0,
                },


            };
        },
        mounted() {
            this.init();
        },
        computed: {
            documentId(): Number {
                return Number(this.$route.params.id);
            },
            pageRequest(): LoadSegmentsRequest {
                var request: LoadSegmentsRequest = {
                    DocumentId: this.documentId,
                    Paging: {
                        PageNumber: this.paging.currentPageNumber,
                        PageSize: this.paging.pageSize
                    },
                }
                return request;
            },
        },
        methods: {
            async init() {
                this.loadTranslationSegments();
            },

            async loadTranslationSegments() {

                this.loading = true;

                var translationResult = await this.archivedDocumentService.loadTranslationSegments(this.pageRequest);

                this.translationSegments = translationResult.results;
                this.paging.rowCount = translationResult.rowCount;
                this.paging.pageCount = translationResult.pageCount;

                this.loading = false;
            },

            getRowNumber(index: number) {
                const baseNumber = (this.paging.currentPageNumber - 1) * this.paging.pageSize
                const currentPageRowNumber = index + 1;
                return baseNumber + currentPageRowNumber;
            },

            async goToPage(pageNumebr: number) {
                this.paging.currentPageNumber = pageNumebr;
                await this.loadTranslationSegments();
            },
        },
    })
</script>

<style lang="scss" scoped>

    .panel {
        padding: 20px 4px;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        border: 1px solid #e4e7ed;
        background-color: #ffffff;
        color: #303133;
        transition: 0.3s;
    }

</style>