<template>
    <div>

        <el-dialog v-model="newUserDialogVisible"
                   :title="newUserTittle"
                   width="30%"
                   align-center>
            <span>{{ newUserText }}</span>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="neverShowTours" type="info">Newer show this dialog</el-button>
                    <div>
                        <el-button @click="startTour" type="primary">Quick start</el-button>
                        <el-button @click="skipTourToday">Next time</el-button>
                    </div>
                </div>
            </template>
        </el-dialog>

        <el-tour v-model="tourStart">
            <el-tour-step v-for="step in tourSteps"
                          :key="step.id"
                          :target="step.target"
                          :title="step.title"
                          :description="step.description" />

        </el-tour>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { userGuideService } from './userGuideService'
    import { navigationService } from '@/services/navigationService'
    import { userSettingsService } from '@/services/userSettingsService'

    import { ElMessage } from 'element-plus'

    export default defineComponent({
        created(){
            this.init();
        },
        data() {
            return {
                newUserDialogVisible: false,
                newUserTittle: `Welcome to WebWordSystem!`,
                newUserText: `We're thrilled to have you join our community. Let's get you up and run it in no time. Click 'Quick start' and follow these simple steps.`,

                infoMessageText: `You can always find tours or shortcuts information in Info section.`,

                userSettingsService: new userSettingsService(),

                tourStart: false,
                tourSteps: userGuideService.getQuickStartGuide(),
            };
        },
        computed: {
            homePage(): boolean {
                return this.$route.name == navigationService.homePageName
            },
        },
        methods:{
            async init() {
                if (!this.homePage) {
                    return;
                }

                const userTourConfig = await this.userSettingsService.getUserTourConfig();

                if(userTourConfig == null){
                    this.showNewUserDialog();
                    return;
                }

                if(userTourConfig.introTourFinished){
                    return;
                }

                const dateIsToday = this.dateIsToday(userTourConfig.lastTourDate);
                if(dateIsToday){
                    return;
                }

                this.showNewUserDialog();
            },
            dateIsToday(dateStr: string){
                const today = new Date();
                const givenDate = new Date(dateStr);

                const isToday = givenDate.getDate() === today.getDate() &&
                                givenDate.getMonth() === today.getMonth() &&
                                givenDate.getFullYear() === today.getFullYear();

                return isToday;
            },
            showNewUserDialog(){ 
                this.newUserDialogVisible = true;    
            },
            async startTour(){
                await this.userSettingsService.markTourAsPassed();

                const result = document.getElementById("tab-fileUpload");
                if (result != null) {
                    result.click();
                }

                this.tourStart = true;
                this.newUserDialogVisible = false;
            },
            async skipTourToday(){
                this.newUserDialogVisible = false;    
                await this.userSettingsService.skipTourToday();
            },
            async neverShowTours(){
                this.newUserDialogVisible = false;    
                this.showInfoMessage();
                await this.userSettingsService.markTourAsPassed();
            },
            showInfoMessage() {
                ElMessage({
                    showClose: true,
                    message: this.infoMessageText,
                    type: 'success',
                })
            },
        }
    })
</script>

<style scoped>
    .dialog-footer {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
    }
</style>