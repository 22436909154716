import Delta from 'quill-delta';
import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
import { TextData } from '@/models/TranslationSegment/TextData';

import { deltaBuilder } from '@/utils/deltaBuilder';

export class deltaHelperService {

    getTargetDelta(translationSegment: TranslationSegment): Delta {
        if (translationSegment.segmentTranslation == null) {
            return deltaBuilder.createEmptyDelta();
        }

        if (translationSegment.segmentTranslation.targetDelta.ops.length == 0) {
            return deltaBuilder.createDeltaFromText(translationSegment.segmentTranslation.targetSegment);
        }

        return deltaBuilder.createDelta(translationSegment.segmentTranslation.targetDelta.ops);
    }

    getDraftDelta(translationSegment: TranslationSegment): Delta {
        if (translationSegment.segmentTranslation == null) {
            return deltaBuilder.createEmptyDelta();
        }

        if (translationSegment.draftData.delta.ops.length == 0) {
            return deltaBuilder.createDeltaFromText(translationSegment.draftData.text);
        }

        return deltaBuilder.createDelta(translationSegment.draftData.delta);
    }

    public clearDraft(segment: TranslationSegment): void {
        segment.draftData = this.getEmptyData();
    }

    public getEmptyData(): TextData {
        const data: TextData = {
            text: '',
            delta: deltaBuilder.createEmptyDelta()
        }

        return data;
    }

    public mapToTextData(text: string, delta: Delta | null = null): TextData {

        if (delta == null) {
            delta = deltaBuilder.createDeltaFromText(text)
        }

        const data: TextData = {
            text: text,
            delta: delta,
        }

        return data;
    } 

}