<template>
    <div class="translation-load-container">
        <el-button type="primary"
                   :disabled="!serviceAvailable"
                   @click="clickAction">
            {{loadExtermalTranslationButton}}
        </el-button>
        <div v-if="!serviceAvailable">
            {{externalServiceNotAvailableMessage}}
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    const loadExtermalTranslationButton = 'Load external translation'
    const externalServiceNotAvailableMessage = 'Sorry, external translation service is not available right now.';

    export default defineComponent({
        props: {
            serviceAvailable: {
                type: Boolean,
                defaultValue: false
            },
        },
        computed: {
            loadExtermalTranslationButton(): string {
                return loadExtermalTranslationButton;
            },
            externalServiceNotAvailableMessage(): string {
                return externalServiceNotAvailableMessage;
            },
        },
        methods: {
            clickAction() {
                this.$emit('loadButtonClick');
            },
        }
    })

</script>

<style scoped lang="scss">
    .translation-load-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        & > div {
            padding: 5px;
        }
    }
</style>