import { segmentsStore } from '@/stores/segmentsStore'
import { pagingStore } from '@/stores/pagingStore';
import { documentStore } from '@/stores/documentStore';

import { documentService } from '@/pages/Document/documentService';
import { segmentEditorItemFactory } from './segmentEditorItemFactory';

import { LoadSegmentsRequest } from '@/models/LoadSegmentsRequest';
import { SegmentEditorItem } from '@/models/SegmentEditorItem';

export class segmentsEditorService {
    documentService: documentService

    segmentsStore: any
    pagingStore: any
    documentStore: any

    constructor() {
        this.documentService = new documentService();

        this.segmentsStore = segmentsStore()
        this.pagingStore = pagingStore();
        this.documentStore = documentStore();
    }

    getCurrentSegmentEditorItem(): SegmentEditorItem{
        return this.segmentsStore.getCurrentSegmentEditorItem();
    }

    async loadSegments(documentId: Number) {
        const translationSegmentsResult = await this.loadDocumentSegments(documentId);
        this.setTranslationResult(translationSegmentsResult);
    }

    async loadSegmentsWithFocusOnLast(documentId: Number) {
        const translationSegmentsResult = await this.loadDocumentSegments(documentId);
        this.setIndexToLastSegment(translationSegmentsResult);
        this.setTranslationResult(translationSegmentsResult);
    }

    setIndexToLastSegment(translationResult: any) {
        const segmentsCount = translationResult.pageSize;

        if (segmentsCount > 0) {
            this.segmentsStore.currentSegmentIndex = segmentsCount - 1
        }
    }

    async loadDocumentSegments(documentId: Number) {
        let pageRequest = this.getPageRequest(documentId);
        let translationSegmentsResult = await this.documentService.loadTranslationSegments(pageRequest);

        if (translationSegmentsResult.results.length == 0 && translationSegmentsResult.rowCount > 0) {
            this.pagingStore.resetState();
            pageRequest = this.getPageRequest(documentId);
            translationSegmentsResult = await this.documentService.loadTranslationSegments(pageRequest);
        }

        return translationSegmentsResult;
    }

    setTranslationResult(translationResult: any) {
        this.setPaging(translationResult);
        this.setSegments(translationResult.results)
    }

    setPaging(translationResult: any) {
        this.pagingStore.setRowCount(translationResult.rowCount);
        this.pagingStore.setPageCount(translationResult.pageCount);
    }

    setSegments(data: any) {
        const segmentItems = segmentEditorItemFactory.mapToTranslateSegmentEditorItems(data);

        this.segmentsStore.setSegmentsArray(segmentItems);
        this.segmentsStore.buildSegmentProperties();
    }

    setFocusToSegment() {
        this.segmentsStore.buildSegmentProperties();
    }

    getPageRequest(documentId: Number): LoadSegmentsRequest {
        const request: LoadSegmentsRequest = {
            DocumentId: documentId,
            Paging: {
                PageNumber: this.pagingStore.currentPageNumber,
                PageSize: this.pagingStore.pageSize
            },
        }
        return request;
    }

    statrMerge(segmentId: number) {
        const mergeSegment = this.createMergeSegment(segmentId);
        const segmentPosition = this.segmentsStore.getSegmentPosition(segmentId);

        this.segmentsStore.addSegmentToArra(segmentPosition, mergeSegment);
    }

    createMergeSegment(segmentId: number) {
        const segmentItem = this.segmentsStore.getSegmentEditorItem(segmentId);
        return segmentEditorItemFactory.mapToMergeSegmentEditorItem(segmentItem);
    }

    statrSplit(segmentId: number) {
        const mergeSegment = this.createSplitSegment(segmentId);
        const segmentPosition = this.segmentsStore.getSegmentPosition(segmentId);

        this.segmentsStore.addSegmentToArra(segmentPosition, mergeSegment);
    }

    createSplitSegment(segmentId: number) {
        const segmentItem = this.segmentsStore.getSegmentEditorItem(segmentId);
        return segmentEditorItemFactory.mapToSplitSegmentEditorItem(segmentItem);
    }

    goToNextSegment() {
        if (this.segmentsStore.increaseCurrentSegmentIndex() == false) {
            this.goToNextPage()
        }
    }

    goToPreviousSegment() {
        if (this.segmentsStore.decreaseCurrentSegmentIndex() == false) {
            if (this.pagingStore.decreasePageNumber()) {
                this.pagingStore.backReload();
            }
        }
    }

    async goToNextWorkSegment() {
        const nextSegmentIndex = this.segmentsStore.getNextWorkSegmentIndex();

        if (nextSegmentIndex > -1) {
            this.segmentsStore.setCurrentSegmentIndex(nextSegmentIndex);
        } else {
            const currentSegment = this.segmentsStore.getCurrentSegmentEditorItem();
            await this.documentService.findNextWorkSegment(this.documentStore.documentId, currentSegment.itemId);
            this.documentStore.reloadDocument();
        }
    }

    goToNextPage() {
        this.pagingStore.goToNextPage()
    }

    goToPreviousPage() {
        this.pagingStore.goToPreviousPage()
    }

}