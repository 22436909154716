import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-098d6baa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tittle"
}
const _hoisted_2 = { class: "file-loader" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StorageStatusInfo = _resolveComponent("StorageStatusInfo")!
  const _component_TranslationConfig = _resolveComponent("TranslationConfig")!
  const _component_FileUploadPrompt = _resolveComponent("FileUploadPrompt")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_StorageStatusInfo, { documnetsCount: _ctx.documentCount }, null, 8, ["documnetsCount"]),
    _createElementVNode("div", null, [
      (!_ctx.uploadDisabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Select the file you want to translate. You can upload only .docx files. "))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", null, [
          _createTextVNode(" File "),
          _createElementVNode("input", {
            type: "file",
            id: "file-upload",
            ref: "file",
            accept: ".docx",
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleFileUpload())),
            disabled: _ctx.uploadDisabled,
            placeholder: "File"
          }, null, 40, _hoisted_3)
        ])
      ]),
      _createVNode(_component_TranslationConfig, {
        modelValue: _ctx.configuration,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.configuration) = $event)),
        onConfigChanged: _ctx.saveConfig,
        pageNamePrefix: "Default user",
        expended: true
      }, null, 8, ["modelValue", "onConfigChanged"]),
      _createVNode(_component_FileUploadPrompt, {
        submitDisabled: _ctx.submitDisabled,
        onSubmitted: _ctx.submitFile
      }, null, 8, ["submitDisabled", "onSubmitted"])
    ])
  ])), [
    [_directive_loading, _ctx.loading]
  ])
}