import ls from 'localstorage-slim';

export const clientCache = (function () {

    function setCookie(name: string, value: any, seconds: number) {
        let expires = "";
        if (seconds) {
            const date = new Date();
            date.setTime(date.getTime() + (seconds * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    function getCookie(name: string) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return undefined;
    }

    const set = (keyName: string, keyValue: any, ttlInSeconds: number) => {

        // incognito mode
        if (localStorage === undefined) {
            setCookie(keyName, JSON.stringify(keyValue), ttlInSeconds);
            return;
        }

        // store data in LocalStorage 
        ls.set(keyName, keyValue, { ttl: ttlInSeconds, encrypt: true });
    };

    const get = (keyName: string) => {

        // incognito mode
        if (localStorage === undefined) {
            const value = getCookie(keyName);
            return value === undefined ? value : JSON.parse(value);
        }

        return ls.get(keyName, { decrypt: true });
    };

    const remove = (keyName: any) => {

        if (localStorage === undefined) {
            setCookie(keyName, JSON.stringify({}), -1);
            return;
        }
        return ls.remove(keyName);
    };

    return {
        set,
        get,
        remove
    };
}());