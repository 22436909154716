<template>
    <div class="row">

        <div v-if="documentIsActive">
            <span v-if="fileReady" class="green">Done</span>

            <span v-if="newFile" class="blue">New</span>

            <span v-if="fileInProgress" class="orange">In progress</span>
        </div>
        <div v-else>
            <span class="grey">Reading issue</span>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { DocumentRow, DocumentStatus } from '@/models/DocumentRow'

    export default defineComponent({
        props: {
            rowData: Object as PropType<DocumentRow>,
        },
        computed: {
            documentRow(): DocumentRow{
                return this.rowData as DocumentRow;
            },
            documentIsActive(): Boolean{
                return this.documentRow.status == DocumentStatus.Active;
            }, 
            status(): DocumentStatus{
                return this.documentRow.status;
            },
            fileReady(): Boolean {
                return this.documentRow.translatedSegments == this.documentRow.totalSegments;
            },
            newFile(): Boolean {
                return this.documentRow.draftSegments == 0 &&
                    this.documentRow.translatedSegments == 0 &&
                    this.documentRow.preTranslatedSegments == 0
            },
            fileInProgress(): Boolean {
                return this.documentRow.draftSegments != 0 ||                    
                    this.documentRow.preTranslatedSegments != 0 ||
                    (this.documentRow.translatedSegments != 0 &&
                    this.documentRow.translatedSegments != this.documentRow.totalSegments)
            },
        },
    })
</script>

<style lang="scss" scoped>
    .row {
        .blue {
            color: #0d99ff;
        }

        .green {
            color: #14ae5c;
        }

        .orange {
            color: #ffa629;
        }

        .grey {
            color: #b0b0b0;
        }
    }
</style>