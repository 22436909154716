import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextFormatEditor = _resolveComponent("TextFormatEditor")!

  return (_openBlock(), _createBlock(_component_TextFormatEditor, {
    modelValue: _ctx.translationSegment.draftData,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.translationSegment.draftData) = $event)),
    setFocus: _ctx.changeFocus,
    onTextChange: _ctx.saveSetmentState,
    onFocusEvent: _ctx.focusAction,
    onEditing: _ctx.updateState
  }, null, 8, ["modelValue", "setFocus", "onTextChange", "onFocusEvent", "onEditing"]))
}