import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus'

import ComponentPlaceholder from './components/ComponentPlaceholder.vue';
import TextFormatEditor from './components/Quill/TextFormatEditor.vue'

import router from './router'

import './axios'
import 'element-plus/dist/index.css'

const pinia = createPinia()

createApp(App)
    .use(router)
    .use(pinia)
    .use(createPinia)
    .use(ElementPlus)

    .component('ComponentPlaceholder', ComponentPlaceholder)
    .component('TextFormatEditor', TextFormatEditor)

    .mount('#app')
