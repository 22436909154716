<template>
    <div class="row">
        <div class="sub-components">
            <div class="logo">
            </div>
            <div class="name">Document editor</div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    export default defineComponent({
        data() {
            return {
            };
        },
    })
</script>

<style lang="scss" scoped>
    .row {
        display: flex;
        justify-content: space-between;
        margin: 50px 10px;

        .logo {
            width: 70px;
            height: 43px;
            margin-right: 20px;
            background-image: url(/src/images/logo.svg);
        }

        .name {
            font-size: 1.5em;
        }

        .sub-components {
            display: flex;
            align-items: center;
        }
    }

</style>