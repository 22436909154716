import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0c13f902"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tags" }
const _hoisted_2 = {
  key: 0,
  class: "tag"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.className)
    }, _toDisplayString(_ctx.tagText), 3),
    (_ctx.coldNotBeSaved)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " #too-long "))
      : _createCommentVNode("", true)
  ]))
}