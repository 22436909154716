import Delta from "quill-delta";
import Op from "quill-delta/dist/Op";
import { TranslationSegment } from "@/models/TranslationSegment/TranslationSegment";
import { TextData } from "@/models/TranslationSegment/TextData";
import { deltaBuilder } from "@/utils/deltaBuilder";

export class DeltaMerger {

    static buildMergedSourceData(segmentsToMerge: TranslationSegment[]) {

        let newDelta = deltaBuilder.createEmptyDelta();
        let newText = '';

        segmentsToMerge.forEach(function (segment: TranslationSegment) {
            newText = `${newText} ${segment.sourceData.text}`;
            newDelta = DeltaMerger.mergeDelta(segment.sourceData.delta, newDelta)
        })

        const result: TextData = {
            text: newText.trim(),
            delta: newDelta.insert('\n'),
        }

        return result;
    }

    static mergeDelta(source: Delta, target: Delta) {
        const resultTarget = DeltaMerger.removeLineBreaks(source, target);
        return resultTarget;
    }

    static removeLineBreaks(source: Delta, target: Delta) {

        source.ops.forEach((op: Op) => {

            const insertLine = op.insert as string
            const result = insertLine.replace('\n', ' ');

            target.insert(result, op.attributes);
        })

        return target;
    }

}