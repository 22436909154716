import { SegmentEditorItem } from "@/models/SegmentEditorItem";
import { TranslationSegment } from "@/models/TranslationSegment/TranslationSegment";
import { SegmentEditorItemType } from "./SegmentEditorItemType";

export class segmentEditorItemFactory {

    private static createInstance(itemId: number, data: TranslationSegment, segmentType: SegmentEditorItemType): SegmentEditorItem {
        const segmentEditorItemInstance: SegmentEditorItem =
        {
            itemId: itemId,
            rowNumber: 0,
            mergePossible: true,
            data: data,
            focused: false,
            loading: false,
            variantsPopup: false,
            segmentIsVisibleOnList: true,
            segmentType: segmentType,
        }

        return segmentEditorItemInstance;
    }


    static createSegmentEditorItem(item: TranslationSegment, segmentType: SegmentEditorItemType): SegmentEditorItem {
        return segmentEditorItemFactory.createInstance(item.translationSegmentId, item, segmentType);
    }

    static mapSegmentEditorItem(item: SegmentEditorItem, segmentType: SegmentEditorItemType): SegmentEditorItem {
        return segmentEditorItemFactory.createInstance(item.itemId, item.data, segmentType);
    }

    static mapToTranslateSegmentEditorItem(item: TranslationSegment): SegmentEditorItem {
        return segmentEditorItemFactory.createSegmentEditorItem(item, SegmentEditorItemType.TranslationSegment);
    }

    static mapToSplitSegmentEditorItem(item: SegmentEditorItem): SegmentEditorItem {
        return segmentEditorItemFactory.mapSegmentEditorItem(item, SegmentEditorItemType.SplitSegment);
    }

    static mapToMergeSegmentEditorItem(item: SegmentEditorItem): SegmentEditorItem {
        return segmentEditorItemFactory.mapSegmentEditorItem(item, SegmentEditorItemType.MergeSegment);
    }

    static mapToTranslateSegmentEditorItems(items: TranslationSegment[]): SegmentEditorItem[] {
        const segmentEditorItems = items.map(segmentEditorItemFactory.mapToTranslateSegmentEditorItem);
        return segmentEditorItems;
    }

}