<template>
    <div v-loading="translationsLoading"
         class="external-providers">

        <el-divider />

        <LicenseMessage :key="licenseMessageUpdate" />

        <div v-if="extarnalTranslations">
            <ExternalVariantsView :translationSegment="translationSegment" />
        </div>
        <div v-else>
            <LoadExternalTranslations v-if="externalServiceAvailable"
                                      :serviceAvailable="serviceAvailable"
                                      @loadButtonClick="loadExternalTranslations"
                                      />
        </div>

    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
    import { TranslationConfiguration } from '@/models/TranslationConfiguration';

    import { externalTranslationService } from './externalTranslationService';
    import { externalProvidersStore } from '@/stores/externalProvidersStore';

    import LicenseMessage from './LicenseMessage.vue';
    import ExternalVariantsView from './ExternalVariantsView.vue';
    import LoadExternalTranslations from './LoadExternalTranslations.vue';


    export default defineComponent({
        props: {
            translationSegment: Object as PropType<TranslationSegment>,
            configuration: Object,
        },
        components: {
            LicenseMessage,
            ExternalVariantsView,
            LoadExternalTranslations,
        },
        data() {
            return {
                externalTranslationService: externalTranslationService.getInstance(),
                translationsLoading: false,
                externalProvidersStore: externalProvidersStore(),

                externalServiceAvailable: true,
                licenseMessageUpdate: false,
            }
        },
        emits: ['input', 'setDraft'],
        created() {
            this.checkServiceStatus();
        },
        computed: {
            extarnalTranslations(): boolean {
                return this.externalTranslationVariants.length > 0;
            },
            externalTranslationVariants(): any {
                if (this.translationSegment?.externalTranslationVariants == undefined || this.translationSegment.externalTranslationVariants == null) {
                    return [];
                }
                return this.translationSegment.externalTranslationVariants.translations;
            },  
            translationProvider(): any {
                return this.configuration?.externalTranslationProviders[0]
            },
            serviceAvailable(): boolean {
                return this.externalProvidersStore.providerIsAvailable(this.translationProvider)
            },
        },
        methods: {
            async loadExternalTranslations() {
                this.translationsLoading = true;
                await this.externalTranslationService.translateExternallyAction(this.translationSegment as TranslationSegment, this.configuration as TranslationConfiguration);
                this.checkServiceStatus();
                this.translationsLoading = false;
            },
            checkServiceStatus() {
                this.externalServiceAvailable = this.externalTranslationService.isServiceStatusOk()
                this.licenseMessageUpdate = !this.licenseMessageUpdate;
            },
        },
    })

</script>

<style lang="scss" scoped>
    .external-providers {
        margin-top: 20px;
    }
</style>