import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-779d827a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "term-value" }
const _hoisted_2 = { class: "term-list" }
const _hoisted_3 = { class: "translation-item-container" }
const _hoisted_4 = { class: "translation-item" }
const _hoisted_5 = { class: "term-definition" }
const _hoisted_6 = { class: "term-loadded" }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "source-text",
    key: _ctx.source
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sourceTextSegments, (word, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: index + word.text
      }, [
        (word.terminology)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.terminologyLoadded)
                ? (_openBlock(), _createBlock(_component_el_popover, {
                    key: 0,
                    trigger: "hover",
                    placement: "top",
                    "show-after": 100,
                    width: 260
                  }, {
                    reference: _withCtx(() => [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(word.text), 1)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_1, "\"" + _toDisplayString(word.text) + "\"", 1),
                        _createElementVNode("ul", _hoisted_2, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTerms(word.text), (term, index) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: index + term.targetTerm,
                              class: "term-item"
                            }, [
                              _createElementVNode("div", _hoisted_3, [
                                _createElementVNode("span", _hoisted_4, _toDisplayString(term.targetTerm), 1),
                                _createVNode(_component_el_button, {
                                  onClick: ($event: any) => (_ctx.copyTerm(term.targetTerm))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Copy ")
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _createElementVNode("div", _hoisted_5, _toDisplayString(term.definition), 1)
                            ]))
                          }), 128))
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1024))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: "term",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadTerms && _ctx.loadTerms(...args)))
                  }, _toDisplayString(word.text), 1))
            ], 64))
          : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(word.text), 1))
      ], 64))
    }), 128))
  ])), [
    [_directive_loading, _ctx.termsLoading]
  ])
}