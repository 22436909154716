<template>
    <div>
        <el-button type="primary"
                   @click="startTour"
                   :disabled="!tourAvailable">
            {{tourName}}
        </el-button>

        <el-tour v-model="tourStart">
            <el-tour-step v-for="step in tourSteps"
                          :key="step.id"
                          :target="step.target"
                          :title="step.title"
                          :description="step.description" />

        </el-tour>
    </div>
</template>

<script lang="ts">
    import { defineComponent, nextTick } from 'vue';

    export default defineComponent({
        props: {
            tourName: {
                type: String,
                default: "Start tour",
            },
            tourSteps: {
                type: Array,
                default() {
                    return []
                }
            },
            tourAvailable: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                tourStart: false,
            };
        },
        methods: {
            async startTour() {
                await this.clickOnButton()
                this.tourStart = true;
            },

            async clickOnButton(){
                const element = document.getElementById("configuration-colapse");

                if(element == null){ return; }

                if(element.classList.contains("is-active")){ return; }
                        
                const button = element.getElementsByTagName("button")[0];
                if(button == null){ return; }
                
                button.click();
                await nextTick();
            }

        }
    })
</script>