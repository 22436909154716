import { defineStore } from 'pinia'

export const mergeStore = defineStore('mergeStore', {
    state: () => ({
        segmentId: 0,
        mergeSegmentsCount: 2,
        maxMergeSegmentsCount: 2,
        mergeArray: [] as number[],
    }),

    getters: {
        canIncrease: (state) => state.maxMergeSegmentsCount > state.mergeSegmentsCount,
        canDecrease: (state) => state.mergeSegmentsCount > 2,
    },

    actions: {
        init() {
            this.segmentId = 0;
            this.mergeSegmentsCount= 2;
            this.maxMergeSegmentsCount = 2;
        },

        setSegmentId(id: number) {
            this.segmentId = id;
        },

        setMaxMergeSegmentsCount(count: number) {
            this.maxMergeSegmentsCount = count;
        },

        setMergeArray(array: number[]) {
            this.mergeArray = array;
        },

        increaseMergeSegmentsCount() {
            if (this.mergeSegmentsCount >= this.maxMergeSegmentsCount) {
                return false;
            }

            this.mergeSegmentsCount++;
            return true
        },

        decreaseMergeSegmentsCount() {
            if(this.mergeSegmentsCount <= 2) {
                return false;
            }
            this.mergeSegmentsCount--;
            return true
        }


    }
})
