<template>
    <BasePage>
        <UnauthorisedHeader />
        <router-view />
    </BasePage>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import BasePage from './BasePage.vue'
    import UnauthorisedHeader from './UnauthorisedHeader.vue'

    export default defineComponent({
        components: {
            BasePage,
            UnauthorisedHeader
        },
    })
</script>

