<template>
    <div>
        <el-button type="primary"
                   @click="startStuleTour"
                   :disabled="!tourAvailable">
            {{tourName}}
        </el-button>

        <el-tour v-model="tourStart"
                 :target-area-clickable="false">

            <el-tour-step :target="targetOne"
                          :title="tittleOne">
                <div>{{descriptionOne}}</div>
            </el-tour-step>

            <el-tour-step :target="targetTwo"
                          :title="tittleTwo">
                <div>{{descriptionTwo}}</div>
            </el-tour-step>

            <el-tour-step :target="targetThree"
                          :title="tittleThree">
                <div>{{descriptionThree}}</div>
            </el-tour-step>

            <el-tour-step :target="targetFour"
                          :title="tittleFour"
                          :content-style="contentStyleFour"
                          placement="left" >

                <div>{{descriptionFour}}</div>

                <img class="screenshot"
                     src="@/images/Tours/load-external-translation.png"
                     alt="tour.png" />
            </el-tour-step>

            <el-tour-step :target="targetFive"
                          :title="tittleFive"
                          :content-style="contentStyleFive"
                          placement="left">

                <div>{{descriptionFive}}</div>

                <img class="screenshot"
                     src="@/images/Tours/use-external-translation.png"
                     alt="tour.png" />
            </el-tour-step>

        </el-tour>
    </div>
</template>

<script lang="ts">
    import { defineComponent, nextTick } from 'vue';

    export default defineComponent({
        props: {
            tourAvailable: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                tourName: "External translation",
                tourStart: false,

                targetOne: "#use-external-translations",
                tittleOne: "Enable external translations",
                descriptionOne: `Checkbox should be checked.`,

                targetTwo: "#translation-providers",
                tittleTwo: "Select translation provider",
                descriptionTwo: "Select external translation provider. Depending on language configurations (source and target language) list of translation providers may vary or be absent.",

                targetThree: "#variants-button",
                tittleThree: "Open translation variants",
                descriptionThree: "Open translation variants popup.",

                targetFour: "#variants-button",
                tittleFour: "Load external translations",
                descriptionFour: "Click the button to load external translation variants.",
                contentStyleFour: { width: '800px' },

                targetFive: "#variants-button",
                tittleFive: "Select external translation",
                descriptionFive: "When translation is loaded you can click the translation (1) and set it to target field. When external translation is selected you will see (2) icon and tag #auto that represent 'automatic translation'.",
                contentStyleFive: { width: '1000px' },
            };
        },

        methods: {
            async startStuleTour() {
                const element = document.getElementById("configuration-colapse");

                if(element == null){ return; }

                if(element.classList.contains("is-active")){
                    this.tourStart = true;
                    return;
                }
                        
                const button = element.getElementsByTagName("button")[0];
                if(button == null){ return; }
                
                button.click();
                await nextTick();

                this.tourStart = true;
            },
        }

    })

</script>

<style scoped>
    .screenshot {
        max-width: 100%;
        margin-top: 10px;
    }
</style>
