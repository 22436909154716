<template>
    <DefaultTour :tour-name="tourName"
                 :tour-steps="tourSteps"
                 :tour-available="tourAvailable" />
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import { userGuideService } from '../userGuideService'

    import DefaultTour from './BaseTours/DefaultTour.vue'

    export default defineComponent({
        props: {
            tourAvailable: {
                type: Boolean,
                default: false,
            }
        },
        components: {
            DefaultTour,
        },
        data() {
            return {
                tourName: "Load translation",
                tourSteps: userGuideService.getLoadTranslation(),
            };
        },
    })
</script>