<template>
    <div class="source-text"
         :key="source"
         v-loading="termsLoading">
        <template v-for="(word, index) in sourceTextSegments" :key="index + word.text">

            <template v-if="word.terminology">

                <el-popover v-if="terminologyLoadded"
                            trigger="hover"
                            placement="top"
                            :show-after="100"
                            :width="260">

                    <div>
                        <div class="term-value">"{{word.text}}"</div>
                        <ul class="term-list">
                            <li v-for="(term, index) in getTerms(word.text)"
                                :key="index + term.targetTerm"
                                class="term-item">
                                <div class="translation-item-container">
                                    <span class="translation-item">{{term.targetTerm}}</span>
                                    <el-button @click="copyTerm(term.targetTerm)">
                                        Copy
                                    </el-button>
                                </div>
                                <div class="term-definition">{{term.definition}}</div>

                            </li>
                        </ul>
                    </div>

                    <template #reference>
                        <span class="term-loadded">{{word.text}}</span>
                    </template>
                </el-popover>

                <span v-else
                      class="term"
                      @click="loadTerms">{{word.text}}</span>

            </template>

            <span v-else>{{word.text}}</span>
        </template>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';

    import { segmentApiService } from '@/services/translationSegment/segmentApiService';

    export default defineComponent({
        props: {
            modelValue: Object,
            configuration: Object
        },
        data() {
            return {
                termsLoading: false,
                segmentApiService: segmentApiService.getInstance(),
            }
        },
        computed: {
            translationSegment: {
                get(): TranslationSegment { return this.modelValue as TranslationSegment; },
                set(value: any) { this.$emit('input', value); }
            },

            source(): string {
                return this.translationSegment.sourceData.text;
            },

            sourceTextSegments(): any {
                let resultArr = [];

                if (this.translationSegment.termPositions != null &&
                    this.translationSegment.termPositions.length > 0) {

                    let lastEndPositon = 0;

                    for (var termPosition of this.translationSegment.termPositions) {

                        if (lastEndPositon != termPosition.begin) {
                            resultArr.push({
                                text: this.source.slice(lastEndPositon, termPosition.begin),
                                terminology: false,
                            })
                        }

                        resultArr.push({
                            text: this.source.slice(termPosition.begin, termPosition.end),
                            terminology: true,
                        })
                        lastEndPositon = termPosition.end;

                    }

                    resultArr.push({
                        text: this.source.slice(lastEndPositon, this.source.length),
                        terminology: false,
                    })

                } else {
                    resultArr.push({
                        text: this.source,
                        terminology: false,
                    })
                }

                return resultArr;                
            },

            termsArray(): any {
                let terms = [];

                if (this.translationSegment != null) {
                    for (var termPosition of this.translationSegment.termPositions) {
                        terms.push(this.source.slice(termPosition.begin, termPosition.end).toLowerCase())
                    }
                }

                return new Set(terms);
            },


            terminologyLoadded(): boolean {
                return this.translationSegment.terminologyVariants != null &&
                        this.translationSegment.terminologyVariants.length > 0
            },
        },
        methods: {
            async loadTerms() {
                if (this.translationSegment == null)
                    return;

                this.termsLoading = true;

                let terms = Array.from(this.termsArray);
                let termTranslations = await this.segmentApiService.getTerminology(this.configuration, terms)

                let resultArray = [];

                for (let termTranslation of termTranslations) {

                    let term = {
                        key: termTranslation.searchResults[0].sourceTerm,
                        values: termTranslation.searchResults,
                    }
                    resultArray.push(term);
                }

                this.translationSegment.terminologyVariants = resultArray;
                this.termsLoading = false;
            },

            getTerms(word: any) {
                var terms = this.translationSegment.terminologyVariants.find((x: any) => x.key == word.toLowerCase());

                if (terms == undefined) {
                    return [];
                }

                return terms.values;
            },

            copyTerm(term: string) {
                navigator.clipboard.writeText(term);
            }
        }

    });
</script>

<style lang="scss" scoped>

    .source-text {
        padding: 30px 0;
        margin: 0 10px;
        word-break: break-word;
        color: black;
        font-size: 21px;
    }

    .term-value {
        margin: 15px 0 0 10px;
        font-weight: 600;
    }

    .term-list {
        padding-left: 15px;
    }

    .term-item:not(:first-child) {
        margin-top: 5px;
    }

    .translation-item-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .translation-item {
        margin-right: 10px;
        font-weight: 600;
    }

    .term-definition {
        word-break: break-word;
        color: #a5a1a1;
    }

    .term-loadded {
        background-color: #d7ecff;
        cursor: pointer;
        transition: background-color 0.5s ease;
    }

    .term {
        transition: background-color 0.5s ease;
        text-decoration: underline;
        text-decoration-color: red;
        text-decoration-thickness: 2px;
        cursor: pointer;
    } 

    .slide-up-enter-active,
    .slide-up-leave-active {
        transition: all 0.15s ease-out;
    }

    .slide-up-enter-from {
        opacity: 0;
        transform: translateY(10px);
    }

    .slide-up-leave-to {
        opacity: 0;
        transform: translateY(-10px);
    }


</style>