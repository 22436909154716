import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextFormatEditor = _resolveComponent("TextFormatEditor")!

  return (_openBlock(), _createBlock(_component_TextFormatEditor, {
    modelValue: _ctx.textData,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textData) = $event)),
    readOnly: true
  }, null, 8, ["modelValue"]))
}