import { createRouter, createWebHistory } from 'vue-router'

import login from './pages/LoginPage.vue';
import logout from './pages/LogoutPage.vue';

import userPage from '@/components/PageComponents/UserPage.vue'
import homePage from './pages/Home/HomePage.vue';
import document from './pages/Document/DocumentPage.vue';
import userInfoPage from './pages/User/UserInfo.vue';

import archivedDocument from './pages/Archive/ArchivedDocumentPage.vue';
import forbiddenPage from './pages/ForbiddenPage.vue';
import pageNotFound from './pages/PageNotFound.vue';


import anonymousPage from '@/components/PageComponents/AnonymousPage.vue'
import notAuthenticated from './pages/NotAuthenticated.vue';

const routes = [
    { path: '/login', component: login },
    { path: '/logout', component: logout },
    {
        path: '/', component: userPage, children: [
            {
                path: '',
                name: 'home',
                component: homePage,
            },
            {
                path: 'document/:id',
                name: 'document',
                component: document,
            },
            {
                path: 'archived-document/:id',
                name: 'archived',
                component: archivedDocument,
            },
            {
                path: 'user/userInfo',
                name: 'userInfo',
                component: userInfoPage,
            },
            {
                path: 'forbidden',
                name: 'forbidden',
                component: forbiddenPage,
            },
            {
                path: 'pageNotFound',
                name: 'pageNotFound',
                component: pageNotFound,
            },
    ]},
    {
        path: '/anonymous', component: anonymousPage, children: [
            {
                path: '',
                component: notAuthenticated
            }

    ]},
];

const router = createRouter({
    history: createWebHistory(),
    routes
});


export default router;