import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
import { SegmentStatus } from '@/models/TranslationSegment/SegmentStatus';
import { Translation } from '@/models/TranslationSegment/TranslationVariants';

import { deltaHelperService } from './deltaHelperService';

import { TEXT_MAX_LENGTH } from '@/utils/constants'

export class segmentTextLogicService {

    private static instance: segmentTextLogicService;
    private deltaHelperService: deltaHelperService

    private constructor() {
        this.deltaHelperService = new deltaHelperService();
    }

    public static getInstance(): segmentTextLogicService {
        if (!segmentTextLogicService.instance) {
            segmentTextLogicService.instance = new segmentTextLogicService();
        }
        return segmentTextLogicService.instance;
    }

    textIsTooLong(text: string): boolean {
        return text.trim().length >= TEXT_MAX_LENGTH;
    }

    textLengthIsOk(text: string): boolean {
        return text.trim().length < TEXT_MAX_LENGTH;
    }

    draftIsEmpty(translationSegment: TranslationSegment): boolean {
        return translationSegment.draftData.text.trim() === ''
    }

    translationNotSelected(translationSegment: TranslationSegment): boolean {
        return translationSegment.segmentTranslation === null;
    }

    draftNotEqalTranslation(translationSegment: TranslationSegment): boolean {
        return translationSegment.segmentTranslation?.targetSegment !== translationSegment.draftData.text;
    }

    matchPercentIs100(translationSegment: TranslationSegment): boolean {
        return translationSegment.segmentTranslation?.matchPercent === 100;
    }

    translationReady(translationSegment: TranslationSegment): boolean {
        if (translationSegment.segmentTranslation == null) { return false; }

        return translationSegment.segmentTranslation.matchPercent >= 100
            && translationSegment.draftData.text == translationSegment.segmentTranslation.targetSegment;
    }

    segmentStatusIsOkay(translationSegment: TranslationSegment): boolean {
        return translationSegment.segmentStatus == SegmentStatus.Ok || translationSegment.segmentStatus == SegmentStatus.Undefined;
    }

    perfectMatch(translationSegment: TranslationSegment): boolean {
        if (translationSegment.segmentTranslation == null) { return false; }

        return translationSegment.segmentTranslation.matchPercent >= 100;
    }

    selectedOwnTranslation(translationSegment: TranslationSegment): boolean{
        if (translationSegment.segmentTranslation == null) { return false; }

        return this.ownershipIsOwn(translationSegment.segmentTranslation);
    }

    public ownershipIsOwn(translation: Translation | undefined): boolean {
        //TODO: move to enum
        return translation?.ownership == 6;
    }

    drftTextEqalTranslationText(translationSegment: TranslationSegment): boolean {
        if (translationSegment.segmentTranslation == null) { return false; }

        return translationSegment.draftData.text == translationSegment.segmentTranslation.targetSegment;
    }

    drftStyleEqalTranslationStyle(translationSegment: TranslationSegment): boolean {
        if (translationSegment.segmentTranslation == null) { return false; }

        const delta = this.deltaHelperService.getDraftDelta(translationSegment);
        const targetDelta = this.deltaHelperService.getTargetDelta(translationSegment)
        const diff = delta.diff(targetDelta);

        const stylesAreEqual = diff.ops.length == 0;
        return stylesAreEqual;
    }

}