import { navigationService } from '@/services/navigationService'
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URI;

axios.interceptors.response.use(undefined, function (error) {
    if (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            navigationService.goLogoutPage()
        }

        if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            navigationService.goForbiddenPage()
        }

        if (error.response.status === 404 && !originalRequest._retry) {
            originalRequest._retry = true;
            navigationService.goNotFoundPage()
        }
        throw error;
    }
})
