import { ServiceStatus } from '@/models/ServiceStatus';
import { ServiceStatusType } from '@/models/ServiceStatusType';

import { userService } from '@/services/userService'

export class serviceStatusManager {

    private static instance: serviceStatusManager;
    private serviceStatus: ServiceStatus;

    userService: userService;

    constructor() {
        this.userService = new userService()
        this.serviceStatus = {
            timeStamp: new Date(),
            status: ServiceStatusType.Ok,
            percentOfQuota: 0,
        };
    }

    public static getInstance(): serviceStatusManager {
        if (!serviceStatusManager.instance) {
            serviceStatusManager.instance = new serviceStatusManager();
        }
        return serviceStatusManager.instance;
    }

    disableService() {
        this.serviceStatus.percentOfQuota = 100;
        this.serviceStatus.status = ServiceStatusType.GroupLimitReached;
        this.serviceStatus.timeStamp = new Date();
    }

    enableService() {
        this.serviceStatus.percentOfQuota = 0;
        this.serviceStatus.status = ServiceStatusType.Ok;
        this.serviceStatus.timeStamp = new Date();
    }

    geterviceStatus() {
        return this.serviceStatus.status;
    }

    async updateQuotaPercent(usedQuota: Number) {
        const externalTranslationLimit = await this.userService.getExternlaTranslationLimit();

        if (externalTranslationLimit === 0) {
            this.disableService();
            return;
        }

        const limitUsed = usedQuota as number;
        const percent = (limitUsed / externalTranslationLimit) * 100;
        this.serviceStatus.percentOfQuota = percent;
    }

    reachedPercentageLimit(limit: number) {
        return this.serviceStatus.percentOfQuota >= limit;
    }
}