<template>
    <el-col :span="6"
            id="project-code">
        <p>Project code</p>
        <el-select v-model="projectCode"
                   @change="valueChanged"
                   placeholder="**-****-****"
                   clearable
                   default-first-option
                   filterable
                   :disabled="disabled">

            <el-option v-for="(item) in projectCodes"
                       :key="item.code"
                       :label="item.projectCodeDisplay"
                       :value="item.code" />
        </el-select>
    </el-col>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { TranslationConfiguration } from '@/models/TranslationConfiguration'
    import { ConfigurationOptions } from '@/models/ConfigurationOptions'

    import { EMPTY_PROJECT_CODE } from '@/utils/constants'

    import { translationConfigService } from '../translationConfigService'

    export default defineComponent({
        props: {
            modelValue: Object,
            disabled: {
                type: Boolean,
                defaultValue: false
            },
        },
        emits: ['input', 'valueChanged'],
        data() {
            return {
                translationConfigService: new translationConfigService(),
                configurationOptions: null as ConfigurationOptions | null,
            }
        },
        mounted() {
            this.init();
        },
        computed: {
            configuration: {
                get(): TranslationConfiguration { return this.modelValue as TranslationConfiguration; },
                set(value: TranslationConfiguration) { this.$emit('input', value); }
            },
            projectCode: {
                get(): any { return this.configuration.projectCode == EMPTY_PROJECT_CODE ? null : this.configuration.projectCode; },
                set(value: any) {
                    var setVal = value
                    if (setVal == '' || setVal == undefined) {
                        setVal = EMPTY_PROJECT_CODE;
                    }
                    this.configuration.projectCode = setVal;
                }
            },
            projectCodes() : any{
                return this.configurationOptions?.projectCodes;
            },
        },
        methods: {
            async init() {
                this.configurationOptions = await this.translationConfigService.getConfigOptions()
            },
            valueChanged() {
                this.$emit('valueChanged');
            },
        }
    })
</script>
