<template>
    <div>
        <el-button type="primary"
                   @click="startTour"
                   :disabled="!tourAvailable">
            {{tourName}}
        </el-button>

        <el-tour v-model="tourStart"
                 :target-area-clickable="tourElementsClickable">
            <el-tour-step v-for="step in tourSteps"
                          :key="step.id"
                          :target="step.target"
                          :title="step.title"
                          :description="step.description" />

        </el-tour>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            tourName: {
                type: String,
                default: "Start tour",
            },
            tourSteps: {
                type: Array,
                default() {
                    return []
                }
            },
            tourAvailable: {
                type: Boolean,
                default: false,
            },
            tourElementsClickable: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                tourStart: false,
            };
        },
        methods: {
            startTour() {
                const result = document.getElementById("shortcut-button");
                if (result != null) {
                    result.click();
                }
                this.tourStart = true;
            },
        }
    })
</script>