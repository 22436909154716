import { apiClient } from '@/utils/apiClient'
import { RequestOptions } from '@/models/RequestOptions'

export class homePageService {
    requestClient: apiClient

    public static MaxDocumentsAllowed = 5

    constructor() {
        this.requestClient = new apiClient();
    }

    async uploadDocument(file: any, configuration: any) {
       return await this.requestClient.postDocument(file, configuration);
    }

    async loadDocuments(options: RequestOptions) {
        return await this.requestClient.getUserDocuments(options);
    }

    async loadArchivedDocuments(options: RequestOptions) {
        return await this.requestClient.getUserArchivedDocuments(options);
    }

    async deleteDocument(documentId: number) {
        return await this.requestClient.deleteDocument(documentId);
    }

    async deleteArchiveDocument(documentId: number) {
        return await this.requestClient.deleteArchiveDocument(documentId);
    }

    async archiveDocument(documentId: number) {
        return await this.requestClient.archiveDocument(documentId);
    }

    maxFileCountreached(filesCount: number) {
        return filesCount >= homePageService.MaxDocumentsAllowed;
    }

    async getDocumentsCount() {
        return await this.requestClient.getDocumentsCount();
    }

}
