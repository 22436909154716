<template>

    <ControlWrapper v-loading="loading"
                    :wrapper-title="title"
                    @confirm="saveMergeAction"
                    @cancel="closeMergeAction">

        <div id="merge-source">
            <div class="title">
                Source segments
            </div>

            <div v-for="(segment, index) in segmentsToMerge"
                 :key="'m'+segment.translationSegmentId">
                <SimpleSegment v-model="segment.data.sourceData"
                               :sample-segment="true">


                    <div id="remove-merge-segment"
                         v-if="showRemove(index)">
                        <el-tooltip placement="top"
                                    :content="removeTooltip"
                                    :show-after="10">

                            <el-button type="warning"
                                       @click="removeLineAction">
                                Remove
                            </el-button>
                        </el-tooltip>
                    </div>
                </SimpleSegment>
            </div>


            <el-tooltip placement="top"
                        :content="addTooltip"
                        :show-after="10">

                <div id="add-merge-segment"
                     v-if="showAdd()">

                    <el-button type="primary"
                               @click="addLineAction">
                        Add
                    </el-button>

                </div>

            </el-tooltip>

        </div>

        <div id="merge-result">
            <div class="title">
                Merge result
            </div>

            <SimpleSegment v-model="mergedSourceData">
                <div v-if="resultSegmentTooLong(mergedSourceData)">
                    <SegmentStatusIcon />
                </div>
            </SimpleSegment>

        </div>

    </ControlWrapper>

</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { mergeStore } from '@/stores/mergeStore'

    import { notificationService } from '@/services/notificationService';
    import { mergeSegmentService } from './mergeSegmentService'
    import { segmentTextLogicService } from '@/services/translationSegment/segmentTextLogicService';
    import { shortcuts } from '@/utils/shortcuts';

    import { SegmentEditorItem } from '@/models/SegmentEditorItem';

    import ControlWrapper from './ControlWrapper.vue'
    import SimpleSegment from './SimpleSegment.vue'
    import SegmentStatusIcon from '@/components/SegmentStatusIcon.vue';

    export default defineComponent({
        props: {
            modelValue: Object,
        },
        components: {
            SimpleSegment,
            ControlWrapper,
            SegmentStatusIcon,
        },
        data() {
            return {
                title: "Merge segments",
                loading: false,
                mergeSegmentService: new mergeSegmentService(),
                notificatioService: new notificationService(),
                segmentTextLogicService: segmentTextLogicService.getInstance(),
                mergeStore: mergeStore(),

                mergeResult: {},
            };
        },
        computed: {
            segmentItem: {
                get(): SegmentEditorItem { return this.modelValue as SegmentEditorItem; },
                set(value: SegmentEditorItem) { this.$emit('input', value); }
            },
            segmentsToMerge(): any {
                return this.mergeSegmentService.getSegmentsToMerge();
            },
            mergedSourceData(): any {
                return this.mergeSegmentService.getMergeSourceData();
            },
            addTooltip():string {
                return shortcuts.getTooltip(shortcuts.addSegmentToMerge);
            },
            removeTooltip(): string {
                return shortcuts.getTooltip(shortcuts.removeSegmentToMerge);
            },
        },
        created() {
            this.mergeSegmentService.initMerge(this.segmentItem.itemId);
        },
        mounted() {
            document.addEventListener("keydown", this.hotkeysListener);
        },

        beforeUnmount() {
            document.removeEventListener("keydown", this.hotkeysListener);
        },
        methods: {
            hotkeysListener(e: KeyboardEvent){
                
                if (e.key === "ArrowDown" && e.ctrlKey) {
                    this.addLineAction()
                    this.notificatioService.info("Added", "Added new line to merge.");
                    return
                }

                if (e.key === "ArrowUp" && e.ctrlKey){  
                    this.removeLineAction()
                    this.notificatioService.info("Removed", "Removed line from merge.");
                    return
                }          
            },
            showRemove(index: number) {
                return this.segmentsToMerge.length == (index + 1);
            },
            showAdd() {
                return this.mergeSegmentService.canMergeMore();
            },

            removeLineAction() {
                this.mergeSegmentService.decreaseSegments();
            },

            addLineAction() {
                this.mergeSegmentService.increaseSegments();
            },

            async saveMergeAction() {
                this.loading = true;
                await this.mergeSegmentService.mergeSegments();
            },

            closeMergeAction() {
                this.mergeSegmentService.cancelMerge();
            },

            resultSegmentTooLong(mergedSourceData: any) {
                return this.segmentTextLogicService.textIsTooLong(mergedSourceData.text);
            },

        },
    });

</script>

<style lang="scss" scoped>
    .title {
        display: flex;
        margin: 10px 5px;
    }

</style>