import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
import { ExternalTranslationResult, Position, Translation, TranslationResult, TranslationVariants } from '@/models/TranslationSegment/TranslationVariants';
import { TranslationState } from '@/models/TranslationSegment/TranslationState';

import { segmentApiService } from './segmentApiService';
import { deltaHelperService } from './deltaHelperService';

export class translationVariantsService {
    private static instance: translationVariantsService;
    private segmentApiService: segmentApiService;
    private deltaHelperService: deltaHelperService

    private constructor() {
        this.segmentApiService = segmentApiService.getInstance();
        this.deltaHelperService = new deltaHelperService();
    }

    public static getInstance(): translationVariantsService {
        if (!translationVariantsService.instance) {
            translationVariantsService.instance = new translationVariantsService();
        }
        return translationVariantsService.instance;
    }

    async setTranslationVariants(translationSegment: TranslationSegment, translationResult: TranslationResult): Promise<void> {

        translationSegment.translationVariants = {
            translations: translationResult.searchResults,
            translationDate: new Date().toISOString()
        };

        translationSegment.termPositions = translationResult.highlightPositions.sort((a: Position, b: Position) => a.begin - b.end);
        translationSegment.terminologyVariants = [];


        if (translationResult.searchResults?.length > 0) {
            const translationVatiant = translationResult.searchResults[0];
            await this.setTranslationVariant(translationSegment, translationVatiant);
        }
        else {
            await this.segmentApiService.saveSegmentState(translationSegment);
        }
    }

    async setTranslationVariant(translationSegment: TranslationSegment, translationVatiant: Translation): Promise<void> {
        translationSegment.draftData = this.deltaHelperService.mapToTextData(translationVatiant.targetSegment, translationVatiant.targetDelta);

        translationSegment.segmentTranslation = translationVatiant;
        translationSegment.translationState = translationVatiant.matchPercent == 100 ? TranslationState.Translated : TranslationState.PreTranslated;

        await this.segmentApiService.saveSegmentState(translationSegment);
    }

    async setExternalTranslationAsDraft(translationSegment: TranslationSegment, variant: ExternalTranslationResult): Promise<void> {
        translationSegment.draftData = this.deltaHelperService.mapToTextData(variant.translation);

        translationSegment.segmentTranslation = null;
        translationSegment.translationState = TranslationState.AutoTranslated;

        await this.segmentApiService.saveSegmentState(translationSegment);
    }

    removeSelectedTranslationVariant(selectedSegment: TranslationSegment): TranslationSegment {
        selectedSegment.segmentTranslation = null;
        return selectedSegment;
    }

    markSegmentsAsLoadded(segment: TranslationSegment): void {
        segment.translationVariants = {
            translationDate: new Date().toISOString(),
            translations: []
        } as TranslationVariants
    }

}