<template>
    <div class="target-with-variants">
        <div class="tareget-container">

            <el-popover :visible="focused && showVariants"
                        placement="bottom"
                        :show-arrow="false"
                        :width="1160"
                        popper-class="variants-popover">

                <div class="header-row">

                    <div class="popover-name">
                        <div>
                            Translation variants
                        </div>
                    </div>

                    <div class="time-stamp-wrapper">
                        <div class="time-stamp">
                            {{variantsTimestamp}}
                        </div>

                        <div class="close-icon">
                            <span @click="closeVariantsPopup" class="material-symbols-outlined">
                                close
                            </span>
                        </div>
                    </div>

                </div>


                <TranslationVariants v-if="showVariants"
                                     v-model="segmentItem"
                                     :configuration="configuration"
                                     :loading="segmnetLoading" />

                <template #reference>
                    <div id="tareget-container"
                         :class="['text-container', translationStateClass, focusedClass]">
                        <TargetSegmentEditor v-model="translationSegment"
                                             :setFocus="updateFocus"
                                             @focus="focusAction" />
                    </div>
                </template>

            </el-popover>

        </div>

        <div id="variants-button" class="variants-button">

            <ButtonTemplate :disabled="!translationLoadded"
                            :tooltipMessage="togleVariantsTooltip"
                            @buttonClick="doToogleVariantsPopup">
                <span class="material-symbols-outlined">
                    menu
                </span>
            </ButtonTemplate>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
     
    import { segmentsStore } from '@/stores/segmentsStore'

    import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
    import { SegmentEditorItem } from '@/models/SegmentEditorItem';

    import { shortcuts } from '@/utils/shortcuts';
    import { translationSegmentService } from '../translationSegmentService'
    import { segmentTextLogicService } from '@/services/translationSegment/segmentTextLogicService';

    import TargetSegmentEditor from './TargetSegmentEditor.vue';
    import TranslationVariants from './TranslationVariants/TranslationVariants.vue';
    import ButtonTemplate from '@/components/ButtonTemplate.vue';

    const VariantsLifetimeMinutes = 5;

    export default defineComponent({
        props: {
            modelValue: Object,
        },
        components: {
            TargetSegmentEditor,
            TranslationVariants,
            ButtonTemplate,
        },
        watch: {
            async focused(newVal) {
                if(newVal){
                    await this.goToEditField();
                } else {
                    this.closeVariantsPopup()
                }
            },
            segmnetLoading(isLoading){
                if(isLoading == false){
                    this.openVariantsPopup()
                }
            },
            togleVariantsPopup(){
                this.doToogleVariantsPopup();
            }
        },
        data() {
            return {
                segmentsStore: segmentsStore(),

                segmentTextLogicService: segmentTextLogicService.getInstance(),
                translationSegmentService: translationSegmentService.getInstance(),

                showVariants: false,

                updateFocus: false,

                variantsTimestamp: '',
            }
        },
        computed: {
            segmentItem: {
                get(): SegmentEditorItem { return this.modelValue as SegmentEditorItem; },
                set(value: SegmentEditorItem) { this.$emit('input', value); }
            },
            translationSegment: {
                get(): TranslationSegment { return this.segmentItem.data as TranslationSegment; },
                set(value: TranslationSegment) { this.segmentItem.data = value }
            },
            focused(): boolean{
                return this.segmentItem.focused;
            },
            togleVariantsPopup(): boolean{
                return this.segmentItem.variantsPopup;
            },
            segmnetLoading(): boolean{
                return this.segmentItem.loading;
            },
            configuration(): any{
                return this.segmentsStore.translationConfiguration;
            },
            translationLoadded(): boolean {
                return this.translationSegment.translationVariants != null;
            },
            togleVariantsTooltip(): string {
                return shortcuts.getTooltip(shortcuts.variantsPopover);
            },
            translationStateClass(): string {
                if (this.translationSegment.draftData.text.trim() == '') {
                    return 'empty';
                }

                if (this.translationSegment.segmentTranslation == null) {
                    return 'editing';
                }

                if (this.translationSegment.segmentTranslation.matchPercent < 100) {
                    return 'editing';
                }

                return this.segmentTextLogicService.translationReady(this.translationSegment) ?
                    'empty' : 'editing';
            },
            focusedClass(): string {
               if(!this.segmentItem.focused){
                   return 'editor-not-focused'
               }

               return ''
            },
        },
        created() {
            if(!this.segmentItem.focused)
                return;
  
            this.translateIfRequired() 
        },

        methods: {
            setTargetFocus() {
                this.updateFocus = !this.updateFocus;
                this.translationSegmentService.setFocusToSegment(this.translationSegment.translationSegmentId);
            },
            focusAction() {
                this.translationSegmentService.setFocusToSegment(this.translationSegment.translationSegmentId);
                this.translateIfRequired()
            },
            translateIfRequired(){
                 if (this.translationSegment.translationVariants)
                    return;

                this.doTranslate(); 
            },
            goToEditField() {
                this.setTargetFocus();

                if (this.variantsPopupNeeded()) {
                    setTimeout(() => {
                        this.openVariantsPopup();
                    }, 500);

                }
            },
            variantsPopupNeeded(){
                if(this.translationSegment.translationVariants == null) {
                    return false;
                }

                if (this.segmentsStore.translationConfiguration == null){
                    return false;
                }

                return this.segmentsStore.translationConfiguration.openVariantsPopup
            },
            doToogleVariantsPopup() {
                this.setFocus();
                if (this.showVariants) {
                    this.closeVariantsPopup();
                } else {
                    this.openVariantsPopup();
                }
            },
            setFocus() {
                if(this.segmentItem.focused)
                    return;

                this.segmentItem.focused = !this.segmentItem.focused;
            },

            closeVariantsPopup() {
                this.showVariants = false;
            },
            openVariantsPopup() {
                this.showVariants = true;
                this.variantsTimestamp = `loaded ${this.formatTimestamp()} ago`;

                if (this.performVariantsReload()) {
                    this.doTranslate();
                }
            },
            formatTimestamp() {
                var seconds = this.variantsLoaddedSecondsAgo()

                var interval = seconds / 31536000
                if (interval > 1) {
                    return Math.floor(interval) + " years";
                }

                interval = seconds / 2592000;
                if (interval > 1) {
                    return Math.floor(interval) + " months";
                }

                interval = seconds / 86400;
                if (interval > 1) {
                    return Math.floor(interval) + " days";
                }

                interval = seconds / 3600;
                if (interval > 1) {
                    return Math.floor(interval) + " hours";
                }

                interval = seconds / 60;
                if (interval > 1) {
                    return Math.floor(interval) + " min";
                }

                return Math.floor(seconds) + " sec";
            },

            variantsLoaddedSecondsAgo() {
                var dateStr = ""
                if (this.translationSegment.translationVariants) {
                    dateStr = this.translationSegment.translationVariants.translationDate
                }

                var timeDiff = Date.now() - new Date(dateStr).getTime();
                var secondsFloor = Math.floor((timeDiff) / 1000);
                return secondsFloor > 0 ? secondsFloor : 0;
            },

            performVariantsReload() {
                var minutes = this.variantsLoaddedSecondsAgo() / 60;
                return minutes > VariantsLifetimeMinutes;
            },

            doTranslate() {
                this.translationSegmentService.translate(this.translationSegment.translationSegmentId);
            },

        }

    });
</script>

<style lang="scss" scoped>
    .header-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .popover-name {
            display: flex;
            align-items: center;
            color: black;
        }

        .time-stamp-wrapper {
            display: flex;
        }

        .time-stamp {
            margin-right: 40px;
            color: gray;
        }

        .close-icon {
            cursor: pointer;
            opacity: 0.6;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: red;
            margin-bottom: 12px;
        }
    }


     .editor-not-focused > * {
        & :deep().ql-editor {
            cursor: pointer;
        }

        & :deep().ql-editor > * {
            cursor: pointer;
        }
    }

    .text-container {
        border: solid 1px #cdcccc;
        width: 500px;
    }

    .empty {
        background-color: #ffffff;
    }

    .editing {
        background-color: #F9EED1;
    }

    .target-with-variants {
        display: flex;
        flex-direction: row;

        .variants-button {
            margin: 12px 8px 12px 12px;
        }
    }
</style>