<template>
    <div></div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    import { navigationService } from '@/services/navigationService'
    import { userSettingsService } from '@/services/userSettingsService'
    import { translationConfigService } from '@/components/TranslationConfig/translationConfigService'
    import { userService } from '@/services/userService'

    export default defineComponent({
        data() {
            return {
                userService: new userService(),
            };
        },
        created() {
            this.logoutActions();
        },
        methods: {
            async logoutActions() {
                await this.userService.userLogout();
                translationConfigService.clearCache();
                userSettingsService.clearCache();
                navigationService.goAnonymousPage()
            }
        },
        computed:{
            acceessTocken(){
                return this.$route.query['accessToken'];
            }
        }
    });
</script>