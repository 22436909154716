export interface DocumentRow {
    id: number
    name: string
    storedName: string
    templateName: string
    mergedName: string
    documentType: DocumentType
    createdAt: Date
    updatedAt: Date
    createdBy: string
    updatetBy: string
    pageConfig: string
    status: DocumentStatus, 
    translationConfig: string
    totalSegments: number
    translatedSegments: number
    draftSegments: number
    preTranslatedSegments: number
}

export enum DocumentType {
    Docx = 0
}

export enum DocumentStatus{
    Active = 0,
    Archived = 1,
    Uploaded = 2,
    ParsingError = 3,
}