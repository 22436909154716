<template>
    <el-popover placement="right" :width="150" trigger="click">

        <template #reference>
            <div class="img-button-container">
                <span class="material-symbols-outlined">
                    more_horiz
                </span>
            </div>
        </template>

        <div class="action-wrapper">

            <el-button v-if="documentIsActive"
                       @click="archiveAction"
                       type="warning"
                       size="small">
                Archive
            </el-button>

            <el-button @click="deleteAction"
                       type="danger"
                       size="small">
                Delete
            </el-button>

        </div>

    </el-popover>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { DocumentRow, DocumentStatus } from '@/models/DocumentRow'

    export default defineComponent({
        props: {
            rowData: Object as PropType<DocumentRow>,
        },
        computed: {
            documentIsActive(): boolean {
                if(this.rowData == null) return false;
                return this.rowData.status === DocumentStatus.Active;
            }, 
        },
        methods: {
            archiveAction() {
                this.$emit('archiveAction', this.rowData?.id);
            },
            deleteAction() {
                this.$emit('deleteAction', this.rowData?.id);
            },
        }
    })

</script>

<style lang="scss" scoped>
    .action-wrapper {
        display: flex;
        flex-direction: column;

        & > button {
            margin: 5px 0;
        }
    }
</style>