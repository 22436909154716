<template>

    <NewFileTabTour :tour-name="tourName"
                    :tour-steps="quickStartSteps"
                    :tour-available="tourAvailable"
                    />

</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import { userGuideService } from '../userGuideService'

    import NewFileTabTour from './BaseTours/NewFileTabTour.vue'

    export default defineComponent({
        props: {
            tourAvailable: {
                type: Boolean,
                default: false,
            }
        },
        components: {
            NewFileTabTour,
        },
        data() {
            return {
                tourName: "Quick start",
                quickStartSteps: userGuideService.getQuickStartGuide(),
            };
        },
    })
</script>