<template>

    <ControlWrapper v-loading="loading"
                    :wrapper-title="title"
                    @confirm="saveAction"
                    @cancel="closeAction">
        <div id="split-source">
            <div class="title">
                Source segment
            </div>

            <SimpleSegment v-model="segmentItem.data.sourceData"
                           :sample-segment="true" />

        </div>

        <div id="split-result">
            <div class="title">
                Result segments
            </div>

            <div v-loading="segmentsLoading">
                <div v-for="segment in mergedSegments"
                     :key="segment.translationSegmentId">
                    <SimpleSegment v-model="segment.sourceData" />
                </div>
            </div>
        </div>

    </ControlWrapper>

</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { SegmentEditorItem } from '@/models/SegmentEditorItem';

    import { SplitService } from './splitService';

    import ControlWrapper from './ControlWrapper.vue'
    import SimpleSegment from './SimpleSegment.vue'


    export default defineComponent({
        props: {
            modelValue: Object,
        },
        components: {
            ControlWrapper,
            SimpleSegment,
        },
        data() {
            return {
                title: "Split segment",
                loading: false,
                splitService: new SplitService(),
                mergedSegments: [],
                segmentsLoading: false,
            };
        },
        computed: {
            segmentItem: {
                get(): SegmentEditorItem { return this.modelValue as SegmentEditorItem; },
                set(value: SegmentEditorItem) { this.$emit('input', value); }
            },
        },
        created() {
            this.init();
        },
        methods: {
            async init() {
                this.splitService.initSplit(this.segmentItem.itemId);
                await this.loadMergedSegments();
            },

            async loadMergedSegments(){
                this.segmentsLoading = true;
                this.mergedSegments = await this.splitService.getSegmentsWereMerged();
                this.segmentsLoading = false;
            },

            async saveAction() {
                await this.splitService.splitMrgedSegments();
            },

            closeAction() {
                this.splitService.cancelSplit();
            },

        },
    });

</script>

<style lang="scss" scoped>
    .title {
        display: flex;
        margin: 10px 5px;
    }
</style>