<template>
    <div class="percentage-tag">
        <el-tag v-if="translated"
                :type="type"
                effect="plain"
                round>
            {{ prercentage }}
        </el-tag>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';

    export default defineComponent({
        props: {
            modelValue: Object as PropType<TranslationSegment>,
        },
        computed: {
            translationSegment(): TranslationSegment {
                return this.modelValue as TranslationSegment;
            },
            translated(): boolean {
                return this.translationSegment.translationVariants != null;
            },
            matchValue(): number {
                return this.translationSegment.segmentTranslation != null ?
                    this.translationSegment.segmentTranslation.matchPercent : 0;
            },
            type(): string {
                switch (true) {
                    case this.matchValue == 100:
                        return 'success';       // green
                    case this.matchValue > 0:
                        return 'primary';       // blue
                    default:
                        return 'info';          // grey
                }
            },
            prercentage(): string {
                return `${this.matchValue}%`;
            }
        },
    });
</script>

<style lang="scss" scoped>
    .percentage-tag {
        width: 50px;
        display: flex;
        justify-content: space-around;
    }
</style>
