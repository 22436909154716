<template>
    <el-col :span="6"
            id="fuzzy-match">

        <p>Fuzzy Match</p>
        <el-input-number v-model="configuration.minMatchPercent"
                         @change="valueChanged"
                         :min="50" :max="100"
                         :disabled="disabled" />


    </el-col>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { TranslationConfiguration } from '@/models/TranslationConfiguration'

    export default defineComponent({
        props: {
            modelValue: Object,
            disabled: {
                type: Boolean,
                defaultValue: false
            },
        },
        emits: ['input', 'valueChanged'],
        computed: {
            configuration: {
                get(): TranslationConfiguration { return this.modelValue as TranslationConfiguration; },
                set(value: TranslationConfiguration) { this.$emit('input', value); }
            },
        },
        methods: {
            valueChanged() {
                this.$emit('valueChanged');
            },
        }
    })
</script>
