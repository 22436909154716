<template>

    <div>
        <div class="table-name">
            External translation
        </div>

        <div class="columns">
            <div>Provider</div>
            <div>Translation</div>
        </div>

        <div class="result-table">

            <div v-for="(item, index) in externalTranslationVariants"
                 :key="'ext' + index">

                <div class="variant-container"
                     @click="setDraftValue(item)">
                    <div>
                        {{providerName(item)}}
                    </div>
                    <div>
                        {{item.translation}}
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
    import { ExternalTranslationResult } from '@/models/TranslationSegment/TranslationVariants';

    import { segmentModificationService } from '@/services/translationSegment/segmentModificationService';
    import { externalProvidersStore } from '@/stores/externalProvidersStore';

    export default defineComponent({
        props: {
            translationSegment: Object as PropType<TranslationSegment>,
        },
        data() {
            return {
                segmentModificationService: segmentModificationService.getInstance(),
                externalProvidersStore: externalProvidersStore(),
            }
        },

        computed: {
            externalTranslationVariants(): any {
                if (this.translationSegment?.externalTranslationVariants == undefined || this.translationSegment.externalTranslationVariants == null) {
                    return [];
                }
                return this.translationSegment.externalTranslationVariants.translations;
            }, 

        },

        methods: {
            providerName(variant: ExternalTranslationResult) {
                return this.externalProvidersStore.getProviderName(variant.provider);
            },
            async setDraftValue(variant: ExternalTranslationResult) {
                await this.segmentModificationService.setExternalTranslationAsDraft(this.translationSegment as TranslationSegment, variant);
            },             

        }

    })
</script>

<style lang="scss" scoped>
    .table-name {
        display: flex;
        align-items: center;
        color: black;
        font-size: 19px;
    }

    .columns {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 16px;
    }

    .result-table {
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1) inset;
        border-style: solid;
        border-width: 2px;
        border-color: #cfcfcf;
    }

    .variant-container {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        cursor: pointer;

        & > div {
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>