
import { notificationService } from '@/services/notificationService'

import { segmentModificationService } from '@/services/translationSegment/segmentModificationService';
import { segmentApiService } from '@/services/translationSegment/segmentApiService';

import { ServiceStatusType } from '@/models/ServiceStatusType';
import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
import { TranslationConfiguration } from '@/models/TranslationConfiguration';
import { ExternalTranslationResponse, ExternalTranslationStatus } from '@/models/ExternalTranslationResponse'

import { externalProvidersStore } from '@/stores/externalProvidersStore';

import { serviceStatusManager } from './serviceStatusManager';

export class externalTranslationService {
    private static instance: externalTranslationService;

    notificationService: notificationService;
    segmentModificationService: segmentModificationService;
    segmentApiService: segmentApiService;
    statusManager: serviceStatusManager;

    externalProvidersStore: any;

    private constructor() {
        this.statusManager = new serviceStatusManager();
        this.notificationService = new notificationService();

        this.segmentModificationService = segmentModificationService.getInstance();
        this.segmentApiService = segmentApiService.getInstance();

        this.externalProvidersStore = externalProvidersStore();
    }

    public static getInstance(): externalTranslationService {
        if (!externalTranslationService.instance) {
            externalTranslationService.instance = new externalTranslationService();
        }
        return externalTranslationService.instance;
    }

    async translateExternallyAction(segment: TranslationSegment, configuration: TranslationConfiguration): Promise<boolean> {
        const externalTranslationResponse = await this.performExternalTranslation(segment, configuration);

        if (!externalTranslationResponse) {
            return false;
        }

        return this.handleTranslationResponse(segment, externalTranslationResponse, configuration);
    }

    private async performExternalTranslation(segment: TranslationSegment, configuration: TranslationConfiguration): Promise<ExternalTranslationResponse | null> {
        try {
            const response = await this.segmentApiService.translateExternallySegment(segment, configuration);
            return response as ExternalTranslationResponse;
        } catch (error) {
            this.notificationService.warning("API error", "Try again later, or contact WWS");
            return null;
        }
    }

    private async handleTranslationResponse(segment: TranslationSegment, externalTranslationResponse: ExternalTranslationResponse, configuration: TranslationConfiguration): Promise<boolean> {
        const translationProvider = configuration.externalTranslationProviders[0];

        switch (externalTranslationResponse.externalTranslationStatus) {
            case ExternalTranslationStatus.Translated:
                return await this.handleTranslated(externalTranslationResponse, segment);

            case ExternalTranslationStatus.UserLimitReached:
                return this.handleUserLimitReached();

            case ExternalTranslationStatus.ServiceLimit:
                this.handleServiceLimit(translationProvider);
                return false;

            case ExternalTranslationStatus.TooManyRequest:
                this.handleTooManyRequests(translationProvider);
                return false;

            case ExternalTranslationStatus.ConfigurationError:
                this.notificationService.warning("Language pair not supported", "Current translation provider does not support this language pair.");
                return false;

            case ExternalTranslationStatus.TranslationError:
                this.notificationService.warning("API error", "Try again later, or contact WWS");
                return false;

            default:
                return false;
        }
    }

    private async handleTranslated(externalTranslationResponse: ExternalTranslationResponse, segment: TranslationSegment): Promise<boolean> {
        this.segmentModificationService.mapTranslationSegment(segment, externalTranslationResponse.translationSegment);
        await this.statusManager.updateQuotaPercent(externalTranslationResponse.groupCharactersCount);
        return true;
    }

    private handleUserLimitReached(): boolean {
        this.statusManager.disableService();
        this.notifyLimitReached();
        return false;
    }

    private handleServiceLimit(translationProvider: any): void {
        this.notificationService.warning("Translation error", "Translation service is not available");
        this.externalProvidersStore.disableProviderForDay(translationProvider);
    }

    private handleTooManyRequests(translationProvider: any): void {
        this.notificationService.warning("Translation error", "Too many requests. Try again later");
        this.externalProvidersStore.disableProviderForThreeMinutes(translationProvider);
    }

    private notifyLimitReached(): void {
        const title = 'Limit reached';
        const messageHtml = this.getNotificationHtml();
        this.notificationService.warningPermanentHtml(title, messageHtml);
    }

    //Comments until we have new production link

    //getNotificationHtml() {
    //    const message = 'You have now reached the limit of your available group scope for today on your account. ' +
    //        'To avoid limitations and ensure you can continue to use the feature, we recommend upgrading your licence. ' +
    //        'With an upgrade you can increase your scope and continue to use the feature.';

    //    const linkText = 'Upgrade your licence now to continue!';

    //    const link = process.env.VUE_APP_SOULUTIONS_AND_PRICES;

    //    const messageHtml = `<p>${message}</p> </br> ` +
    //        `<a href="${link}" target="_blank" rel="noopener noreferrer" style="text-decoration: none;">${linkText}</a>`

    //    return messageHtml;
    //}

    getNotificationHtml() {
        const message = 'You have now reached the limit of your available group scope for today on your account. ' +
            'To avoid limitations and ensure you can continue to use the feature, we recommend upgrading your licence. ' +
            'With an upgrade you can increase your scope and continue to use the feature.';

        const contact = 'Contact WWS.';

        const messageHtml = `<p>${message}</p> </br> ` +
            `<b>${contact}</b></a>`

        return messageHtml;
    }

    reachedSeventyFivePercent() {
        return this.statusManager.reachedPercentageLimit(75);
    }

    reachedNinetyPercent() {
        return this.statusManager.reachedPercentageLimit(90);
    }

    reachedHundredPercent() {
        return this.statusManager.reachedPercentageLimit(100);
    }

    isServiceStatusOk() {
        const status = this.statusManager.geterviceStatus();
        return status == ServiceStatusType.Ok;
    }
}

