<template>
    <div>
        <el-button type="primary"
                   @click="startTour"
                   :disabled="!tourAvailable">
            {{tourName}}
        </el-button>

        <el-tour v-model="tourStart"
                 :target-area-clickable="false">

            <el-tour-step :target="target1"
                          :title="title1"
                          :description="description1" />

            <el-tour-step :target="target2"
                          :title="title2"
                          :description="description2" />

            <el-tour-step :target="target3"
                          :title="title3"
                          :description="description3" />

            <el-tour-step :target="target4"
                          :title="title4">
                {{description4}}

                <div class="icon-wrapper">
                    <SegmentStatusIcon />
                </div>

            </el-tour-step>
        </el-tour>
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    import { shortcuts } from '@/utils/shortcuts';
    import SegmentStatusIcon from '@/components/SegmentStatusIcon.vue';

    export default defineComponent({
        props: {
            tourAvailable: {
                type: Boolean,
                default: false,
            }
        },
        components: {
            SegmentStatusIcon,
        },
        data() {
            return {
                tourName: "Add translation",
                tourStart: false,

                target1: "#source-container",
                title1: "Source text",
                description1: "Check the source text that needs to be translated.",

                target2: "#tareget-container",  
                title2: "Target text",
                description2: "Fill input with corresponding translation. The translation input colour is changed - it means that this version of translation is not saved to translation memory yet. Also, you can see #draft tag and not saved translation icon.",

                target3: "#save-button",
                title3: "Save translation",
                description3: `Click 'Save translation' button (or use hotkey ${shortcuts.addTranslation.win}). It will be saved in translation memory and retrieved during the next translation matches (Alternatively, use hotkey ${shortcuts.saveMoveNext.win} to save and go to next segment).`,
                        
                target4: "#segment-container",
                title4: "Limits",
                description4: "Translation memory has limit for 500 characters. Users can't save larger segments. When the limit for the segment is reached, you will see the icon as shown below. You can still fill the translation and it will be reflected in the result document but not saved in the memory.",
            };
        },
        methods: {
            startTour() {
                const result = document.getElementById("shortcut-button");
                if (result != null) {
                    result.click();
                }
                this.tourStart = true;
            },
        }
    })
</script>

<style scoped>

    .icon-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

</style>