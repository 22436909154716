<template>
    <div>
        <el-card>

            <el-collapse v-if="configuration"
                         v-model="collapseModel"
                         @change="handleChange">
                <el-collapse-item id="configuration-colapse"
                                  name="1">

                    <template #title>
                        <div class="img-container">
                            <span class="material-symbols-outlined ">settings</span>
                        </div>
                        <span class="config-value-combined">{{configTittle}}</span>
                       
                    </template>

                    <el-row :gutter="24">
                        <LanguagesSetup v-model="configuration"
                                        :disabled="viewMode"
                                        @valueChanged="debouncedHandler" />

                        <FuzzyMatch v-model="configuration"
                                    :disabled="viewMode"
                                    @valueChanged="debouncedHandler" />

                        <VariantsPopup v-model="configuration"
                                      :disabled="viewMode"
                                      @valueChanged="debouncedHandler" />
                    </el-row>

                    <el-row :gutter="24">
                        <SubjectAndSubsubject v-model="configuration"
                                              :disabled="viewMode"
                                              @valueChanged="debouncedHandler" />

                        <ProjectCode v-model="configuration"
                                    :disabled="viewMode"
                                    @valueChanged="debouncedHandler" />

                        <DatabaseType v-model="configuration"
                                      :disabled="viewMode"
                                      @valueChanged="debouncedHandler" />
                    </el-row>

                    <el-divider/>

                    <el-row :gutter="24">
                        <ExternalProviders v-model="configuration"
                                           :disabled="viewMode"
                                           @valueChanged="debouncedHandler" />
                    </el-row>

                    <slot>
                    </slot>

                </el-collapse-item>
            </el-collapse>

        </el-card>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { ConfigurationOptions, Poroject, Language, IdNamePair } from '@/models/ConfigurationOptions'
    import { TranslationConfiguration } from '@/models/TranslationConfiguration'

    import { translationConfigService } from './translationConfigService'

    import debounce from "lodash.debounce";

    import LanguagesSetup from './Components/LanguagesSetup.vue'
    import FuzzyMatch from './Components/FuzzyMatch.vue'
    import VariantsPopup from './Components/VariantsPopup.vue'

    import SubjectAndSubsubject from './Components/SubjectAndSubsubject.vue'
    import ProjectCode from './Components/ProjectCode.vue'
    import DatabaseType from './Components/DatabaseType.vue'

    import ExternalProviders from './Components/ExternalProviders.vue'

    export default defineComponent({
        props: {
            modelValue: Object,
            viewMode: {
                type: Boolean,
                defaultValue: false
            },
            pageNamePrefix: {
                type: String,
                required: true
            },
            expended: {
                type: Boolean,
                defaultValue: false
            }
        },
        components: {
            LanguagesSetup,
            FuzzyMatch,
            VariantsPopup,

            SubjectAndSubsubject,
            ProjectCode,
            DatabaseType,

            ExternalProviders
        },
        data() {
            return {
                debouncedHandler: null as any,

                translationConfigService: new translationConfigService(),

                configurationOptions: null as ConfigurationOptions | null,
                collapsedItems: [] as string[],
                collapseModel: [] as string[],
            };
        },
        created() {
            this.debouncedHandler = debounce(() => {
                this.$emit("configChanged");
            }, 1500);

            if (this.expended) {
                this.collapseModel.push('1');
            }
        },
        beforeUnmount() {
            this.debouncedHandler.cancel();
        },
        mounted(){
            this.init();
        },
        computed: {
            configuration: {
                get(): TranslationConfiguration { return this.modelValue as TranslationConfiguration; },
                set(value: TranslationConfiguration) { this.$emit('input', value); }
            },
            configTittle(): string{
                if (this.collapsedItems.includes('1') || (this.configValuesLoadded == false) ){
                     return `${this.pageNamePrefix} translation settings`;
                }

                return `${this.pageNamePrefix} translation settings: ${this.sourceLanguage} - ${this.targetLanguage}, ${this.subject},${this.projectCodeString} ${this.configuration.minMatchPercent}%`;
            },
            configValuesLoadded(): boolean {
                return this.configurationOptions != null;
            },
            sourceLanguage(): any{
                let lang = this.configurationOptions?.languages.find((l: Language) => l.id == this.configuration.sourceLanguageId );
                return lang?.name;
            },
            targetLanguage(): any{
                let lang = this.configurationOptions?.languages.find((l: Language) => l.id == this.configuration.targetLanguageId );
                return lang?.name;
            },
            subject(): any{
                let subj = this.configurationOptions?.subjects.find((l: IdNamePair) => l.id == this.configuration.subjectId );
                return subj?.name;
            }, 
            projectCodeString(): string{
                let project = this.configurationOptions?.projectCodes.find((l: Poroject) => l.code == this.configuration.projectCode );

                if(project == undefined){
                    return ""
                }

                if(project.id != -1){
                    return ` ${project.display},`;
                }

                return "";
            },
        },
        methods: {
            async init() {
                this.configurationOptions = await this.translationConfigService.getConfigOptions()
            },
            configChanged(){
                this.$emit("configChanged");
            },
            handleChange(val: string[]){
                this.collapsedItems = val;
            },
        },
    });
</script>

<style lang="scss" scoped>
    .config-value-combined {
        font-size: 19px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
</style>