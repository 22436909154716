import { segmentsStore } from '@/stores/segmentsStore';

import { SegmentEditorItem } from '@/models/SegmentEditorItem';
import { SegmentEditorState } from '@/models/SegmentEditorState';
import { MergeState } from "@/models/MergeState";
import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';

import { segmentModificationService } from '@/services/translationSegment/segmentModificationService';
import { segmentTextLogicService } from '@/services/translationSegment/segmentTextLogicService';

export class translationSegmentService {
    private static instance: translationSegmentService;

    segmentsStore: any
    segmentModificationService: segmentModificationService;
    segmentTextLogicService: segmentTextLogicService;

    private constructor() {
        this.segmentsStore = segmentsStore();
        this.segmentModificationService = segmentModificationService.getInstance();
        this.segmentTextLogicService = segmentTextLogicService.getInstance();
    }

    public static getInstance(): translationSegmentService {
        if (!translationSegmentService.instance) {
            translationSegmentService.instance = new translationSegmentService();
        }
        return translationSegmentService.instance;
    }

    setFocusToSegment(segmentId: number) {
        this.segmentsStore.setCurrentSegmentId(segmentId);
    }

    mergeIsPossible(segmentItem: SegmentEditorItem) {
        return segmentItem.mergePossible &&
            this.segmentsStore.segmentEditorState == SegmentEditorState.Translate;
    }

    splitIsPossible(segmentItem: SegmentEditorItem) {
        return segmentItem.data.mergeState == MergeState.MergeResult &&
            this.segmentsStore.segmentEditorState == SegmentEditorState.Translate;
    }

    async copy(segmentId: number) {
        this.segmentsStore.setCurrentSegmentId(segmentId);
        await this.copyCurrentSegment();
    }

    async copyCurrentSegment() {
        const segmentItem = this.segmentsStore.getCurrentSegmentEditorItem();
        await this.segmentModificationService.copyAction(segmentItem.data);
    }

    async translate(segmentId: number) {
        this.segmentsStore.setCurrentSegmentId(segmentId);
        await this.translateCurrentSegment();
    }

    async translateCurrentSegment() {
        const segmentItem = this.segmentsStore.getCurrentSegmentEditorItem() as SegmentEditorItem;

        if (this.segmentsStore.translationConfiguration == null) {
            throw new Error("Translation configuration is not set");
        }

        segmentItem.loading = true;
        const translated = await this.segmentModificationService.translateAction(segmentItem.data, this.segmentsStore.translationConfiguration);

        segmentItem.loading = false;
    }

    toogleCurrentSegmentVariantsPopup() {
        const segmentItem = this.segmentsStore.getCurrentSegmentEditorItem() as SegmentEditorItem;

        segmentItem.variantsPopup = !segmentItem.variantsPopup;
    }

    async addTranslation(segmentId: number) {
        this.segmentsStore.setCurrentSegmentId(segmentId);
        await this.addCurrentSegmentTranslation();
    }

    async addCurrentSegmentTranslation() {
        const segmentItem = this.segmentsStore.getCurrentSegmentEditorItem();

        if (this.segmentsStore.translationConfiguration == null) {
            throw new Error("Translation configuration is not set");
        }

        segmentItem.loading = true;
        await this.segmentModificationService.addTranslationAction(segmentItem.data, this.segmentsStore.translationConfiguration);
        segmentItem.loading = false;
    }

    currentTargetIsEmpty() {
        const segmentItem = this.segmentsStore.getCurrentSegmentEditorItem() as SegmentEditorItem;
        return this.targetIsEmpty(segmentItem.data);
    }

    targetIsEmpty(segment: TranslationSegment) {
        const segmentText = segment.draftData.text.trim()
        return segmentText == '';
    }

    translationCouldBeAdded() {
        const segmentItem = this.segmentsStore.getCurrentSegmentEditorItem() as SegmentEditorItem;
        const translationSegment = segmentItem.data;

        const couldBeAdded = !this.targetIsEmpty(translationSegment) &&
            !this.segmentTextLogicService.translationReady(translationSegment) &&
            this.segmentTextLogicService.segmentStatusIsOkay(translationSegment) ;

        return couldBeAdded;
    }

    translationCouldBeUpdated() {
        const segmentItem = this.segmentsStore.getCurrentSegmentEditorItem() as SegmentEditorItem;
        const translationSegment = segmentItem.data;

        const couldBeUpdated =
            this.segmentTextLogicService.segmentStatusIsOkay(translationSegment) && 
            this.segmentTextLogicService.perfectMatch(translationSegment) &&
            this.segmentTextLogicService.selectedOwnTranslation(translationSegment) &&
            (
                !this.segmentTextLogicService.drftTextEqalTranslationText(translationSegment) ||
                !this.segmentTextLogicService.drftStyleEqalTranslationStyle(translationSegment)
            )

        return couldBeUpdated;
    }

    async updateTranslation(segmentId: number) {
        this.segmentsStore.setCurrentSegmentId(segmentId);
        await this.updateCurrentSegmentTranslation();
    }

    async updateCurrentSegmentTranslation() {
        if (this.segmentsStore.translationConfiguration == null) {
            throw new Error("Translation configuration is not set");
        }

        const segmentItem = this.segmentsStore.getCurrentSegmentEditorItem();
        segmentItem.loading = true;
        await this.segmentModificationService.updateTranslationAction(segmentItem.data, this.segmentsStore.translationConfiguration);
        segmentItem.loading = false;
    }

    async cleanTranslationSegment(segmentId: number) {
        this.segmentsStore.setCurrentSegmentId(segmentId);
        await this.cleanCurrentSegmentTranslationSegment();
    }

    async cleanCurrentSegmentTranslationSegment() {
        const segmentItem = this.segmentsStore.getCurrentSegmentEditorItem();

        await this.segmentModificationService.clearTranslationAction(segmentItem.data);
    }

    async removeStylesInCurrentSrgment() {
        const segmentItem = this.segmentsStore.getCurrentSegmentEditorItem();
        await this.segmentModificationService.clearSegmnetStylesAction(segmentItem.data);
    }

}