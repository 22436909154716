<template>
    <div>

        <el-button type="primary"
                   @click="startTour"
                   :disabled="!tourAvailable">
            {{tourName}}
        </el-button>

        <el-dialog v-model="mergeTourDialog"
                   :title="title"
                   width="400">

            <div class="dialod-wrapper">

                {{description}}
                <div class="img-button-container merge-button"
                     @click="continueMergeTour">
                    <span class="material-symbols-outlined">
                        expand
                    </span>
                </div>
                {{hotkey}}

                <el-button type="primary"
                           class="merge-button"
                           @click="continueMergeTour">
                    Next
                </el-button>
            </div>


        </el-dialog>

        <el-tour v-model="tourStart"
                 :target-area-clickable="false"
                 :scroll-into-view-options="{ block: 'center' }">
            <el-tour-step v-for="step in tourSteps"
                          :key="step.id"
                          :target="step.target"
                          :title="step.title"
                          :description="step.description" />

        </el-tour>

    </div>
</template>

<script lang="ts">
    import { defineComponent, nextTick } from 'vue';
    import { ElMessage } from 'element-plus'

    import { segmentsStore } from '@/stores/segmentsStore';
    import { userGuideService } from '../userGuideService'

    import { shortcuts } from "@/utils/shortcuts";

    const SplitButtonId = "split-button"

    export default defineComponent({
        props: {
            tourAvailable: {
                type: Boolean,
                default: false,
            }
        },
        computed:{
            mergeOrSplitIsActive(): boolean{
                return this.segmentsStore.isMergeActive || this.segmentsStore.isSplitActive;
            },

        },
        data() {
            return {
                segmentsStore: segmentsStore(),

                tourName: "Split segment",
                tourNotPossibleMessage: "You should have at least one merged segment.",
            
                mergeTourDialog: false,
                
                title: "Split merge",
                description: `Select merged segment that you want to split. Click the Split merge button below the segment to start.`,
                hotkey: `(or use hotkey ${shortcuts.split.win}).`,

                mergeOrSplitIsActiveMessage: "Merge or Split state is active. You can't start tour now. Please, finish or cancel it first.",

                tourStart: false,
                tourSteps: userGuideService.getSplitSegments(),
            };
        },
        methods: {
            startTour() {
                
                if(this.mergeOrSplitIsActive){
                    this.showMergeIsActiveMessage();
                    return;
                }

                if(this.mergedSegmentExist() == false){
                    this.showSplitTourNotPossible();
                    return;
                }

                this.mergeTourDialog = true;
            },            
            mergedSegmentExist(){
                const result = document.getElementById(SplitButtonId);
                return result != null;
            },
            async continueMergeTour(){

                this.mergeTourDialog = false;

                this.clickSplitButton();
                await nextTick();
                this.tourStart = true;                   
            },
            clickSplitButton(){
                const result = document.getElementById(SplitButtonId);
                if (result != null) {
                    result.click();
                }                
            },
            showSplitTourNotPossible() {
                ElMessage({
                    showClose: true,
                    message: this.tourNotPossibleMessage,
                    type: 'warning',
                })
            },
            showMergeIsActiveMessage() {
                ElMessage({
                    showClose: true,
                    message: this.mergeOrSplitIsActiveMessage,
                    type: 'warning',
                })
            },
        }
    })
</script>

<style scoped>
    .dialod-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .merge-button {
        margin-top: 10px;
    }
</style>