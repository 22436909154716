<template>
    <div v-loading="loading">
        <StorageStatusInfo :documnetsCount="documentCount" />

        <div>
            <div v-if="!uploadDisabled" class="tittle">
                Select the file you want to translate. You can upload only .docx files.
            </div>

            <div class="file-loader">

                <label>
                    File
                    <input type="file"
                           id="file-upload"
                           ref="file"
                           accept=".docx"
                           @change="handleFileUpload()"
                           :disabled="uploadDisabled"
                           placeholder="File" />
                </label>
            </div>

            <TranslationConfig v-model="configuration"
                               @configChanged="saveConfig"
                               pageNamePrefix="Default user"
                               :expended="true" />



            <FileUploadPrompt :submitDisabled="submitDisabled"
                              @submitted="submitFile"
                              />

        </div>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { navigationService } from '@/services/navigationService'
    import { homePageService } from '@/pages/Home/homePageService'
    import { userSettingsService } from '@/services/userSettingsService'

    import TranslationConfig from '@/components/TranslationConfig/TranslationConfig.vue'
    import StorageStatusInfo from './storageStatusInfo.vue'

    import FileUploadPrompt from './FileUploadPrompt.vue'

    export default defineComponent({
        components: {
            TranslationConfig,
            StorageStatusInfo,
            FileUploadPrompt,
        },
        data() {
            return {

                loading: false,
                homePageService: new homePageService(),
                userSettingsService: new userSettingsService(),
                file: '',

                documentCount: 0,

                configuration: {
                    sourceLanguageId: null,
                    targetLanguageId: null,
                    subjectId: null,
                    subSubjectId: null,
                    projectCode: null,
                    minMatchPercent: null,
                    databaseType: null
                } as any,

            };
        },
        mounted() {
            this.init();
        },
        computed: {
            uploadDisabled(): boolean {
                return this.homePageService.maxFileCountreached(this.documentCount);
            },
            submitDisabled(): boolean {
                return this.file == '';
            },
            defaultConfig(): any {
                return {
                    sourceLanguageId: 2,
                    targetLanguageId: 1,
                    subjectId: 4,
                    subSubjectId: -1,
                    projectCode: "0000000000",
                    minMatchPercent: 50,
                    databaseType: 1
                };
            },
        },
        methods: {
            async init() {
                this.loading = true;
                this.documentCount = await this.homePageService.getDocumentsCount();
                this.configuration = await this.getConfig();

                this.loading = false;
            },

            handleFileUpload() {
                this.file = (this.$refs.file as any).files[0];
            },
            async submitFile() {
                try {
                    this.loading = true;
                    var uploadedDocumentId = await this.homePageService.uploadDocument(this.file, this.configuration);
                    this.loading = false;

                    if (uploadedDocumentId) {

                        if (uploadedDocumentId == -1) {
                            this.$emit('upload-error');
                        } else {
                            navigationService.goDocumentPage(uploadedDocumentId);
                        }

                    } else {
                        //todo show message smth went wrong with upload
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            async getConfig() {

                let config = await this.userSettingsService.getUserTranslationConfig();

                if (config == null || config == '') {
                    return this.defaultConfig;
                }
                return config;
            },

            async saveConfig() {
                await this.userSettingsService.saveTranslationConfig(this.configuration);
            },

        }
    })
</script>

<style lang="scss" scoped>
    .tittle {
        display: flex;
        justify-content: space-around;
        margin: 30px;
        font-size: 26px;
    }

    .file-loader {
        margin: 60px;
    }
</style>