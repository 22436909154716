import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UnauthorisedHeader = _resolveComponent("UnauthorisedHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BasePage = _resolveComponent("BasePage")!

  return (_openBlock(), _createBlock(_component_BasePage, null, {
    default: _withCtx(() => [
      _createVNode(_component_UnauthorisedHeader),
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}