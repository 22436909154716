<template>
    <div class="status-container">
        <el-progress :text-inside="true"
                     :stroke-width="40"
                     :percentage="progress"
                     :status="progressBarStatus">
            <span class="message">{{filesUploadedMessage}}</span>
        </el-progress>

        <el-alert :type="getPopupType"
                  :closable="false"
                  center 
                  show-icon >
            <template #default>
                <span class="message">{{popupMessage}}</span>
            </template>
            </el-alert>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { homePageService } from '@/pages/Home/homePageService'

    export default defineComponent({
        props: {
            documnetsCount: Number,
        },
        data() {
            return {
                maxDocumentsAllowed: homePageService.MaxDocumentsAllowed,
                docCount: 0,
            };
        },
        watch: {
            documnetsCount: function (val: number) {
                this.docCount = val;
            }
        },
        computed: {
            popupMessage(): string {
                switch (true) {
                    case this.error:
                        return 'You have reached max files count. Remove files that you are no longer using.';
                    case this.warning:
                        return 'You will reach max files count soon. Remove files that you are no longer using.';
                    default: return `You have uploaded ${this.documnetsCount} files of ${this.maxDocumentsAllowed} allowed.`;
                }
            },
            getPopupType(): string {
                switch (true) {
                    case this.error:
                        return 'error';
                    case this.warning:
                        return 'warning';
                    default: return 'success';
                }
            },
            progressBarStatus(): string {
                switch (true) {
                    case this.error:
                        return 'exception';
                    case this.warning:
                        return 'warning';
                    default: return 'success';
                }
            },
            progress(): number {
                return (this.docCount / this.maxDocumentsAllowed) * 100;

            },
            warning(): Boolean{
                return this.docCount == (this.maxDocumentsAllowed - 1);
            },

            error(): Boolean {
                return this.docCount >= this.maxDocumentsAllowed
            },

            filesUploadedMessage(): string {
                return `${this.docCount} / ${this.maxDocumentsAllowed} files uploaded`;
            }
        }
    })
</script>

<style lang="scss" scoped>
    .status-container {
        margin: 30px 40px;

        .message {
            margin: 0 20px;
            color: #4d4d4d;
            font-size: 1.2em;
            font-weight: 600;
        }

        & > div {
            margin-bottom: 20px;
        }
    }
</style>