import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.segmentsStore.renderSegments, (item, index) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentType(_ctx.segmentsStore.renderSegments[index])), {
        key: _ctx.getkey(item),
        modelValue: _ctx.segmentsStore.renderSegments[index],
        "onUpdate:modelValue": ($event: any) => ((_ctx.segmentsStore.renderSegments[index]) = $event),
        onMergeSegment: _ctx.mergeSegmentHandler,
        onSplitSegment: _ctx.splitSegmentHandler,
        onNextTranslateSegment: _ctx.nextTranslateSegmentHandler
      }, null, 40, ["modelValue", "onUpdate:modelValue", "onMergeSegment", "onSplitSegment", "onNextTranslateSegment"]))
    }), 128))
  ])), [
    [_directive_loading, _ctx.loadingSegments]
  ])
}