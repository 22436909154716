import { apiClient } from '@/utils/apiClient'
import { LoadSegmentsRequest } from '@/models/LoadSegmentsRequest';

export class archivedDocumentService {
    requestClient: apiClient

    constructor() {
        this.requestClient = new apiClient();
    }

    async loadConfigurations(documentId: number) {
        return await this.requestClient.getTranslationConfig(documentId)
    }

    async loadTranslationSegments(request: LoadSegmentsRequest) {
        return await this.requestClient.getDocumentSegments(request)
    }

}