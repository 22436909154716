<template>

    <el-button link
               type="primary"
               size="small"
               :disabled="documentNotActive"
               @click.prevent="openDoc"
               v-on:click.middle="openDocInNewTab">
        Edit
    </el-button>

</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { DocumentRow, DocumentStatus } from '@/models/DocumentRow'

    import { navigationService } from '@/services/navigationService'

    export default defineComponent({
        props: {
            rowData: Object as PropType<DocumentRow>,
        },
        computed: {
            documentRow(): DocumentRow {
                return this.rowData as DocumentRow;
            },
            documentNotActive(): boolean {
                return this.documentRow && this.documentRow.status != DocumentStatus.Active;
            }, 

        },
        methods: {
            openDoc() {
                navigationService.goDocumentPage(this.documentRow.id);
            },

            openDocInNewTab() {
                const url = navigationService.getDocumentUrl(this.documentRow.id);
                window.open(url, '_blank', 'noreferrer');
            },
        }

    })

</script>