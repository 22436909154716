<template>

    <div :class="[{'sample-segment': sampleSegment}, 'merge-result']">
        <el-row :gutter="24">
            
            <el-col :offset="1" :span="10">
                <SourceSegment v-model="textData" />
            </el-col>
            
            <el-col :span="10">
                <div class="empty-target"></div>
            </el-col>

            <el-col :span="1">
                <div class="slot-container">
                    <slot>

                    </slot>
                </div>
            </el-col>

        </el-row>
    </div>

</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import SourceSegment from '@/pages/Document/TranslationSegment/Components/SourceSegmentView.vue'

    export default defineComponent({
        props: {
            modelValue: Object,
            sampleSegment: {
                type: Boolean,
                default: false
            }
        },
        components:{
            SourceSegment
        },
        data() {
            return {
                textData: {
                    text: '',
                    delta: {}
                },
            };
        },
        watch: {
            modelValue: {
                immediate: true,
                deep: true,
                handler(val: any) {
                    this.textData = val;
                }
            }
        }


    });
</script>

<style lang="scss" scoped>
    
    .merge-result {
        margin-bottom: 10px;
        padding: 10px;
        background-color: #ffffff;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        width: 100%;
        box-sizing: border-box;
    }

    .sample-segment {
        background: #ffffcc;
    }

    .empty-target {
        border: solid 1px #cdcccc;
        width: 500px;
        height: 50px;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .slot-container {
        display: flex;
        align-items: center;
        height: 100%;
    }

</style>