<template>

    <el-col :span="6"
            id="open-variants-popup">
        <p>Open variants popup automatically</p>
        <el-checkbox v-model="configuration.openVariantsPopup"
                     @change="valueChanged"
                     size="large"
                     :disabled="disabled" />
    </el-col>

</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { TranslationConfiguration } from '@/models/TranslationConfiguration'

    export default defineComponent({
        props: {
            modelValue: Object,
            disabled: {
                type: Boolean,
                defaultValue: false
            },
        },
        emits: ['input', 'valueChanged'],
        computed: {
            configuration: {
                get(): TranslationConfiguration { return this.modelValue as TranslationConfiguration; },
                set(value: TranslationConfiguration) { this.$emit('input', value); }
            },
        },
        methods: {
            valueChanged() {
                this.$emit('valueChanged');
            },
        }
    })
</script>
