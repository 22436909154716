export class wwsUrlService {
    static baseUrl: string = process.env.VUE_APP_WWS_ADMIN;

    static loginPage() {
        return `${this.baseUrl}/DocumentEditor.aspx`
    }

    static homePage() {
        return `${this.baseUrl}/`
    }
}