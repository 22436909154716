<template>
    <div class="segment-tail">

        <div class="option-buttons">

            <ButtonTemplate id="save-button"
                            class="option-item"
                            :disabled="!showSave"
                            :tooltipMessage="saveTranslationTooltip"
                            @buttonClick="doAddTranslation">
                <span class="material-symbols-outlined">
                    add_comment
                </span>
            </ButtonTemplate>

            <ButtonTemplate id="update-button"
                            class="option-item"
                            :disabled="!showUpdate"
                            :tooltipMessage="updateTranslationTooltip"
                            @buttonClick="doUpdateTranslation">
                <span class="material-symbols-outlined">
                    system_update_alt
                </span>
            </ButtonTemplate>

            <ButtonTemplate id="clear-button"
                            class="option-item"
                            :disabled="!showClear"
                            :tooltipMessage="eraceTranslationTooltip"
                            @buttonClick="doClearTranslation">
                <span class="material-symbols-outlined">
                    ink_eraser
                </span>
            </ButtonTemplate>

            <div id="info-items"
                 class="info-items">
                <MatchPersentage v-model="translationSegment" />
                <component :is="componentType(translationSegment)"
                           v-model="translationSegment" />
            </div>

        </div>

        <StateTag v-model="translationSegment" />

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
    import { SegmentEditorItem } from '@/models/SegmentEditorItem';
    import { SegmentStatus } from '@/models/TranslationSegment/SegmentStatus';

    import { translationSegmentService } from '../translationSegmentService'
    import { shortcuts } from '@/utils/shortcuts';

    import ButtonTemplate from '@/components/ButtonTemplate.vue';
    import MatchPersentage from './MatchPersentage.vue';
    import StateImage from './StateImage.vue';
    import StateTag from './StateTag.vue';

    import SegmentStatusIcon from '@/components/SegmentStatusIcon.vue';

    export default defineComponent({
        props: {
            modelValue: Object,
        },
        components: {
            ButtonTemplate,
            MatchPersentage,
            StateImage,
            StateTag,
            SegmentStatusIcon,
        },
        data() {
            return {
                translationSegmentService: translationSegmentService.getInstance(),
            }
        },
        computed: {
            segmentItem: {
                get(): SegmentEditorItem { return this.modelValue as SegmentEditorItem; },
                set(value: SegmentEditorItem) { this.$emit('input', value); }
            },
            translationSegment: {
                get(): TranslationSegment { return this.segmentItem.data as TranslationSegment; },
                set(value: TranslationSegment) { this.segmentItem.data = value }
            },
            focused(): boolean {
                return this.segmentItem.focused;
            },
            showSave(): boolean {
                return this.focused &&
                    this.translationSegmentService.translationCouldBeAdded();
            },
            showUpdate(): boolean {
                return this.focused &&
                    this.translationSegmentService.translationCouldBeUpdated();
            },
            showClear(): boolean {
                return this.focused &&
                    !this.translationSegmentService.currentTargetIsEmpty();
            },
            saveTranslationTooltip(): string {
                return shortcuts.getTooltip(shortcuts.addTranslation);
            },
            updateTranslationTooltip(): string {
                return shortcuts.getTooltip(shortcuts.updateTranslation);
            },
            eraceTranslationTooltip(): string {
                return shortcuts.getTooltip(shortcuts.discardTranslation);
            },
        },
        methods: {
            doAddTranslation() {
                if (this.showSave) {
                    this.translationSegmentService.addTranslation(this.translationSegment.translationSegmentId)
                }
            },
            doUpdateTranslation() {
                if (this.showUpdate) {
                    this.translationSegmentService.updateTranslation(this.translationSegment.translationSegmentId)
                }
            },
            doClearTranslation() {
                if (this.showClear) {
                    this.translationSegmentService.cleanTranslationSegment(this.translationSegment.translationSegmentId)
                }
            },

            componentType(translationSegment: TranslationSegment) {
                switch (translationSegment.segmentStatus) {
                    case SegmentStatus.Ok:
                    case SegmentStatus.Undefined:
                        return StateImage;
                    case SegmentStatus.SourceAndTargetReach500Limit:
                    case SegmentStatus.SourceReach500Limit:
                    case SegmentStatus.TargetReach500Limit:
                        return SegmentStatusIcon;

                    default: StateImage;
                }
            },
        }

    });
</script>

<style lang="scss" scoped>

    .segment-tail {
        margin: 12px 0 0 0;
        display: flex;
        flex-direction: column;
    }

    .option-buttons {
        display: flex;

        .option-item {
            margin-right: 8px;
        }

        .info-items {
            display: flex;
        }
    }
</style>