import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-126b38f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SegmentItemPreview = _resolveComponent("SegmentItemPreview")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.translationSegments, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.translationSegmentId
      }, [
        _createVNode(_component_SegmentItemPreview, {
          modelValue: _ctx.translationSegments[index],
          "onUpdate:modelValue": ($event: any) => ((_ctx.translationSegments[index]) = $event),
          rowNumber: _ctx.getRowNumber(index)
        }, null, 8, ["modelValue", "onUpdate:modelValue", "rowNumber"])
      ]))
    }), 128)),
    _createVNode(_component_el_pagination, {
      layout: "prev, pager, next",
      total: _ctx.paging.rowCount,
      "current-page": _ctx.paging.currentPageNumber,
      "default-page-size": _ctx.paging.pageSize,
      onCurrentChange: _ctx.goToPage
    }, null, 8, ["total", "current-page", "default-page-size", "onCurrentChange"])
  ])), [
    [_directive_loading, _ctx.loading]
  ])
}