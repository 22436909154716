<template>
    <div>
        <el-button type="primary"
                   @click="startStuleTour"
                   :disabled="!tourAvailable">
            {{tourName}}
        </el-button>

        <el-tour v-model="tourStart"
                 :target-area-clickable="false">

            <el-tour-step :target="targetOne"
                          :title="tittleOne">
                <div>{{descriptionOne}}</div>
            </el-tour-step>

            <el-tour-step :target="targetTwo"
                          :title="tittleTwo"
                          :content-style="contentStyleTwo"
                          placement="left">

                <div>{{descriptionTwo}}</div>

                <img class="screenshot"
                     src="@/images/Tours/term-recognition-tour-1.png"
                     alt="tour.png" />
            </el-tour-step>

            <el-tour-step :target="targetThree"
                          :title="tittleThree"
                          :content-style="contentStyleThree"
                          placement="left">

                <div>{{descriptionThree}}</div>

                <img class="screenshot"
                     src="@/images/Tours/term-recognition-tour-2.png"
                     alt="tour.png" />
            </el-tour-step>


        </el-tour>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { shortcuts } from '@/utils/shortcuts';

    export default defineComponent({
        props: {
            tourAvailable: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                tourName: "Terminology recognition",
                tourStart: false,

                targetOne: "#translate-button",
                tittleOne: "Translate",
                descriptionOne: `Editor performs terminology recognition when loading translation for a segment. Click load translation(or use hotkey ${shortcuts.translate.win})`,

                targetTwo: "#variants-button",
                tittleTwo: "Terminology recognized",
                descriptionTwo: "After translation is loaded you will see 'Translation variants' window. If system finds terminology in source text, that has been added to the termbase, it will be underlined with red colour (see the arrow). You can click on it and it will load terminology definition and its translation.",
                contentStyleTwo: { width: '800px' },

                targetThree: "#variants-button",
                tittleThree: "Terminology loaded",
                descriptionThree: "When terminology is loaded it will appear in the pop-up above. From there you can copy the terminology translation to the target field.",
                 contentStyleThree: { width: '800px' },

            };
        },

        methods: {
            startStuleTour() {
                const result = document.getElementById("shortcut-button");
                if (result != null) {
                    result.click();
                }
                this.tourStart = true;
            },
        },


    })
</script>

<style scoped>
    .screenshot {
        max-width: 100%;
        margin-top: 10px;
    }
</style>