import { segmentsStore } from '@/stores/segmentsStore'
import { mergeStore } from '@/stores/mergeStore'
import { pagingStore } from '@/stores/pagingStore'
import { documentStore } from '@/stores/documentStore';

import { SegmentEditorItem } from '@/models/SegmentEditorItem'
import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment'
import { SegmentEditorItemType } from '../SegmentEditorItemType'

import { segmentEditorItemFactory } from '../segmentEditorItemFactory'
import { apiClient } from '@/utils/apiClient'

import { DeltaMerger } from './deltaMerger'


export class mergeSegmentService {
    requestClient: any

    segmentsStore: any
    mergeStore: any
    pagingStore: any
    documentStore: any

    constructor() {
        this.requestClient = new apiClient();

        this.segmentsStore = segmentsStore()
        this.mergeStore = mergeStore()
        this.pagingStore = pagingStore()
        this.documentStore = documentStore();
        this.mergeStore.init()
    }

    initMerge(segmentId: number) {
        this.initMergeStore(segmentId);
        this.markSegmentsToMerge() 
        this.enableMerge()
    }

     private initMergeStore(segmentId: number) {
        //set segmnet id
        this.mergeStore.setSegmentId(segmentId);

        //set max segments count
        const maxMergeSegmentsCount = this.getMaxMergeSegmentsCount(segmentId);
        this.mergeStore.setMaxMergeSegmentsCount(maxMergeSegmentsCount);

    }

    private getMaxMergeSegmentsCount(startSegmentId: number) {
        const segmentsCount = this.segmentsStore.segmentsArray.length;
        const startSegegmentIndex = this.segmentsStore.getSegmentPosition(startSegmentId);

        return segmentsCount - startSegegmentIndex;
    }

    private markSegmentsToMerge() {
        const segmentsToMerge = this.getSegmentsToMerge();
        const segmentIdsToMerge = segmentsToMerge.map((s: SegmentEditorItem) => s.itemId);

        this.mergeStore.setMergeArray(segmentIdsToMerge);

        this.segmentsStore.segmentsArray.forEach((s: SegmentEditorItem) => {

            if (segmentIdsToMerge.includes(s.itemId) && s.segmentType == SegmentEditorItemType.TranslationSegment) {
                s.segmentIsVisibleOnList = false;
            }
            else {
                s.segmentIsVisibleOnList = true;
            }

        })
    }

    getSegmentsToMerge() {
        const startPosition = this.segmentsStore.getSegmentPosition(this.mergeStore.segmentId);
        return this.segmentsStore.segmentsArray.slice(startPosition, startPosition + this.mergeStore.mergeSegmentsCount);
    }

    getMergeSourceData() {
        const segmentsToMerge = this.getSegmentsToMerge();
        const segmentsData = segmentsToMerge.map((s: SegmentEditorItem) => s.data);

        return DeltaMerger.buildMergedSourceData(segmentsData);
    }

    private enableMerge() {
        this.segmentsStore.setMergeState();
    }

    canMergeMore() {
        return this.mergeStore.canIncrease;
    }

    increaseSegments() {
        if (this.mergeStore.canIncrease) {
            this.mergeStore.increaseMergeSegmentsCount();
            this.markSegmentsToMerge();
        }
    }

    decreaseSegments() {
        if (this.mergeStore.canDecrease) {
            this.mergeStore.decreaseMergeSegmentsCount();
            this.markSegmentsToMerge();
        } else {
            this.cancelMerge();
        }
    }

    cancelMerge() {
        this.segmentsStore.segmentsArray.forEach((s: SegmentEditorItem) => {
            s.segmentIsVisibleOnList = true;
        })

        this.segmentsStore.setTranslateState();
        this.removeMergeSegment();
    }

    private removeMergeSegment() {
        const segmentIndex = this.segmentsStore.segmentsArray.findIndex((s: SegmentEditorItem) => s.segmentType == SegmentEditorItemType.MergeSegment);
        this.segmentsStore.removeSegmentFromSegmentsArray(segmentIndex);
    }

    async mergeSegments() {
        const segmnet = await this.getMergedSegment();

        this.segmentsStore.setTranslateState();
        this.documentStore.reloadDocument();
    }

    private async getMergeResult() {

        const mergeRequest = {
            documentId: 0,
            segmentIds: this.mergeStore.mergeArray,
            pageSize: this.pagingStore.pageSize,
        }

        const response = await this.requestClient.apiPost(`segments/mergeSegments`, mergeRequest);

        return response;
    }

    private async getMergedSegment() {
        const mergeResponce = await this.getMergeResult();
        const mergedSegment = mergeResponce as TranslationSegment;

        return segmentEditorItemFactory.mapToTranslateSegmentEditorItem(mergedSegment)
    }
}