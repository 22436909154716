<template>
    <div>
        <div v-if="noVariants">
            <div class="no-variants">
                <div>
                    {{noVariantsMessage}}
                </div>
                <div>
                    {{translationDate}}
                </div>
            </div>
        </div>
        <div v-else>
            <div class="variants-grid table-header">

                <div class="grid-span-2">
                    Source
                </div>
                <div>
                    Translation
                </div>
                <div>
                    Match
                </div>
            </div>

            <div class="translation-variants-container">
                <el-scrollbar max-height="290">

                    <TransitionGroup tag="div" name="list" class="container" appear>

                        <div v-for="(variant, index) in translationVariants"
                             :key="variant.targetSegment + index"
                             class="translation-variant-wrapper">
                            <div :id="`v-${index}`"
                                 :class="['translation-variant', 'item', 'variants-grid', {'selected': translationVariantSelected(variant)}]"
                                 @click="setTranslationVariant(variant)">

                                <SourceItem :sourceData="translationSegment.sourceData"
                                            :translationVariant="variant" />

                                <GroupInfo :translationVariant="variant" />

                                <TargetText :translationVariant="variant" />

                                <MarchPersentage :translationVariant="variant" />

                            </div>
                        </div>

                    </TransitionGroup>

                </el-scrollbar>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'

    import { SegmentEditorItem } from '@/models/SegmentEditorItem';
    import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
    import { Translation } from '@/models/TranslationSegment/TranslationVariants';

    import { translationVariantsService } from '@/services/translationSegment/translationVariantsService'

    import SourceItem from './SourceItem.vue';
    import GroupInfo from './GroupInfo.vue';
    import TargetText from './TargetText.vue';
    import MarchPersentage from './MarchPersentage.vue';

    export default defineComponent({
        props: {
            modelValue: Object,
            disabled: {
                type: Boolean,
                defaultValue: false
            },
        },
        components: {
            SourceItem,
            GroupInfo,
            TargetText,
            MarchPersentage
        },
        data() {
            return {
                noVariantsMessage: 'No variants found in the memory group',
                translationVariantsService: translationVariantsService.getInstance(),
            }
        },
        computed: {
            segmentItem: {
                get(): SegmentEditorItem { return this.modelValue as SegmentEditorItem; },
                set(value: SegmentEditorItem) { this.$emit('input', value); }
            },
            translationSegment: {
                get(): TranslationSegment { return this.segmentItem.data as TranslationSegment; },
                set(value: TranslationSegment) { this.segmentItem.data = value }
            },
            noVariants(): boolean {
                return this.translationSegment?.translationVariants != undefined &&
                    (this.translationSegment?.translationVariants.translations == null ||
                        this.translationSegment?.translationVariants.translations.length == 0)
            },
            translationVariants(): any {
                if (this.translationSegment?.translationVariants == undefined || this.translationSegment.translationVariants.translations == null  ) {

                    return [];
                }

                return this.translationSegment.translationVariants.translations;
            },
            translationDate(): any {
                if(this.translationSegment.translationVariants){
                    return new Date(this.translationSegment.translationVariants.translationDate).toLocaleDateString()
                }

                return ''                
            },
        },
        methods: {
            async setTranslationVariant(translationVariant: Translation) {
                await this.translationVariantsService.setTranslationVariant(this.translationSegment as TranslationSegment, translationVariant);
            },

            translationVariantSelected(translationVariant: any) {
                if (this.translationSegment?.segmentTranslation == null) {

                    return false
                }

                return this.translationSegment.segmentTranslation.targetSegment == translationVariant.targetSegment &&
                    this.translationSegment.segmentTranslation.sourceSegment == translationVariant.sourceSegment
            },


        }

    })

</script>

<style lang="scss" scoped>

    .no-variants {
        display: flex;
        justify-content: space-around;
        background-color: aliceblue;
    }

    .variants-grid {
        display: grid;
        grid-template-columns: 5fr 1fr 5fr 1fr;
    }


    .table-header {
        font-size: 16px;
    }

    .grid-span-2 {
        grid-column: span 2;
    }

    .translation-variants-container {
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1) inset;
        border-style: solid;
        border-width: 2px;
        border-color: #cfcfcf;

        .translation-variant-wrapper {
            left: 0;
            right: 0;

            &:hover {
                background-color: #f5faff;
            }
        }

        .translation-variant {
            border-bottom: solid 2px #cfcfcf;
            cursor: pointer;
            align-items: center;
        }

        .selected {
            background-color: #d7ecff
        }
    }

    /* list transitions */
    .list-enter-from {
        opacity: 0;
        transform: translateY(40px);
    }

    .list-enter-active {
        transition: all 0.8s ease;
    }

    .list-leave-to {
        opacity: 0;
        transform: translateY(-40px);
    }

    .list-leave-active {
        transition: all 0.8s ease;
        position: absolute; /* for move transition after item leaves */
    }

    .list-move {
        transition: all 0.8s ease;
    }

</style>