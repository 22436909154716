import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7c07ae10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-control" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_button, {
      onClick: _ctx.goToHomePage,
      type: "primary"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Go Home ")
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}