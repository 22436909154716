<template>
    <div class="tour-wrapper">

        <el-text type="info">
            Home page tours
        </el-text>

        <div class="tour-button-container">
            <QuickStart :tour-available="homePage" />
            <HomePage :tour-available="homePage" />
            <TranslationConfig :tour-available="homePage" />
        </div>

        <el-divider />

        <el-text type="info">
            Document page tours
        </el-text>

        <div class="tour-button-container">
            <AddTranslation :tour-available="documentPage" />
            <LoadTranslation :tour-available="documentPage" />
            <UpdateTranslation :tour-available="documentPage" />
            <SegmentControls :tour-available="documentPage" />
            <DocumentActions :tour-available="documentPage" />
            <MergeSegments :tour-available="documentPage" />
            <SplitSegments :tour-available="documentPage" />
            <TextStyles :tour-available="documentPage" />
            <TermRecognition :tour-available="documentPage" />
            <ExternalTranslation :tour-available="documentPage" />
        </div>

    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import { navigationService } from '@/services/navigationService'

    import QuickStart from './Tours/QuickStart.vue'
    import HomePage from './Tours/HomePage.vue'
    import TranslationConfig from './Tours/TranslationConfig.vue'

    import AddTranslation from './Tours/AddTranslation.vue'
    import LoadTranslation from './Tours/LoadTranslation.vue'
    import UpdateTranslation from './Tours/UpdateTranslation.vue'
    import SegmentControls from './Tours/SegmentControls.vue'
    import DocumentActions from './Tours/DocumentActions.vue'
    import MergeSegments from './Tours/MergeSegments.vue'
    import SplitSegments from './Tours/SplitSegments.vue'
    import TextStyles from './Tours/TextStyles.vue'
    import TermRecognition from './Tours/TermRecognition.vue'
    import ExternalTranslation from './Tours/ExternalTranslation.vue'

    export default defineComponent({
        components: {
            QuickStart,
            HomePage,
            TranslationConfig,

            AddTranslation,
            LoadTranslation,
            UpdateTranslation,
            SegmentControls,
            DocumentActions,
            MergeSegments,
            SplitSegments,
            TextStyles,
            TermRecognition,
            ExternalTranslation,
        },
        computed: {
            homePage() {
                return this.$route.name == navigationService.homePageName
            },
            documentPage() {
                return this.$route.name == navigationService.documentPageName
            },
        },
    })
</script>

<style lang="scss" scoped>
    .tour-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: space-between;

        .tour-button-container {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            & > div {
                margin-right: 10px;
                margin-top: 10px;
            }
        }
    }
</style>
