import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_tooltip, {
      placement: "top-start",
      disabled: _ctx.disabled,
      content: _ctx.tooltipMessage,
      "show-after": 5
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['img-small-button-container', {'button-disabled':_ctx.disabled}] ),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.buttonClick && _ctx.buttonClick(...args)))
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2)
      ]),
      _: 3
    }, 8, ["disabled", "content"])
  ]))
}