<template>
    <div>
        <div class="main-body">
            <slot></slot>
        </div>

        <div class="page-footer">
            <PageFooter />
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import PageFooter from './PageFooter.vue'

    export default defineComponent({
        components: {
            PageFooter
        },
    })
</script>

<style lang="scss">

    .main-body {
        min-height: 85vh;
        display: flex;
        flex-direction: column;
    }

    .page-footer {
        margin-top: auto;
    }
</style>