import { TourItemStep } from "./tourItemSteps";
import { shortcuts } from "@/utils/shortcuts";

export class userGuideService {

    static getQuickStartGuide(): TourItemStep[] {
        const steps: TourItemStep[] = [
            {
                id: "step-1",
                target: "#tab-fileUpload",
                title: "New file",
                description: "For Quick start go to 'New file' tab.",
            },
            {
                id: "step-2",
                target: "#source-lagnuage",
                title: "Source language",
                description: "Set source language from which you are translating.",
            },
            {
                id: "step-3",
                target: "#target-laguage",
                title: "Target language",
                description: "Set target language to which you are translating.",
            },
            {
                id: "step-4",
                target: "#translation-subject",
                title: "Subject",
                description: "Select subject from the list.",
            },
            {
                id: "step-5",
                target: "#translation-sub-subject",
                title: "Sub-subject",
                description: "Select sub-subject from the list.",
            },
            {
                id: "step-6",
                target: "#file-upload",
                title: "Select file",
                description: "Select file that you plan to translate.",
            },
            {
                id: "step-7",
                target: "#submitt-file",
                title: "Submit",
                description: "Click 'Submit' to start. This will load the file to server, and you can start translation.",
            },
            {
                id: "step-8",
                target: "#shortcut-button",
                title: "Information button",
                description: "Information about hotkeys and other tours can be found here.",
            },
        ]

        return steps;
    }

    static getHomePage(): TourItemStep[] {
        const steps: TourItemStep[] = [
            {
                id: "step-1",
                target: "#go-home",
                title: "Home page button",
                description: "Return users to home page after clicking the logo.",
            },
            {
                id: "step-2",
                target: "#user-component",
                title: "User section",
                description: "Here you can go to WebWordSystem admin page or log out from Document editor.",
            },
            {
                id: "step-3",
                target: "#shortcut-button",
                title: "Information",
                description: "Here you can find information how to interact with Document editor: system hotkeys and tours.",
            },
            {
                id: "step-4",
                target: "#home-page-tabs",
                title: "User documents",
                description: "Here you can find documents that you are currently working with, upload new document and documents that were moved to archive.",
            },
            {
                id: "step-5",
                target: "#tab-userDocuments",
                title: "Continue translation",
                description: "",
            },
            {
                id: "step-6",
                target: "#tab-fileUpload",
                title: "Upload new document",
                description: "",
            },
            {
                id: "step-7",
                target: "#tab-archiveDocuments",
                title: "Archived documents",
                description: "",
            },
        ]

        return steps;
    }

    static getConfigurationTour(): TourItemStep[] {
        const steps: TourItemStep[] = [
            {
                id: "step-1",
                target: "#tab-fileUpload",
                title: "'New file' tab",
                description: "To see/edit user translation settings go to 'New file' tab.",
            },
            {
                id: "step-2",
                target: "#configuration-colapse",
                title: "Translation settings",
                description: "Settings are saved for each user. After login the system loads user settings. You can always change them, and they will be loaded next time. These settings will be applied to new files that you upload. But you can always adjust these settings on uploaded file.",
            },
            {
                id: "step-3",
                target: "#source-lagnuage",
                title: "Source language",
                description: "",
            },
            {
                id: "step-4",
                target: "#target-laguage",
                title: "Target language",
                description: "",
            },
            {
                id: "step-5",
                target: "#swap-languages",
                title: "Swap source and target language",
                description: "",
            },
            {
                id: "step-6",
                target: "#fuzzy-match",
                title: "Fuzzy match",
                description: "Define the precision in % for search engine to find matches in translation memory.",
            },
            {
                id: "step-7",
                target: "#open-variants-popup",
                title: "Open variants popup automatically",
                description: "When this option is checked on a document page when navigating between segments, system will always open translation variants window.",
            },
            {
                id: "step-8",
                target: "#translation-subject",
                title: "Subject and Subsubject",
                description: "Subject and Subsubject of document that is being translated. The combination of subject and sub-subject defines the area where translation will be saved and retrieved.",
            },
            {
                id: "step-9",
                target: "#project-code",
                title: "Project code",
                description: "",
            },
            {
                id: "step-10",
                target: "#database-type",
                title: "Database",
                description: "Private, public or both.",
            },
            {
                id: "step-11",
                target: "#use-external-translations",
                title: "Use external translations",
                description: "",
            },
            {
                id: "step-12",
                target: "#translation-providers",
                title: "External translation options",
                description: "",
            },
        ]

        return steps;
    }

    static getLoadTranslation(): TourItemStep[] {
        const steps: TourItemStep[] = [
            {
                id: "step-1",
                target: "#source-container",
                title: "Source text",
                description: "Check the source text which needs to be translated.",
            },
            {
                id: "step-2",
                target: "#translate-button",
                title: "Translate",
                description: `Click translate button (or use hotkey ${shortcuts.translate.win}). It will load translation from translation memory.`
            },

            {
                id: "step-3",
                target: "#info-items",
                title: "Translation match",
                description: `If you received 100% translation match you can navigate to next translation segment (or use hotkey ${shortcuts.moveNext.win})`
            },
        ]

        return steps;
    }

    static getUpdateTranslation(): TourItemStep[] {
        const steps: TourItemStep[] = [
            {
                id: "step-1",
                target: "#segment-container",
                title: "Edit",
                description: "Find translation segment which needs to be edited.",
            },
            {
                id: "step-2",
                target: "#variants-button",
                title: "Could be edited",
                description: "Open translation variants window and check if this translation variant could be edited - it should be 100% match or fuzzy match and your own translation."
                    
            },
            {
                id: "step-3",
                target: "#tareget-container",
                title: "Edit translation text",
                description: "Edit text of translation. The translation input colour is changed - it means that the edited version of translation is not saved to translation memory yet. Also, you can see #edit tag and not saved translation image"
            },
            {
                id: "step-4",
                target: "#update-button",
                title: "Update translation",
                description: `When you have updated translation, you can click 'Update translation' button (or use hotkey ${shortcuts.updateTranslation.win})`
            },
        ]

        return steps;
    }

    static getSegmentControls(): TourItemStep[] {
        const steps: TourItemStep[] = [
            {
                id: "step-1",
                target: "#row-number",
                title: "Segment number",
                description: ""
            },
            {
                id: "step-2",
                target: "#source-container",
                title: "Source text",
                description: ""
            },
            {
                id: "step-3",
                target: "#copy-button",
                title: "Copy source text to target (translation input)",
                description: ""
            },
            {
                id: "step-4",
                target: "#translate-button",
                title: "Load translation",
                description: ""
            },
            {
                id: "step-5",
                target: "#tareget-container",
                title: "Target text - translation of source text",
                description: ""
            },
            {
                id: "step-6",
                target: "#variants-button",
                title: "Open/close translation variants popup",
                description: ""
            },
            {
                id: "step-7",
                target: "#save-button",
                title: "Save translation to translation memory",
                description: ""
            },
            {
                id: "step-8",
                target: "#update-button",
                title: "Update your translation in translation memory",
                description: ""
            },
            {
                id: "step-9",
                target: "#clear-button",
                title: "Discard translation and leave segment not translated",
                description: ""
            },
            {
                id: "step-10",
                target: "#info-items",
                title: "Segments state section",
                description: "Show match percentage, translation state."
            },
            {
                id: "step-11",
                target: "#find-next-segmet-container",
                title: "Find the next segment to translate",
                description: "Will go through all segments in document and find next segment that needs translator attention - empty segment, fuzzy match, segment with edited translation."
            },
        ]

        return steps;
    }

    static getDocumentActions(): TourItemStep[] {
        const steps: TourItemStep[] = [
            {
                id: "step-1",
                target: "#translate-all",
                title: "Translate current page",
                description: "Will load translation from translation memory for all segments for current page."
            },
            {
                id: "step-2",
                target: "#save-drafts",
                title: "Save all drafts",
                description: "This action will save all draft translations (marked as yellow with tag #draft) to translation memory. Afterwards, all previously draft translations could be loaded as regular translations."
            },
            {
                id: "step-3",
                target: "#clear-document",
                title: "Clear document",
                description: "Will mark all segments as not translated and remove selected translations, drafts. Could also be used when user sets up wrong settings and retrieves incorrect or no results."
            },
            {
                id: "step-4",
                target: "#download-document",
                title: "Download document",
                description: "Generates file with translation and downloads to your PC."
            },
        ]

        return steps;
    }

    static getMergeSegments(): TourItemStep[] {
        const steps: TourItemStep[] = [
            //Step 1 moved to popup
            {
                id: "step-2",
                target: "#merge-source",
                title: "Source segments",
                description: "",
            },
            {
                id: "step-3",
                target: "#merge-result",
                title: "Merge result",
                description: ``,
            },
            {
                id: "step-4",
                target: "#add-merge-segment",
                title: "Add segment",
                description: `In merge state you can add more segments (or use hotkey ${shortcuts.addSegmentToMerge.win}).`,
            },
            {
                id: "step-5",
                target: "#remove-merge-segment",
                title: "Remove segment",
                description: `If you accidentally added to many segments, you can remove them from merge (or use hotkey ${shortcuts.removeSegmentToMerge.win}).`,
            },
            {
                id: "step-6",
                target: "#save-merge",
                title: "Save merge",
                description: `Click to save merge (or use hotkey ${shortcuts.saveMerge.win}).`,
            },
            {
                id: "step-7",
                target: "#cancel-merge",
                title: "Cancel merge",
                description: `Click to cancel merge (or use hotkey ${shortcuts.discardMerge.win}).`,
            },

        ]

        return steps;
    }

    static getSplitSegments(): TourItemStep[] {
        const steps: TourItemStep[] = [
            //Step 1 moved to popup
            {
                id: "step-2",
                target: "#split-source",
                title: "Source segment",
                description: ``,
            },
            {
                id: "step-3",
                target: "#split-result",
                title: "Result segments",
                description: ``,
            },
            {
                id: "step-4",
                target: "#save-merge",
                title: "Save merge split",
                description: `Click to save merge split (or use hotkey ${shortcuts.saveMerge.win}).`,
            },
            {
                id: "step-5",
                target: "#cancel-merge",
                title: "Cancel merge split",
                description: `Click to cancel merge split (or use hotkey ${shortcuts.discardMerge.win}).`,
            },
        ]

        return steps;
    }

}
