<template>
    <div class="row">
        <div id="go-home"
             class="sub-components ckickable"
             @click="goToHomePage">
            <div class="logo">
            </div>
            <div class="name">Document editor</div>
        </div>
        <div class="sub-components">
            <div>
                <UserComponent />
            </div>
            <div>
                <ShortcutsInfo />
            </div>
        </div>

    </div>
    <NewUserPrompt />

</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    
    import UserComponent from './UserComponent.vue'
    import ShortcutsInfo from './ShortcutsInfo.vue'
    import NewUserPrompt from './UserTour/NewUserPrompt.vue'

    import { navigationService } from '@/services/navigationService'

    export default defineComponent({
        components:{
            UserComponent,
            ShortcutsInfo,
            NewUserPrompt,
        },
        data() {
            return {
            };
        },
        methods: {
            goToHomePage() {
                navigationService.goHomePage();
            }
        }
    })
</script>

<style lang="scss" scoped>
    .row {
        display: flex;
        justify-content: space-between;
        margin: 50px 10px;
        
        .logo {
            width: 70px;
            height: 43px;
            margin-right: 20px;
            background-image: url(/src/images/logo.svg);
        }

        .name {
            font-size: 1.5em;
        }

        .ckickable {
            cursor: pointer;
        }

        .sub-components {
            display: flex;
            align-items: center;
        }
    }

</style>