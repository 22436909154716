export class shortcuts {

    static moveNext: ShortcutValue = {
        win: 'Tab',
        mac: 'Tab',
        action: 'Move to next row (without saving translation to memory)',
        shortName: ''
    }

    static moveUp: ShortcutValue = {
        win: 'Shift + Tab',
        mac: 'Shift + Tab',
        action: 'Move to previous row (without saving translation to memory)',
        shortName: ''
    }

    static saveMoveNext: ShortcutValue = {
        win: 'Alt + Right',
        mac: 'Cmd + Right',
        action: 'Save translation to memory and move to next row',
        shortName: ''
    }

    static saveMoveUp: ShortcutValue = {
        win: 'Alt + Left',
        mac: 'Cmd + Left',
        action: 'Save translation to memory and move to previous row',
        shortName: ''
    }

    static goToNextPage: ShortcutValue = {
        win: 'Alt + PageUp',
        mac: 'Cmd + PageUp',
        action: 'Move to previous page',
        shortName: ''
    }

    static goToPreviousPage: ShortcutValue = {
        win: 'Alt + PageUp',
        mac: 'Cmd + PageUp',
        action: 'Move to next page',
        shortName: ''
    }

    static copy: ShortcutValue = {
        win: 'Alt + C',
        mac: 'Cmd + C',
        action: 'Copy source to target field',
        shortName: 'Copy'
    }

    static translate: ShortcutValue = {
        win: 'Alt + T',
        mac: 'Cmd + T',
        action: 'Translate selected row',
        shortName: 'Translate'
    }

    static addTranslation: ShortcutValue = {
        win: 'Alt + S',
        mac: 'Cmd + S',
        action: 'Save new translation to memory for selected row',
        shortName: 'Save new translation'
    }

    static updateTranslation: ShortcutValue = {
        win: 'Alt + U',
        mac: 'Cmd + U',
        action: 'Update existing translation for selected row',
        shortName: 'Update Translation'
    }

    static discardTranslation: ShortcutValue = {
        win: 'Alt + Backspace',
        mac: 'Cmd + Backspace',
        action: 'Discard translation and make target field empty',
        shortName: 'Discard translation'
    }

    static clearStyles: ShortcutValue = {
        win: 'Alt + Q',
        mac: 'Cmd + Q',
        action: 'Clear styles from selected segment',
        shortName: 'Clear styles'
    }

    static variantsPopover: ShortcutValue = {
        win: 'Alt + V',
        mac: 'Cmd + V',
        action: 'Open/hide matching variants popover for selected row',
        shortName: 'Open/hide variants'
    }

    static nextTranslateSegment: ShortcutValue = {
        win: 'Alt + N',
        mac: 'Cmd + N',
        action: 'Open next translate segment',
        shortName: 'Next segment\n to translate'
    }

    static merge: ShortcutValue = {
        win: 'Ctrl + M',
        mac: 'Ctrl + M',
        action: 'Merge next segment to current one',
        shortName: 'Merge'
    }

    static split: ShortcutValue = {
        win: 'Ctrl + Shift + S',
        mac: 'Ctrl + Shift + S',
        action: 'Split merged segments',
        shortName: 'Split merge'
    }

    static addSegmentToMerge: ShortcutValue = {
        win: 'Ctrl + ArrowDown',
        mac: 'Ctrl + ArrowDown',
        action: 'Add one more segment to merge',
        shortName: 'Add segment'
    }
    static removeSegmentToMerge: ShortcutValue = {
        win: 'Ctrl + ArrowUp',
        mac: 'Ctrl + ArrowUp',
        action: 'Remove last segment from merge',
        shortName: 'Remove segment'
    }

    static saveMerge: ShortcutValue = {
        win: 'Ctrl + S',
        mac: 'Ctrl + S',
        action: 'Save merge',
        shortName: 'Save merge'
    }

    static discardMerge: ShortcutValue = {
        win: 'Ctrl + X',
        mac: 'Ctrl + X',
        action: 'Discard merge',
        shortName: 'Discard merge'
    }

    static getTooltip(shortCut: ShortcutValue) {
        return `${shortCut.shortName} (${shortCut.win})`
    }
}

export interface ShortcutValue {
    win: string
    mac: string
    action: string
    shortName: string
}
