import { defineStore } from 'pinia'

export const documentStore = defineStore('documentStore', {
    state: () => ({
        documentId: 0,
        documentUpdate: 0,
        loading: false,
    }),

    getters: {
    },

    actions: {
        setDocumntId(documentId: number) {
            this.documentId = documentId;
        },

        reloadDocument() {
            this.documentUpdate++;
        },

        startLoading() {
            this.loading = true;
        },

        stopLoading() {
            this.loading = false;
        },
    }
})
