<template>
    <div class="component">
        <slot></slot>
    </div>
</template>

<script lang="js">

</script>

<style>
    .component {
        padding: 20px;
        background-color: #c7c5c5;
        border-radius: 15px;
    }
</style>

